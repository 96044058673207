<template>
  <div class="promo">
    <div class="promo__wrapper">
      <img class="promo__logo" src="../assets/images/logotype2.svg" alt="" />
      <div class="promo__headline">
        Для получения скидки <br />
        заполните форму ниже
      </div>
      <form class="promo__form" @submit.prevent>
        <div class="promo__form-body">
          <div class="promo__form-block">
            <input
              :class="`promo__form-input promo__form-input_${last_name.status}`"
              :type="last_name.type"
              :name="last_name.name"
              :value="last_name.value"
              :placeholder="last_name.placeholder"
              :status="last_name.status"
              @touchstart="handleChange"
              @focus="handleChange"
              @input="handleChange"
              @paste="handleChange"
              @change="handleChange"
            />
            <span class="promo__form-error" v-if="last_name.error">{{
              last_name.error
            }}</span>
          </div>
          <div class="promo__form-block">
            <input
              :class="`promo__form-input promo__form-input_${first_name.status}`"
              :type="first_name.type"
              :name="first_name.name"
              :value="first_name.value"
              :placeholder="first_name.placeholder"
              :status="first_name.status"
              @touchstart="handleChange"
              @focus="handleChange"
              @input="handleChange"
              @paste="handleChange"
              @change="handleChange"
            />
            <span class="promo__form-error" v-if="first_name.error">{{
              first_name.error
            }}</span>
          </div>
          <div class="promo__form-block">
            <input
              :class="`promo__form-input promo__form-input_${middle_name.status}`"
              :type="middle_name.type"
              :name="middle_name.name"
              :value="middle_name.value"
              :placeholder="middle_name.placeholder"
              :status="middle_name.status"
              @touchstart="handleChange"
              @focus="handleChange"
              @input="handleChange"
              @paste="handleChange"
              @change="handleChange"
            />
            <span class="promo__form-error" v-if="middle_name.error">{{
              middle_name.error
            }}</span>
          </div>
          <div class="promo__form-block">
            <input
              :class="`promo__form-input promo__form-input_${phone.status}`"
              :type="phone.type"
              :name="phone.name"
              :value="phone.value"
              :placeholder="phone.placeholder"
              :status="phone.status"
              @touchstart="handleChange"
              @focus="handleChange"
              @input="handleChange"
              @paste="handleChange"
              @change="handleChange"
            />
            <span class="promo__form-error" v-if="phone.error">{{
              phone.error
            }}</span>
          </div>
          <div class="promo__form-block">
            <input
              :class="`promo__form-input promo__form-input_${email.status}`"
              :type="email.type"
              :name="email.name"
              :value="email.value"
              :placeholder="email.placeholder"
              :status="email.status"
              @touchstart="handleChange"
              @focus="handleChange"
              @input="handleChange"
              @paste="handleChange"
              @change="handleChange"
            />
            <span class="promo__form-error" v-if="email.error">{{
              email.error
            }}</span>
          </div>
          <div class="promo__form-block">
            <input
              :class="`promo__form-input promo__form-input_${occupation.status}`"
              :type="occupation.type"
              :name="occupation.name"
              :value="occupation.value"
              :placeholder="occupation.placeholder"
              :status="occupation.status"
              @touchstart="handleChange"
              @focus="handleChange"
              @input="handleChange"
              @paste="handleChange"
              @change="handleChange"
            />
            <span class="promo__form-error" v-if="occupation.error">{{
              occupation.error
            }}</span>
          </div>
        </div>
        <div class="promo__form-footer">
          <button
            class="promo__form-submit"
            :disabled="!validation"
            @click="handleFormSumbit"
          >
            Отправить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  export default {
    name: "Promo",
    components: {},
    computed: {
      ...mapGetters({
        last_name: "getPromoLastName",
        first_name: "getPromoFirstName",
        middle_name: "getPromoMiddleName",
        phone: "getPromoPhone",
        email: "getPromoEmail",
        occupation: "getPromoOccupation",
        validation: "getPromoValidation",
      }),
    },
    methods: {
      ...mapMutations(["handlePromoChanges"]),
      ...mapActions(["sendPromoChanges"]),
      handleChange(event) {
        let payload = {
          event: event.type,
          name: event.target.getAttribute("name"),
          value: event.target.value,
        };

        if (payload.name === "phone") {
          if (event.type === "focus" && event.target.value == "") {
            event.target.value = "+7 ";
          } else if (event.type === "paste") {
            let value = event.clipboardData.getData("text/plain");

            let pattern =
              /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
            let phoneNumbers = value.match(pattern);

            if (phoneNumbers !== null) {
              let correctNumber = phoneNumbers[0].replace(
                pattern,
                "+7 ($2) $3-$4-$5"
              );

              event.target.value = correctNumber;
            }
          } else if (event.type === "keydown" || event.type === "input") {
            let keyCode;
            event.keyCode && (keyCode = event.keyCode);

            if (event.target.selectionStart < 3) {
              event.target.value = `+7 ${event.data}`;
            }

            let mask = "+7 (___) ___-__-__";
            let index = 0;
            let def = mask.replace(/\D/g, "");
            let val = event.target.value.replace(/\D/g, "");
            let newVal = mask.replace(/[_\d]/g, (arg) =>
              index < val.length ? val.charAt(index++) || def.charAt(index) : arg
            );

            index = newVal.indexOf("_");

            if (index !== -1) {
              index < 5 && (index = 3);
              newVal = newVal.slice(0, index);
            }

            let reg = mask
              .substr(0, event.target.value.length)
              .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
              .replace(/[+()]/g, "\\$&");

            reg = new RegExp(`^${reg}$`);

            if (
              !reg.test(event.target.value) ||
              event.target.value.length < 5 ||
              (keyCode > 47 && keyCode < 58)
            ) {
              event.target.value = newVal;
            }
          }

          payload.value = event.target.value;
        }

        this.handlePromoChanges(payload);
      },
      handleFormSumbit() {
        if (this.validation) {
          this.sendPromoChanges();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .promo {
    width: calc(100% + 20px);
    min-height: 105vh;
    margin: -10px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: linear-gradient(
      341.55deg,
      #3d93f8 0.22%,
      #6e5ff8 50.8%,
      #ba4ff5 101.38%
    );
    font-family: "BellotaText";

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      padding-top: 40px;
    }

    &__logo {
      width: 180px;
      margin-bottom: 40px;
      margin-right: 5%;
    }

    &__headline {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;
    }

    &__form {
      width: 100%;
      margin-bottom: 20px;

      &-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 15px;
        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-input {
        width: 100%;
        background: #f8fcff;
        box-shadow: 0px 3px 5px 3px rgba(47, 130, 176, 0.23);
        border: 1px solid transparent;
        border-radius: 10px;
        font-family: "BellotaText";
        font-weight: 300;
        font-size: 13px;
        padding: 13px 27px;

        &::placeholder {
          color: #100f4b;
        }

        &:focus {
          border-color: #a554f6;
        }

        &_valid {
          background: #c1bfff;
        }

        &_invalid {
          border-color: #ec1b1b !important;
        }
      }

      &-error {
        position: absolute;
        top: 100%;
        left: 27px;
        font-size: 9px;
        color: #fefefe;
      }

      &-footer {
        display: flex;
        justify-content: center;
        margin-top: 35px;
      }

      &-submit {
        background: linear-gradient(90deg, #af75ff 0%, #6a84ff 100%);
        box-shadow: 2px 2px 4px #6312c9;
        border-radius: 7px;
        border: 0;
        font-family: "BellotaText";
        font-weight: 500;
        font-size: 13px;
        color: #ffffff;
        padding: 9px 20px;
        cursor: pointer;

        &:disabled {
          background: #dddddd;
          box-shadow: none;
          pointer-events: none;
        }
      }
    }
  }
</style>
<template>
  <input
    :class="{
      input: true,
      input_valid: this.status === 'valid',
      input_invalid: this.status === 'invalid',
    }"
    v-bind:status="status"
    v-bind:align="align"
  />
</template>

<script>
  export default {
    name: "Input",
    props: {
      status: String,
      align: String,
    },
  };
</script>

<style lang="scss">
  .input {
    height: 36px;
    width: 100%;
    padding-left: 12px;
    padding-right: 10px;
    font-family: "Avenir";
    font-size: 12px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: $colorMainLight;
    box-shadow: $shadowLight;
    color: #222325;
    -webkit-text-fill-color: #222325;

    &:disabled {
      border: 1px solid #dcdcdc;
      box-shadow: none;
      -webkit-text-fill-color: #222325;
      opacity: 1;
    }

    &:focus {
      background-color: #fff;
    }

    &::placeholder {
      color: #9b9b9b;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background-color: $colorMainLight;
      box-shadow: $shadowLight;
    }

    &[data-align="center"] {
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }

    &_valid {
      border-color: #41c6eb;
    }

    &_invalid {
      border-color: #ff8068;
    }
  }
</style>

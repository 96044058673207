<template>
  <div class="discount">
    <div class="discount__wrapper">
      <img class="discount__logo" src="../assets/images/logotype2.svg" alt="" />
      <div class="discount__headline">Поздравляем!</div>
      <div class="discount__text">
        <p class="discount__text-paragraph">Вы получили скидку</p>
        <div class="discount__amount">
          <span class="discount__amount-value">50 000</span>
          <span class="discount__amount-unit">руб.</span>
        </div>
        <p class="discount__text-paragraph">
          на установку адиабатической системы увлажнения*
        </p>
        <p class="discount__text-paragraph">
          Письмо с промокодом было отправлено вам на почту. Воспользуйтесь им
          при оформлении заказа.
        </p>
      </div>
      <div class="discount__notice">
        *В случае заказа индивидуальной системы увлажнения
        <span class="discount__notice-strong">в течении 6 месяцев</span> с
        момента регистрации
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Discount",
  };
</script>

<style lang="scss" scoped>
  .discount {
    width: calc(100% + 20px);
    min-height: 105vh;
    margin: -10px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: linear-gradient(
      341.55deg,
      #3d93f8 0.22%,
      #6e5ff8 50.8%,
      #ba4ff5 101.38%
    );

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      padding-top: 40px;
    }

    &__logo {
      width: 180px;
      margin-bottom: 70px;
    }

    &__headline {
      font-family: "CenturyGothic";
      font-weight: 700;
      font-size: 44px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 40px;
    }

    &__text {
      text-align: center;
      color: #ffffff;

      &-paragraph {
        font-family: "BellotaText";
        font-weight: 400;
        font-size: 18px;
        margin: 0;

        &:last-of-type {
          margin-top: 40px;
        }
      }
    }

    &__amount {
      margin: 10px 0;
      font-family: "CenturyGothic";

      &-value {
        font-weight: 700;
        font-size: 45px;
      }

      &-unit {
        margin-left: 10px;
        font-weight: 400;
        font-size: 26px;
      }
    }

    &__notice {
      font-family: "BellotaText";
      font-weight: 400;
      font-size: 13px;
      text-align: center;
      color: #fefefe;
      margin-top: 80px;
      margin-bottom: 20px;

      &-strong {
        font-weight: 700;
      }
    }
  }
</style>
<template>
  <label class="checkbox">
    <input class="checkbox__input" type="checkbox" :checked="this.status" />
    <span class="checkbox__checkmark"></span>
  </label>
</template>

<script>
  export default {
    name: "Checkbox",
    props: {
      status: Boolean,
    },
  };
</script>

<style lang="scss">
  .checkbox {
    position: relative;
    display: flex;
    width: $checkboxSize;
    min-width: $checkboxSize;
    height: $checkboxSize;
    cursor: pointer;
    user-select: none;
    border-radius: 6px;
    background-color: $colorMainLight;
    box-shadow: $shadowLight;

    &:hover {
      border-color: #1aba03;
    }

    &__input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;

      &:checked ~ .checkbox__checkmark {
        display: block;
      }
    }

    &__checkmark {
      position: absolute;
      left: $checkboxSize / 3;
      top: $checkboxSize / 6;
      display: none;
      width: $checkboxSize / 3;
      height: $checkboxSize / 2;
      border: solid #41c6eb;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
    }
  }
</style>

import axios from 'axios';
import router from '@/router';

export default {
  state: {
    last_name: {
      type: 'text',
      name: "last_name",
      value: null,
      error: null,
      placeholder: 'Фамилия',
      status: 'default',
    },
    first_name: {
      type: 'text',
      name: "first_name",
      value: null,
      error: null,
      placeholder: 'Имя',
      status: 'default',
    },
    middle_name: {
      type: 'text',
      name: "middle_name",
      value: null,
      error: null,
      placeholder: 'Отчество',
      status: 'default',
    },
    phone: {
      type: 'tel',
      name: "phone",
      value: null,
      error: null,
      placeholder: 'Телефон',
      status: 'default',
    },
    email: {
      type: 'email',
      name: "email",
      value: null,
      error: null,
      placeholder: 'Эл. почта',
      status: 'default',
    },
    occupation: {
      type: 'text',
      name: "occupation",
      value: null,
      error: null,
      placeholder: 'Род деятельности',
      status: 'default',
    },
    validation: false,
    status: null,
    errors: null,
  },
  getters: {
    getPromoLastName: state => state.last_name,
    getPromoFirstName: state => state.first_name,
    getPromoMiddleName: state => state.middle_name,
    getPromoPhone: state => state.phone,
    getPromoEmail: state => state.email,
    getPromoOccupation: state => state.occupation,
    getPromoValidation: state => state.validation,
  },
  mutations: {
    handlePromoChanges(state, payload) {
      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        let module = {
          state: state,
          payload: payload,
        }

        this.commit("handleValidation", module);
      }
    },
  },
  actions: {
    sendPromoChanges(context) {
      context.commit("showPreloader");

      axios
        .post("https://show-api.airwet.app/api/customers/sendForm", {
          data: {
            last_name: context.state.last_name.value,
            first_name: context.state.first_name.value,
            middle_name: context.state.middle_name.value,
            phone: context.state.phone.value,
            email: context.state.email.value,
            occupation: context.state.occupation.value,
          }
        })
        .then((response) => {
          context.commit("hidePreloader");

          if (response.status === 200) {
            router.push("/discount");
          }
        })
        .catch((error) => {
          context.commit("handleShowError", error)
        });
    }
  },
}
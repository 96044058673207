import axios from 'axios'

export default {
  state: {
    navbar: {
      current: 1,
      list: [{
          id: 1,
          quantity: 6,
          name: 'Объекты',
          feature: 3,
          isActive: true
        },
        {
          id: 2,
          quantity: 24,
          name: 'Задачи',
          feature: '2089',
          isActive: false,
        },
        {
          id: 3,
          quantity: 18,
          name: 'Мои задачи',
          feature: '2089',
          isActive: false,
        },
        {
          id: 4,
          quantity: 11,
          name: 'Исполнители',
          feature: 'Airwet',
          isActive: false,
        },
      ],
      search: {
        query: null,
        validation: false,
        result: null,
      },
    },
    objects: {
      sort: {
        param: null,
        order: null,
      },
      list: []
    },
    tasks: {
      sort: {
        param: 'date',
        order: 'asc',
      },
      list: []
    },
    personal: {
      sort: {
        param: 'date',
        order: 'asc',
      },
      list: []
    },
    staff: {
      sort: {
        param: 'date',
        order: 'asc',
      },
      list: []
    },
  },
  getters: {
    getSystemsNavbar: state => state.navbar,
    getSystemsObjects: state => state.objects,
    getSystemsTasks: state => state.tasks,
    getSystemsPersonal: state => state.personal,
    getSystemsStaff: state => state.staff,
  },
  mutations: {
    updateSystemsNavbar(state, payload) {
      state.navbar = {
        current: 1,
        list: [{
            id: 1,
            quantity: payload.length,
            name: 'Объекты',
            feature: 3,
            isActive: true
          },
          {
            id: 2,
            quantity: 24,
            name: 'Задачи',
            feature: '2089',
            isActive: false,
          },
          {
            id: 3,
            quantity: 18,
            name: 'Мои задачи',
            feature: '2089',
            isActive: false,
          },
          {
            id: 4,
            quantity: 11,
            name: 'Исполнители',
            feature: 'Airwet',
            isActive: false,
          },
        ],
        search: {
          query: null,
          validation: false,
        },
      }
    },
    updateSystemsObjects(state, payload) {
      state.objects.list = payload;

      state.objects.list.forEach(system => {
        if (system.state === 0) {
          system.state = {
            value: 'disabled',
            text: 'выключена'
          }
        }

        if (system.state === 1) {
          system.state = {
            value: 'enabled',
            text: 'включена'
          }
        }

        if (system.state === 2) {
          system.state = {
            value: 'error',
            text: 'ошибка'
          }
        }

        if (system.state === 3) {
          system.state = {
            value: 'active',
            text: 'работает'
          }
        }

        if (system.state === 4) {
          system.state = {
            value: 'inactive',
            text: 'не работает'
          }
        }
      });
    },
    updateSystemsTasks(state) {
      state.tasks.list = [{
        id: 1,
        period: '18-12-2021',
        number: 'A-012-37-22-12-2021',
        kind: {
          name: 'error',
          text: 'Прокладка сетей'
        },
        contract: '9800-21-12-2021',
        type: 'Квартира',
        address: 'Новокуйбышевск, ул. Перестройческая 23, 78',
        executor: 'Авелович А.'
      }];
    },
    updateSystemsPersonal(state) {
      state.personal.list = [{
          id: 1,
          period: '18-12-2021',
          number: 'A-012-37-22-12-2021',
          kind: {
            name: 'success',
            text: 'Ошибка'
          },
          contract: '9800-21-12-2021',
          type: 'Дом',
          address: 'Новокуйбышевск, ул. Перестройческая 23, 78',
          status: {
            name: 'done',
            text: '22-12-2021 Выполнено'
          },
        },
        {
          id: 2,
          period: '18-12-2021',
          number: 'A-012-37-22-12-2021',
          kind: {
            name: 'error',
            text: 'Ошибка'
          },
          contract: '9800-21-12-2021',
          type: 'Квартира',
          address: 'Санкт-Петербург, ул.Перестройческая 23, 78',
          status: {
            name: 'process',
            text: '22-12-2021'
          },
        }
      ]
    },
    updateSystemsStaff(state) {
      state.staff.list = [{
          id: 1,
          company: 'Airwet',
          executor: 'Жоворьев Руслан',
          type: {
            name: 'default',
            text: 'продажи'
          },
          city: 'Екатеринбург',
          phone: '+7 (900) 18 19 997',
          email: 'emilyanov.eng@airwet.ru',
        },
        {
          id: 2,
          company: 'Airwet Самара',
          executor: 'Емельянов Владислав Яковлевич',
          type: {
            name: 'pro',
            text: 'инженер'
          },
          city: 'Самара',
          phone: '+7 (912) 34 43 765',
          email: 'emilyanov.eng@airwet.ru',
        },
      ];
    },
    sortSystemsTable(state, payload) {
      let order = '';

      if (payload.order === null) payload.order = 'asc';
      payload.order === 'asc' ? order = 'desc' : order = 'asc';

      if (payload.tab === 'objects') {
        if (payload.param === 'date') {
          if (payload.order === 'asc') {
            state.objects.list.sort((a, b) => b.contract.date - a.contract.date);
          }
          if (payload.order === 'desc') {
            state.objects.list.sort((a, b) => a.contract.date - b.contract.date);
          }
        }

        if (payload.param === 'type') {
          state.objects.list.sort((a, b) => {
            let typeA = a.type.toLowerCase();
            let typeB = b.type.toLowerCase();

            if (payload.order === 'asc') {
              if (typeA < typeB) return -1
              if (typeA > typeB) return 1
              return 0
            }

            if (payload.order === 'desc') {
              if (typeA > typeB) return -1
              if (typeA < typeB) return 1
              return 0
            }
          })
        }

        if (payload.param === 'address') {
          state.objects.list.sort((a, b) => {
            let addressA = a.address.toLowerCase();
            let addressB = b.address.toLowerCase();

            if (payload.order === 'asc') {
              if (addressA < addressB) return -1
              if (addressA > addressB) return 1
              return 0
            }

            if (payload.order === 'desc') {
              if (addressA > addressB) return -1
              if (addressA < addressB) return 1
              return 0
            }
          })
        }

        if (payload.param === 'state') {
          state.objects.list.sort((a, b) => {
            let stateA = a.state.text.toLowerCase();
            let stateB = b.state.text.toLowerCase();

            if (payload.order === 'asc') {
              if (stateA < stateB) return -1
              if (stateA > stateB) return 1
              return 0
            }

            if (payload.order === 'desc') {
              if (stateA > stateB) return -1
              if (stateA < stateB) return 1
              return 0
            }
          })
        }
      }

      state[payload.tab].sort.param = payload.param;
      state[payload.tab].sort.order = order;
    },
    changeSystemsObjectsSearch(state, payload) {
      if (payload.length > 2) state.navbar.search.validation = true;
      else state.navbar.search.validation = false;

      state.navbar.search.query = payload;
    },
    clearSystemsObjectsSearch(state, payload) {
      if (payload) {
        state.navbar.search.result = payload;
        state.navbar.search.validation = false;
      } else {
        state.navbar.search.result = null;
        state.navbar.search.query = null;
        state.navbar.search.validation = false;
      }
    },
    setSystemsActiveNavbarItem(state, item) {
      state.navbar.list.forEach(element => element.isActive = false);
      state.navbar.list.forEach(element => {
        if (element.id == item.id) {
          element.isActive = true;
          state.navbar.current = +item.id;
        }
      });
    },
    clearSystemsState(state) {
      state.objects.list = [];
      state.tasks.list = [];
      state.personal.list = [];
      state.staff.list = [];
    }
  },
  actions: {
    fetchSystemsObjects(context) {
      context.commit("showPreloader");

      axios
        .get("https://show-api.airwet.app/api/systems/?populate[0]=contract", this.state.common.headers)
        .then((response) => {
          context.commit('updateSystemsObjects', response.data.data);
          context.commit('updateSystemsNavbar', response.data.data);
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    searchSystemsObjects(context, payload) {
      context.commit("showPreloader");

      axios
        .get(`https://show-api.airwet.app/api/systems/?populate[0]=contract&filters[name][$containsi]=${payload}`, this.state.common.headers)
        .then((response) => {
          context.commit('updateSystemsObjects', response.data.data);
          context.commit('clearSystemsObjectsSearch', response.data.data);
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    clearSystemsObjects(context) {
      context.commit("showPreloader");

      if (context.state.navbar.search.result) {
        axios
          .get("https://show-api.airwet.app/api/systems/?populate[0]=contract", this.state.common.headers)
          .then((response) => {
            context.commit('updateSystemsObjects', response.data.data);
            context.commit('clearSystemsObjectsSearch');
            context.commit("hidePreloader");
          })
          .catch((error) => context.commit("handleError", error));
      } else {
        context.commit('clearSystemsObjectsSearch');
        context.commit("hidePreloader");
      }
    },
    createSystemsObject(context, payload) {
      context.commit("showPreloader");

      if (payload.owner === 'new') {
        let acronym = '';

        if (payload.first_name !== null && payload.last_name !== null) {
          acronym = `${payload.first_name.substr(0, 1)}${payload.last_name.substr(0, 1)}`;
        }

        axios
          .post("https://show-api.airwet.app/api/auth/local/register", {
            username: payload.email.substring(0, payload.email.indexOf('@')),
            email: payload.email,
            password: payload.password,
            first_name: payload.first_name,
            last_name: payload.last_name,
            middle_name: payload.middle_name,
            phone: payload.phone,
            acronym: acronym,
            first_entry: true,
          }, this.state.common.headers)
          .then((user) => {
            axios
              .post("https://show-api.airwet.app/api/users/sendMailToNewUser", {
                user_id: user.data.user.id,
                password: payload.password,
              }, this.state.common.headers)
              .then(() => {
                axios
                  .post('https://show-api.airwet.app/api/systems', {
                    data: {
                      type: payload.type,
                      address: payload.address,
                      name: payload.name,
                      from_user: user.data.user.id,
                    },
                  }, this.state.common.headers)
                  .then((system) => {
                    axios
                      .post('https://show-api.airwet.app/api/contracts', {
                        data: {
                          value: payload.contract,
                          phone: payload.phone,
                          date: payload.date,
                          from_system: system.data.data.id,
                        },
                      }, this.state.common.headers)
                      .then(() => {
                        axios
                          .post("https://show-api.airwet.app/api/boards", {
                            data: {
                              params: {
                                features: {
                                  "p_in_min": 0.8,
                                  "p_in_max": 7,
                                  "p_out_min": 35,
                                  "p_out_nom": 55,
                                  "p_out_max": 85,
                                  "p_precharge": 25,
                                  "precharge_timeout": 45,
                                  "p_delay": 10,
                                  "p_timeout": 2,
                                  "pump_rpm": 750,
                                  "Kp": 3,
                                  "Ki": 0.5,
                                  "Kd": 0,
                                  "p_drain": 32,
                                  "discharge_timeout": 300,
                                  "t_drain": 3,
                                  "t_open": 60,
                                  "t_close": 60,
                                  "hum_min": 0,
                                  "hum_max": 58,
                                },
                                network: {
                                  "MAC": "",
                                  "IP": "",
                                  "mask": "",
                                  "gateway": "",
                                  "DNS": ""
                                }
                              },
                              from_system: system.data.data.id
                            },
                          }, this.state.common.headers)
                          .then(() => {
                            axios
                              .get("https://show-api.airwet.app/api/systems/?populate[0]=contract", this.state.common.headers)
                              .then((response) => {
                                context.commit('updateSystemsObjects', response.data.data);
                                context.commit("hidePreloader");
                              })
                          })
                      })
                  })
              })
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload.owner === 'exist') {
        axios
          .get(`https://show-api.airwet.app/api/users/GetUserIdFromEmail/${payload.email}`, this.state.common.headers)
          .then((user) => {
            axios
              .post('https://show-api.airwet.app/api/systems', {
                data: {
                  type: payload.type,
                  address: payload.address,
                  name: payload.name,
                  from_user: user.data.data.id
                },
              }, this.state.common.headers)
              .then((system) => {
                axios
                  .post('https://show-api.airwet.app/api/contracts', {
                    data: {
                      value: payload.contract,
                      phone: payload.phone,
                      date: payload.date,
                      from_system: system.data.data.id,
                    },
                  }, this.state.common.headers)
                  .then(() => {
                    axios
                      .post("https://show-api.airwet.app/api/boards", {
                        data: {
                          params: {
                            features: {
                              "p_in_min": 0.8,
                              "p_in_max": 7,
                              "p_out_min": 35,
                              "p_out_nom": 55,
                              "p_out_max": 85,
                              "p_precharge": 25,
                              "p_drain": 32,
                              "p_delay": 10,
                              "p_timeout": 2,
                              "t_drain": 3,
                              "t_open": 60,
                              "t_close": 60,
                              "pump_rpm": 750,
                              "Kp": 3,
                              "Ki": 0.5,
                              "Kd": 0,
                              "discharge_timeout": 300,
                              "precharge_timeout": 45,
                            },
                            network: {
                              "MAC": "",
                              "IP": "",
                              "mask": "",
                              "gateway": "",
                              "DNS": ""
                            }
                          },
                          from_system: system.data.data.id
                        }
                      }, this.state.common.headers)
                      .then(() => {
                        axios
                          .get("https://show-api.airwet.app/api/systems/?populate[0]=contract", this.state.common.headers)
                          .then((response) => {
                            context.commit('updateSystemsObjects', response.data.data);
                            context.commit("hidePreloader");
                          })
                      })
                  })
              })
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    deleteSystemsObject(context, payload) {
      context.commit("showPreloader");

      axios
        .delete(`https://show-api.airwet.app/api/systems/${payload.system}`, this.state.common.headers)
        .then(() => {
          axios
            .get("https://show-api.airwet.app/api/systems/?populate[0]=contract", this.state.common.headers)
            .then((response) => {
              context.commit('updateSystemsObjects', response.data.data);
              context.commit("hidePreloader");
            })
        })
        .catch((error) => context.commit("handleError", error));
    }
  }
}
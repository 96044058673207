<template>
  <div class="login">
    <div class="login__wrapper">
      <img class="login__logo" src="../assets/images/logo.svg" alt="" />
      <div class="login__title">Вход</div>
      <form class="login__form" @submit.prevent>
        <div class="login__form-body">
          <div class="login__form-block">
            <div class="login__form-title">Логин</div>
            <Input
              :type="login.type"
              :name="login.name"
              :value="login.value"
              :status="login.status"
              autocomplete
              @touchstart="handleChange"
              @focus="handleChange"
              @input="handleChange"
              @paste="handleChange"
              @change="handleChange"
            />
            <span class="login__form-error" v-if="login.error">{{
              login.error
            }}</span>
          </div>
          <div class="login__form-block">
            <div class="login__form-title">Пароль</div>
            <Input
              :type="password.type"
              :name="password.name"
              :value="password.value"
              :status="password.status"
              autocomplete
              readonly
              @touchstart="handleChange"
              @focus="handleChange"
              @input="handleChange"
              @paste="handleChange"
              @change="handleChange"
            />
            <span class="login__form-error" v-if="password.error">{{
              password.error
            }}</span>
          </div>
        </div>
        <div class="login__form-footer">
          <Button
            :type="'text'"
            :value="'Войти'"
            :paint="'blue'"
            :disabled="!validation"
            @click="handleFormSumbit"
          />
        </div>
      </form>
      <div class="login__notice">
        Если у вас нет учетной записи в системе Airwet, пройдите
        <a class="login__notice-link" href="#" @click.prevent="goToRegister"
          >Регистрацию</a
        >, чтобы ее создать!
      </div>
      <div class="login__notice">
        Для восстановления пароля перейдите по
        <a class="login__notice-link" href="#" @click.prevent="goToRecover"
          >ссылке</a
        >.
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";
  import Button from "../components/Button";
  import Input from "../components/Input";

  export default {
    name: "Login",
    components: {
      Button,
      Input,
    },
    computed: {
      ...mapGetters({
        login: "getAuthorizationLogin",
        password: "getAuthorizationPassword",
        validation: "getAuthorizationValidation",
      }),
    },
    methods: {
      ...mapMutations(["handleAuthorizationChanges"]),
      ...mapActions(["loginAuthorization", "logoutAuthorization"]),
      handleChange(event) {
        let payload = {
          event: event.type,
          name: event.target.getAttribute("name"),
          value: event.target.value,
        };

        if (event.type === "focus" || event.type === "touchstart") {
          event.target.removeAttribute("readonly");
        }

        this.handleAuthorizationChanges(payload);
      },
      goToRegister() {
        this.$router.push("/register");
      },
      goToRecover() {
        this.$router.push("/recover");
      },
      goToDemo() {
        window.location.href = "https://demo.airwet.app";
      },
      handleFormSumbit() {
        if (this.validation) {
          let user = {
            identifier: this.login.value,
            password: this.password.value,
          };

          this.loginAuthorization(user);
        }
      },
      logout() {
        this.logoutAuthorization();
      },
    },
    created() {
      this.logoutAuthorization();
    },
  };
</script>

<style lang="scss" scoped>
  .login {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    @include mediaquery(md) {
      min-height: calc(100vh - 20px);
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @include mediaquery(sm) {
        width: 400px;
      }
    }

    &__logo {
      width: 50px;
      margin-bottom: 24px;

      @include mediaquery(sm) {
        margin-bottom: 44px;
      }
    }

    &__title {
      width: 100%;
      margin-bottom: 20px;
      text-transform: uppercase;
      text-align: center;
      font-size: 24px;
    }

    &__form {
      @include base;

      width: 100%;
      padding: 20px;

      @include mediaquery(sm) {
        padding: 46px 40px 25px;
      }

      &-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-subblock {
        display: flex;
        align-items: center;
      }

      &-title {
        @include title;
      }

      &-error {
        position: absolute;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &-link {
        font-size: 12px;
        color: #41c6eb;
      }

      &-footer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: space-between;
        }

        button {
          width: 100%;
        }
      }

      &-footer2 {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #dcdcdc;

        @include mediaquery(sm) {
          justify-content: space-between;
        }

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }
      }

      &-errors {
        margin-top: 10px;
        font-size: 12px;
        color: #ff8068;
      }
    }

    &__notice {
      font-size: 12px;
      margin-top: 16px;
      text-align: center;

      &-link {
        color: #41c6eb;
      }
    }
  }
</style>
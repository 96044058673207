<template>
  <main class="objects" :data-id="about.id">
    <div class="objects__content">
      <div class="notification" v-if="notification.visible">
        <div class="notification__info">
          <div class="notification__title">{{ notification.title }}</div>
          <div class="notification__text">{{ notification.text }}</div>
        </div>
      </div>
      <slot v-if="navbar.length > 0">
        <div class="objects__navbar">
          <ul class="objects__navbar-list">
            <li
              v-for="item in navbar"
              :class="[
                item.isActive
                  ? 'objects__navbar-item objects__navbar-item_current'
                  : 'objects__navbar-item',
              ]"
              :data-id="item.id"
              :key="item.id"
              @click="setActiveItem"
            >
              <div class="objects__navbar-avatar"></div>
              <div class="objects__navbar-info">
                <span class="objects__navbar-name">{{ item.name }}</span>
                <span class="objects__navbar-feature">
                  {{ item.contract.value }}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <section class="about">
          <div class="about__header" @click="toggleSection('about')">
            <div class="about__wrapper">
              <div class="about__name">
                <div class="about__title">Имя объекта</div>
                <Input
                  :type="'text'"
                  :name="about.name.name"
                  :value="about.name.value"
                  :status="about.name.status"
                  @paste="handleChanges($event, about.name.element)"
                  @input="handleChanges($event, about.name.element)"
                  @change="handleChanges($event, about.name.element)"
                  @click.stop="false"
                />
                <span class="about__error" v-if="about.name.error">{{
                  about.name.errorText
                }}</span>
              </div>
              <div class="about__address">
                <div class="about__title">Адрес объекта</div>
                <Input :type="'text'" :value="about.address" :disabled="true" />
              </div>
              <div class="about__service">
                <div class="about__title">Дата планового ТО</div>
                <Input
                  :type="'text'"
                  :value="this.convertDate(about.date_maintenance_plan)"
                  :disabled="true"
                />
              </div>
              <div class="about__mileage">
                <div class="about__title">Моточасы</div>
                <Input
                  :type="'text'"
                  :value="about.pump_hours"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="about__control">
              <div class="about__info">
                <div :class="`about__state about__state_${about.state.value}`">
                  {{ about.state.text }}
                </div>
                <slot
                  v-if="
                    about.state.value === 'error' ||
                    about.state.value === 'disabled'
                  "
                >
                  <Switch
                    :paint="'blue'"
                    :status="false"
                    :disabled="true"
                    @click.stop="false"
                  />
                </slot>
                <slot v-else>
                  <Switch
                    :paint="'blue'"
                    :status="about.active_user"
                    @change.prevent="changeSystemStatus"
                  />
                </slot>
              </div>
              <button
                class="about__button"
                @click.stop="toggleSection('about')"
              >
                <span
                  :class="[
                    about.extended
                      ? 'about__button-icon about__button-icon_rotate'
                      : 'about__button-icon',
                  ]"
                ></span>
              </button>
            </div>
          </div>
          <div class="about__body" v-show="about.extended">
            <div class="about__contract">
              Договор {{ about.contract.value }} от
              {{ this.convertDate(about.contract.date) }}
            </div>
            <div class="about__params">
              <div class="about__param">
                <div class="about__title">Конфигурация системы</div>
                <slot v-if="about.type === 'pro'">
                  <Input
                    :type="'text'"
                    :value="`Airwet pro`"
                    :disabled="true"
                  />
                </slot>
                <slot v-if="about.type === 'home'">
                  <Input
                    :type="'text'"
                    :value="`Airwet ${about.type} ${
                      about.system_type !== null ? about.system_type : ''
                    } ${zones.length > 0 ? this.declOfNum(zones.length) : ''}`"
                    :disabled="true"
                  />
                </slot>
                <slot v-if="about.type === 'light'">
                  <Input
                    :type="'text'"
                    :value="`Airwet ${about.type} ${
                      zones.length > 0 ? this.declOfNum(zones.length) : ''
                    }`"
                    :disabled="true"
                  />
                </slot>
              </div>
              <div class="about__param">
                <div class="about__title">Дата запуска</div>
                <Input
                  :type="'text'"
                  :value="this.convertDate(about.date_start)"
                  :disabled="true"
                />
              </div>
              <div class="about__param">
                <div class="about__title">Гарантия до</div>
                <Input
                  :type="'text'"
                  :value="this.convertDate(about.date_warranty)"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="about__vacation" v-if="false">
              <div class="about__vacation-info">
                <div class="about__vacation-title">Отпуск</div>
                <div class="about__vacation-description">
                  Перейти в режим отпуск
                </div>
              </div>
              <div class="about__vacation-control">
                <Switch :status="false" :paint="'blue'" />
              </div>
            </div>
            <section class="maintenance" v-if="false">
              <div class="maintenance__header" v-on:click="toggleSubSection">
                <div class="maintenance__info">
                  <div class="maintenance__name">01 МАЙ 2022</div>
                  <div class="maintenance__description">
                    дата ближайшего сервисного обслуживания
                  </div>
                </div>
                <div class="maintenance__control">
                  <button
                    class="maintenance__button"
                    v-on:click.stop="toggleSubSection"
                  >
                    <span class="maintenance__button-icon"></span>
                  </button>
                </div>
              </div>
              <div class="maintenance__body">
                <div class="maintenance__blocks">
                  <div class="maintenance__block">
                    <div class="maintenance__title">01.05.2022</div>
                    <div class="maintenance__wrapper">
                      <Input
                        :type="'text'"
                        :value="'Техническое Обслуживание №1'"
                        :disabled="false"
                      />
                      <Button
                        :type="'text'"
                        :value="'Записаться'"
                        :paint="'blue'"
                        @click="getMaintenance"
                      />
                    </div>
                  </div>
                  <div class="maintenance__block">
                    <div class="maintenance__title">01.05.2022</div>
                    <div class="maintenance__wrapper">
                      <Input
                        :type="'text'"
                        :value="'Техническое Обслуживание №1'"
                        :disabled="true"
                      />
                      <Button
                        :type="'text'"
                        :value="'2 310 руб.'"
                        :paint="'gray'"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="maintenance__block">
                    <div class="maintenance__title">01.05.2022</div>
                    <div class="maintenance__wrapper">
                      <Input
                        :type="'text'"
                        :value="'Техническое Обслуживание №1'"
                        :disabled="true"
                      />
                      <Button
                        :type="'text'"
                        :value="'2 310 руб.'"
                        :paint="'gray'"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="maintenance__block">
                    <div class="maintenance__title">01.05.2022</div>
                    <div class="maintenance__wrapper">
                      <Input
                        :type="'text'"
                        :value="'Техническое Обслуживание №1'"
                        :disabled="true"
                      />
                      <Button
                        :type="'text'"
                        :value="'2 310 руб.'"
                        :paint="'gray'"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="documents"
              v-if="
                Array.isArray(about.documents) && about.documents.length > 0
              "
            >
              <div class="documents__header" v-on:click="toggleSubSection">
                <div class="documents__info">
                  <div class="documents__name">ДОКУМЕНТЫ</div>
                  <div class="documents__description">
                    все документы вашей системы
                  </div>
                </div>
                <div class="documents__control">
                  <button
                    class="documents__button"
                    v-on:click.stop="toggleSubSection"
                  >
                    <span class="documents__button-icon"></span>
                  </button>
                </div>
              </div>
              <div class="documents__body">
                <div class="documents__preview">
                  <div
                    class="documents__document"
                    v-for="document in about.documents"
                    :data-id="document.id"
                    :key="document.id"
                  >
                    <div class="documents__document-info">
                      <div class="documents__document-name">
                        {{ document.name }}.{{ document.file.ext.slice(1) }}
                      </div>
                      <div class="documents__document-size">
                        {{ document.file.size }} Кб
                      </div>
                    </div>
                    <div class="documents__document-control">
                      <Button
                        :type="'action'"
                        :value="'download'"
                        :paint="'gray'"
                        @click="downloadDocument(document)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="about__footer">
              <Button
                :type="'text'"
                :value="'Отмена'"
                :paint="'gray'"
                :disabled="about.cancellation"
                @click="cancelChanges($event, 'about')"
              />
              <Button
                :type="'text'"
                :value="'Сохранить изменения'"
                :paint="'blue'"
                :disabled="!about.validation"
                @click="sendChanges('about')"
              />
            </div>
          </div>
        </section>
        <section
          class="hum"
          v-if="Array.isArray(hum.programs) && hum.programs.length > 0"
        >
          <div class="hum__header" @click="toggleSection('hum')">
            <div class="hum__wrapper">
              <div class="hum__list">
                <Dropdown
                  type="simple"
                  :items="hum.list.categories"
                  :value="hum.list.value"
                  paint="blue"
                  @click.stop="handleProperty($event, 'hum', 'programs')"
                />
              </div>
              <div class="hum__period" @click.stop="false">
                <div class="hum__start">
                  <span class="hum__start-wrapper">
                    <span class="hum__start-strong">Старт</span>
                    программы
                  </span>
                  <span class="hum__start-date">
                    {{ convertProgramTime(hum.program.start_day, "day") }}
                  </span>
                </div>
                <div class="hum__stop">
                  <span class="hum__stop-wrapper">
                    <span class="hum__stop-strong">Стоп</span>
                    программы
                  </span>
                  <span class="hum__stop-date">
                    {{ convertProgramTime(hum.program.stop_day, "day") }}
                  </span>
                </div>
              </div>
            </div>
            <div class="hum__control">
              <Switch
                :status="hum.program.active_user"
                :paint="'blue'"
                @change.prevent="handleProperty($event, 'hum', 'active_user')"
                @click.stop="false"
              />
              <button class="hum__button" @click.stop="toggleSection('hum')">
                <span
                  :class="[
                    hum.extended
                      ? 'hum__button-icon hum__button-icon_rotate'
                      : 'hum__button-icon',
                  ]"
                ></span>
              </button>
            </div>
          </div>
          <div class="hum__body" v-show="hum.extended">
            <div class="hum__program" :data-id="hum.program.id">
              <div class="hum__column">
                <div class="hum__schedule">
                  <div class="hum__schedule-headline">ГРАФИК ПРОГРАММЫ</div>
                  <div class="hum__schedule-content">
                    <div
                      class="hum__schedule-period"
                      v-for="period in hum.program.periods"
                      :id="period.id"
                      :key="period.id"
                      :data-period="period.name"
                    >
                      <div class="hum__schedule-title">
                        {{ period.title }}
                      </div>
                      <div class="hum__schedule-range">
                        <Timepicker
                          :hour="convertProgramTime(period.start.hour, 'hour')"
                          :minute="
                            convertProgramTime(period.start.minute, 'minute')
                          "
                          data-stage="start"
                          @click="handleProperty($event, 'hum', 'range')"
                        />
                        <Timepicker
                          :hour="convertProgramTime(period.stop.hour, 'hour')"
                          :minute="
                            convertProgramTime(period.stop.minute, 'minute')
                          "
                          data-stage="stop"
                          @click="handleProperty($event, 'hum', 'range')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hum__settings">
                  <div class="hum__settings-setting">
                    <div class="hum__settings-stage">СТАРТ</div>
                    <div class="hum__settings-wrapper">
                      <Daypicker
                        :day="convertProgramTime(hum.program.start_day, 'day')"
                        data-stage="start"
                        @click="handleProperty($event, 'hum', 'period')"
                      />
                    </div>
                  </div>
                  <div class="hum__settings-setting">
                    <div class="hum__settings-stage">СТОП</div>
                    <div class="hum__settings-wrapper">
                      <Daypicker
                        :day="convertProgramTime(hum.program.stop_day, 'day')"
                        data-stage="stop"
                        @click="handleProperty($event, 'hum', 'period')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="hum__column">
                <div class="hum__zones">
                  <div class="hum__select">
                    <Checkbox
                      data-period="morning"
                      :status="
                        hum.program.period.name === 'morning' ? true : false
                      "
                      @change="handleProperty($event, 'hum', 'select')"
                    />
                    <Checkbox
                      data-period="day"
                      :status="hum.program.period.name === 'day' ? true : false"
                      @change="handleProperty($event, 'hum', 'select')"
                    />
                    <Checkbox
                      data-period="evening"
                      :status="
                        hum.program.period.name === 'evening' ? true : false
                      "
                      @change="handleProperty($event, 'hum', 'select')"
                    />
                    <Checkbox
                      data-period="night"
                      :status="
                        hum.program.period.name === 'night' ? true : false
                      "
                      @change="handleProperty($event, 'hum', 'select')"
                    />
                  </div>
                  <perfect-scrollbar>
                    <div
                      class="hum__zone"
                      v-for="zone in hum.zones"
                      :id="zone.id"
                      :key="zone.id"
                    >
                      <div class="hum__zone-title">{{ zone.name }}</div>
                      <div class="hum__zone-value">
                        <button
                          class="hum__zone-button"
                          data-period="morning"
                          data-step="decrease"
                          @click="handleProperty($event, 'hum', 'zone')"
                          v-if="zone.hum.morning.control"
                        >
                          <span class="hum__zone-minus"></span>
                        </button>
                        <Input
                          :type="'tel'"
                          :value="
                            zone.hum.morning.value + zone.hum.morning.unit
                          "
                          :status="zone.hum.morning.status"
                          data-period="morning"
                          @focus="handleProperty($event, 'hum', 'zone')"
                          @change="handleProperty($event, 'hum', 'zone')"
                          @input="handleProperty($event, 'hum', 'zone')"
                          @blur="handleProperty($event, 'hum', 'zone')"
                        />
                        <button
                          class="hum__zone-button"
                          data-period="morning"
                          data-step="increase"
                          @click="handleProperty($event, 'hum', 'zone')"
                          v-if="zone.hum.morning.control"
                        >
                          <span class="hum__zone-plus"></span>
                        </button>
                      </div>
                      <div class="hum__zone-value">
                        <button
                          class="hum__zone-button"
                          data-period="day"
                          data-step="decrease"
                          @click="handleProperty($event, 'hum', 'zone')"
                          v-if="zone.hum.day.control"
                        >
                          <span class="hum__zone-minus"></span>
                        </button>
                        <Input
                          :type="'tel'"
                          :value="zone.hum.day.value + zone.hum.day.unit"
                          :status="zone.hum.day.status"
                          data-period="day"
                          @focus="handleProperty($event, 'hum', 'zone')"
                          @change="handleProperty($event, 'hum', 'zone')"
                          @input="handleProperty($event, 'hum', 'zone')"
                          @blur="handleProperty($event, 'hum', 'zone')"
                        />
                        <button
                          class="hum__zone-button"
                          data-period="day"
                          data-step="increase"
                          @click="handleProperty($event, 'hum', 'zone')"
                          v-if="zone.hum.day.control"
                        >
                          <span class="hum__zone-plus"></span>
                        </button>
                      </div>
                      <div class="hum__zone-value">
                        <button
                          class="hum__zone-button"
                          data-period="evening"
                          data-step="decrease"
                          @click="handleProperty($event, 'hum', 'zone')"
                          v-if="zone.hum.evening.control"
                        >
                          <span class="hum__zone-minus"></span>
                        </button>
                        <Input
                          :type="'tel'"
                          :value="
                            zone.hum.evening.value + zone.hum.evening.unit
                          "
                          :status="zone.hum.evening.status"
                          data-period="evening"
                          @focus="handleProperty($event, 'hum', 'zone')"
                          @change="handleProperty($event, 'hum', 'zone')"
                          @input="handleProperty($event, 'hum', 'zone')"
                          @blur="handleProperty($event, 'hum', 'zone')"
                        />
                        <button
                          class="hum__zone-button"
                          data-period="evening"
                          data-step="increase"
                          @click="handleProperty($event, 'hum', 'zone')"
                          v-if="zone.hum.evening.control"
                        >
                          <span class="hum__zone-plus"></span>
                        </button>
                      </div>
                      <div class="hum__zone-value">
                        <button
                          class="hum__zone-button"
                          data-period="night"
                          data-step="decrease"
                          @click="handleProperty($event, 'hum', 'zone')"
                          v-if="zone.hum.night.control"
                        >
                          <span class="hum__zone-minus"></span>
                        </button>
                        <Input
                          :type="'tel'"
                          :value="zone.hum.night.value + zone.hum.night.unit"
                          :status="zone.hum.night.status"
                          data-period="night"
                          @focus="handleProperty($event, 'hum', 'zone')"
                          @change="handleProperty($event, 'hum', 'zone')"
                          @input="handleProperty($event, 'hum', 'zone')"
                          @blur="handleProperty($event, 'hum', 'zone')"
                        />
                        <button
                          class="hum__zone-button"
                          data-period="night"
                          data-step="increase"
                          @click="handleProperty($event, 'hum', 'zone')"
                          v-if="zone.hum.night.control"
                        >
                          <span class="hum__zone-plus"></span>
                        </button>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
                <div class="hum__tuner">
                  <div class="hum__tuner-period">
                    <span class="hum__tuner-title">Влажность периода</span>
                    <div class="hum__tuner-wrapper">
                      <Slider
                        type="horizontal"
                        :min="hum.program.min"
                        :max="hum.program.max"
                        :value="hum.program.period.value"
                        :disabled="!hum.program.period.name"
                        data-scope="period"
                        @input.prevent="handleProperty($event, 'hum', 'value')"
                      />
                      <span class="hum__tuner-value"
                        >{{ hum.program.period.value }}%</span
                      >
                    </div>
                  </div>
                  <div class="hum__tuner-common">
                    <span class="hum__tuner-title">Общая влажность</span>
                    <div class="hum__tuner-wrapper">
                      <Slider
                        type="horizontal"
                        :min="hum.program.min"
                        :max="hum.program.max"
                        :value="hum.program.value"
                        :disabled="false"
                        data-scope="common"
                        @input.prevent="handleProperty($event, 'hum', 'value')"
                      />
                      <span class="hum__tuner-value"
                        >{{ hum.program.value }}%</span
                      >
                    </div>
                  </div>
                </div>
                <div class="hum__footer">
                  <div class="hum__footer-control">
                    <Button
                      :type="'text'"
                      :value="'Отмена'"
                      :paint="'gray'"
                      :disabled="hum.cancellation"
                      @click="cancelChanges($event, 'hum')"
                    />
                    <Button
                      :type="'text'"
                      :value="'Сохранить изменения'"
                      :paint="'blue'"
                      :disabled="!hum.validation"
                      @click="changeProgram"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="objects__zones">
          <div
            v-for="zone in zones"
            :class="zone.current ? 'zone zone_current' : 'zone'"
            :data-id="zone.id"
            :key="zone.id"
            v-click-outside="commonClick"
            @click="setActiveZone"
          >
            <div class="zone__body">
              <div class="zone__name">
                <input
                  :class="{
                    'zone__name-input': true,
                    'zone__name-input_valid': zone.status === 'valid',
                    'zone__name-input_invalid': zone.status === 'invalid',
                  }"
                  type="text"
                  :name="'name'"
                  :status="zone.status"
                  :value="zone.name"
                  @input="handleZone($event, 'input')"
                  @change="handleZone($event, 'input')"
                />
              </div>
              <div
                class="zone__program"
                :data-period="zone.program.period"
                v-if="zone.program && hum.program.active_user"
              >
                <span class="zone__program-title">{{
                  zone.program.title
                }}</span>
                <span class="zone__program-range">{{
                  zone.program.range
                }}</span>
              </div>
              <div class="zone__info">
                <div class="zone__caption">
                  <div class="zone__caption-set">Целевое</div>
                  <hr class="zone__caption-line" />
                  <div class="zone__caption-cur">Текущее</div>
                </div>
                <div class="zone__indicators">
                  <div class="zone__indicator" data-param="hum">
                    <div class="zone__set">
                      <font-awesome-icon icon="arrow-down" />
                      <span class="zone__set-value">{{ zone.hum.set }}</span>
                      <span class="zone__set-unit">%</span>
                    </div>
                    <div class="zone__cur">
                      <font-awesome-icon
                        icon="arrow-up"
                        v-if="zone.hum.activity"
                      />
                      <font-awesome-icon icon="arrow-down" v-else />
                      <span class="zone__cur-value">{{ zone.hum.cur }}</span>
                      <span class="zone__cur-unit">%</span>
                    </div>

                    <span
                      :class="
                        zone.hum.activity
                          ? 'zone__led zone__led_on'
                          : 'zone__led zone__led_off'
                      "
                    ></span>
                  </div>
                  <div class="zone__indicator" data-param="temp">
                    <slot v-if="zone.temp.active_srv">
                      <div class="zone__set">
                        <font-awesome-icon icon="arrow-down" />
                        <span class="zone__set-value">{{ zone.temp.set }}</span>
                        <span class="zone__set-unit">&#176;</span>
                      </div>
                      <div class="zone__cur">
                        <font-awesome-icon
                          icon="arrow-up"
                          v-if="zone.temp.activity"
                        />
                        <font-awesome-icon icon="arrow-down" v-else />
                        <span class="zone__cur-value">{{ zone.temp.cur }}</span>
                        <span class="zone__cur-unit">&#176;</span>
                      </div>
                    </slot>
                    <slot v-else>
                      <div class="zone__set">
                        <span class="zone__set-dash">&mdash;</span>
                        <span class="zone__set-dash">&mdash;</span>
                        <span class="zone__set-unit">&#176;</span>
                      </div>
                      <div class="zone__cur">
                        <span class="zone__cur-value">{{ zone.temp.cur }}</span>
                        <span class="zone__cur-unit">&#176;</span>
                      </div>
                    </slot>
                    <span
                      :class="
                        zone.temp.active_user
                          ? 'zone__led zone__led_on'
                          : 'zone__led zone__led_off'
                      "
                    ></span>
                  </div>
                  <div class="zone__indicator" data-param="carb" v-if="false">
                    <div class="zone__set">
                      <span class="zone__set-value">1189</span>
                      <span class="zone__set-unit">PPM</span>
                    </div>
                    <div class="zone__cur">
                      <div class="zone__cur-wrapper">
                        <font-awesome-icon
                          icon="arrow-up"
                          v-if="zone.carb.active_user && zone.carb.activity"
                        />
                        <font-awesome-icon icon="arrow-down" v-else />
                        <span class="zone__cur-value">820</span>
                      </div>
                      <span class="zone__cur-unit">PPM</span>
                    </div>
                    <span
                      :class="
                        zone.carb.active_user
                          ? 'zone__led zone__led_on'
                          : 'zone__led zone__led_off'
                      "
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="zone__footer" v-show="zone.extended">
              <div class="zone__params">
                <div
                  class="zone__param"
                  data-param="hum"
                  v-if="zone.hum.active_srv"
                >
                  <Switch
                    :paint="'blue'"
                    :name="'activity'"
                    :status="zone.hum.activity"
                    @change="handleZone($event, 'switch', 'hum')"
                  />
                  <div class="zone__tuner">
                    <div class="zone__set">
                      <span class="zone__set-value">{{ zone.hum.set }}</span>
                      <span class="zone__set-unit">%</span>
                    </div>
                    <Slider
                      type="horizontal"
                      :value="zone.hum.set"
                      :min="zone.hum.min"
                      :max="zone.hum.max"
                      :name="'set'"
                      :disabled="!zone.hum.activity"
                      @input="handleZone($event, 'slider', 'hum')"
                      @change="handleZone($event, 'slider', 'hum')"
                    />
                  </div>
                  <img
                    class="zone__icon"
                    src="../assets/images/hum.svg"
                    alt=""
                  />
                </div>
                <div
                  class="zone__param"
                  data-param="temp"
                  v-if="zone.temp.active_srv"
                >
                  <Switch
                    :paint="'orange'"
                    :name="'active_user'"
                    :status="zone.temp.active_user"
                    @change="handleZone($event, 'switch', 'temp')"
                  />
                  <div class="zone__tuner">
                    <div class="zone__set">
                      <span class="zone__set-value">{{ zone.temp.set }}</span>
                      <span class="zone__set-unit">&#176;</span>
                    </div>
                    <Slider
                      type="horizontal"
                      :value="zone.temp.set"
                      :min="zone.temp.min"
                      :max="zone.temp.max"
                      :name="'set'"
                      :disabled="!zone.temp.active_user"
                      @input="handleZone($event, 'slider', 'temp')"
                      @change="handleZone($event, 'slider', 'temp')"
                    />
                  </div>
                  <img
                    class="zone__icon"
                    src="../assets/images/temp.svg"
                    alt=""
                  />
                </div>
                <div class="zone__param" data-param="carb" v-if="false">
                  <Switch
                    :paint="'dark'"
                    :name="'active_user'"
                    :status="true"
                    @change="handleZone($event, 'switch', 'carb')"
                  />
                  <div class="zone__tuner">
                    <div class="zone__set">
                      <span class="zone__set-value">4750</span>
                      <span class="zone__set-unit">PPM</span>
                    </div>
                    <Slider
                      type="horizontal"
                      :name="'set'"
                      @input="handleZone($event, 'slider', 'carb')"
                      @change="handleZone($event, 'slider', 'carb')"
                    />
                  </div>
                  <img
                    class="zone__icon"
                    src="../assets/images/carb.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="zone__control">
                <Button
                  :type="'text'"
                  :value="'Отмена'"
                  :paint="'gray'"
                  :disabled="zone.cancellation"
                  @click="cancelChanges($event, 'zone')"
                />
                <Button
                  :type="'text'"
                  :value="'Сохранить'"
                  :paint="'blue'"
                  :disabled="!zone.validation"
                  @click="changeZone"
                />
              </div>
            </div>
            <div
              class="zone__arrow"
              @click="collapseZone($event, zone.extended)"
            >
              <Chevron :orient="zone.extended ? 'up' : 'down'" />
            </div>
          </div>
        </div>
      </slot>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Button from "../components/Button";
  import Checkbox from "../components/Checkbox";
  import Chevron from "../components/Chevron";
  import Daypicker from "../components/Daypicker";
  import Dropdown from "../components/Dropdown";
  import Input from "../components/Input";
  import Slider from "../components/Slider";
  import Switch from "../components/Switch";
  import Timepicker from "../components/Timepicker";

  export default {
    name: "Objects",
    components: {
      Button,
      Checkbox,
      Chevron,
      Daypicker,
      Dropdown,
      Input,
      Slider,
      Switch,
      Timepicker,
    },
    data() {
      return {
        messages: [],
      };
    },
    computed: {
      ...mapGetters({
        notification: "getObjectsNotification",
        navbar: "getObjectsNavbar",
        about: "getObjectsAbout",
        hum: "getObjectsHum",
        zones: "getObjectsZones",
      }),
    },
    methods: {
      ...mapMutations([
        "toggleObjectsSection",
        "setObjectsActiveZone",
        "extendObjectsZone",
        "collapseObjectsZone",
        "handleObjectsChanges",
        "handleObjectsProperty",
        "handleObjectsZone",
        "cancelObjectsChanges",
        "resetObjectsState",
        "createModal",
        "createViewer",
        "updateObjectsZonesSocket",
      ]),
      ...mapActions([
        "fetchObjects",
        "changeObjectsActiveStatus",
        "sendObjectsChanges",
        "changeObjectsProgram",
        "changeObjectsZone",
      ]),
      toggleSection(section) {
        this.toggleObjectsSection(section);
      },
      toggleSubSection(event) {
        let section = event.target.closest("section");
        let className = section.classList[0];
        let sectionBody = section.querySelector(`.${className}__body`);
        let sectionButton = section.querySelector(`.${className}__button-icon`);

        if (getComputedStyle(sectionBody).display === "none") {
          sectionBody.style.display = "block";
          sectionButton.className = `${className}__button-icon ${className}__button-icon_rotate`;
        } else if (getComputedStyle(sectionBody).display === "block") {
          sectionBody.style.display = "none";
          sectionButton.className = `${className}__button-icon`;
        }
      },
      convertDate(timestamp) {
        if (timestamp !== null) {
          let newDate = new Date(timestamp * 1000);
          let year = newDate.getFullYear();
          let month = newDate.getMonth() + 1;
          if (month < 10) month = "0" + month;
          let day = newDate.getDate();
          if (day < 10) day = "0" + day;
          let date = `${day}.${month}.${year}`;
          return date;
        }
      },
      convertProgramTime(date, param) {
        if (param === "day") {
          let days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
          let day = days[date];

          return day;
        } else {
          if (date < 10) date = "0" + date;

          return date;
        }
      },
      declOfNum(number) {
        number = Math.abs(number) % 100;
        let n1 = number % 10;
        let arr = ["зона", "зоны", "зон"];

        if (number > 10 && number < 20) return `${number} ${arr[2]}`;
        if (n1 > 1 && n1 < 5) return `${number} ${arr[1]}`;
        if (n1 == 1) return `${number} ${arr[0]}`;
        return `${number} ${arr[2]}`;
      },
      changeSystemStatus(event) {
        let payload = {
          system: +event.target.closest(".objects").getAttribute("data-id"),
          active: event.target.checked,
        };

        this.changeObjectsActiveStatus(payload);
      },
      commonClick(event) {
        if (!event.target.closest(".zone")) {
          this.resetObjectsState();
        }
      },
      setActiveItem(event) {
        let payload = {
          id: +event.target
            .closest(".objects__navbar-item")
            .getAttribute("data-id"),
        };

        this.$router.push(`/objects/${payload.id}`);
        this.fetchObjects(payload.id);
      },
      handleChanges(event, element) {
        let payload = {};

        if (element === "input") {
          payload = {
            event: event.type,
            name: event.target.getAttribute("name"),
            value: event.target.value,
          };
        }

        payload.element = element;
        payload.section = event.target.closest("section").className;

        this.handleObjectsChanges(payload);
      },
      handleProperty(event, property, param) {
        let payload = {};

        if (param === "programs") {
          if (event.target.closest(".dropdown__item")) {
            payload = {
              event: "change",
              value: event.target
                .closest(".dropdown__item")
                .getAttribute("data-name"),
            };
          }
        }

        if (param === "value") {
          payload = {
            event: event.type,
            scope: event.target.closest(".slider").getAttribute("data-scope"),
            value: +event.target.value,
          };
        }

        if (param === "active_user") {
          payload = {
            event: event.type,
            value: event.target.checked,
          };
        }

        if (param === "period") {
          if (event.target.closest(".daypicker")) {
            if (event.target.closest(".daypicker__item")) {
              payload = {
                event: "change",
                type: "day",
                stage: event.target
                  .closest(".daypicker")
                  .getAttribute("data-stage"),
                value: +event.target
                  .closest(".daypicker__item")
                  .getAttribute("data-id"),
                num: +event.target
                  .closest(".daypicker__item")
                  .getAttribute("data-num"),
              };
            }
          }

          if (event.target.closest(".timepicker")) {
            if (event.target.closest(".timepicker__item")) {
              payload = {
                event: "change",
                type: "time",
                stage: event.target
                  .closest(".timepicker")
                  .getAttribute("data-stage"),
                unit: event.target
                  .closest(".timepicker__list")
                  .getAttribute("data-unit"),
                value: parseInt(
                  event.target.closest(".timepicker__item").innerHTML
                ),
              };
            }
          }
        }

        if (param === "range") {
          if (event.target.closest(".timepicker")) {
            if (event.target.closest(".timepicker__item")) {
              payload = {
                event: "change",
                id: event.target.closest(`.${property}__schedule-period`).id,
                type: "time",
                stage: event.target
                  .closest(".timepicker")
                  .getAttribute("data-stage"),
                unit: event.target
                  .closest(".timepicker__list")
                  .getAttribute("data-unit"),
                value: parseInt(
                  event.target.closest(".timepicker__item").innerHTML
                ),
              };
            }
          }
        }

        if (param === "select") {
          payload = {
            event: "change",
            value: event.target.checked,
            period: event.target.closest(".checkbox").getAttribute("data-period"),
          };
        }

        if (param === "zone") {
          payload = {
            event: event.type,
            id: +event.target.closest(`.${property}__zone`).id,
            value: +event.target.value,
            period:
              event.target.getAttribute("data-period") ||
              event.target
                .closest(".hum__zone-value")
                .querySelector(".input")
                .getAttribute("data-period"),
          };

          if (event.target.closest(".hum__zone-button")) {
            payload.event = "change";
            payload.step = event.target
              .closest(".hum__zone-button")
              .getAttribute("data-step");

            let value = parseInt(
              event.target.closest(".hum__zone-value").querySelector(".input")
                .value
            );

            if (payload.step === "increase") {
              value++;
              if (value > 58) value = 58;
            }

            if (payload.step === "decrease") {
              value--;
              if (value < 0) value = 0;
            }

            payload.value = value;
          }
        }

        payload.property = property;
        payload.param = param;

        this.handleObjectsProperty(payload);
      },
      cancelChanges(event, param) {
        let payload = {
          param: param,
        };

        if (param === "zone") {
          payload.id = +event.target.closest(".zone").getAttribute("data-id");
        }

        this.cancelObjectsChanges(payload);
      },
      sendChanges(payload) {
        this.sendObjectsChanges(payload);
      },
      downloadDocument(document) {
        window.location.href = `https://show-api.airwet.app${document.file.url}`;
      },
      changeProgram(event) {
        let payload = {
          id: +event.target.closest(".hum__program").getAttribute("data-id"),
        };

        this.changeObjectsProgram(payload);
      },
      setActiveZone(event) {
        let payload = {
          id: +event.target.closest(".zone").getAttribute("data-id"),
        };

        this.setObjectsActiveZone(payload);
        this.extendObjectsZone(payload);
      },
      handleZone(event, element, param) {
        let payload = {};

        if (element === "input") {
          payload.value = event.target.value;
          payload.name = event.target.getAttribute("name");
        }

        if (element === "switch") {
          payload.value = event.target.checked;
          payload.name = event.target.getAttribute("name");
        }

        if (element === "slider") {
          payload.value = +event.target.value;
          payload.name = event.target.getAttribute("name");
        }

        payload.event = event.type;
        payload.param = param;
        payload.id = +event.target.closest(".zone").getAttribute("data-id");
        payload.system = +event.target
          .closest(".objects")
          .getAttribute("data-id");

        this.extendObjectsZone(payload);
        this.handleObjectsZone(payload);
      },
      changeZone(event) {
        let payload = {
          zone: +event.target.closest(".zone").getAttribute("data-id"),
          system: +event.target.closest(".objects").getAttribute("data-id"),
        };

        this.changeObjectsZone(payload);
      },
      collapseZone(event, extended) {
        let payload = {
          id: +event.target.closest(".zone").getAttribute("data-id"),
        };

        if (extended) {
          event.stopPropagation();
          this.collapseObjectsZone(payload);
        }
      },
      getMaintenance() {
        let payload = {
          type: "maintenance",
        };

        this.createModal(payload);
      },
      showDocument() {
        this.createViewer();
        document.body.style.overflow = "hidden";
      },
    },
    created() {
      this.fetchObjects(this.$route.params.id);

      this.$soketio.on(`system/${this.$route.params.id}/state`, (data) => {
        if (this.$route.params.id == data.id) {
          this.updateObjectsZonesSocket(data);
        }
      });
    },
  };
</script>

<style lang="scss" scoped>
  .objects {
    position: relative;
    width: 100%;

    @include mediaquery(sm) {
      width: calc(100% - 75px);
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      position: relative;
      padding-bottom: 50px;

      @include mediaquery(sm) {
        padding-bottom: 100px;
      }
    }

    &__navbar {
      width: 100%;
      margin-bottom: 20px;

      &-list {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
          margin-left: -5px;
          margin-right: -5px;
          margin-top: -5px;
          margin-bottom: -10px;
          width: calc(100% + 10px);
        }
      }

      &-item {
        @include base;

        display: flex;
        width: 100%;
        border: 2px solid $colorMainLight;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;

          &:last-of-type {
            margin-bottom: 5px;
          }
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 10px);
        }

        &_current {
          border: 2px solid #41c6eb;

          .objects__navbar-avatar {
            background-color: #fff;
          }

          .objects__navbar-name {
            color: #222325;
          }
        }
      }

      &-avatar {
        height: 40px;
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        overflow: hidden;
      }

      &-name {
        font-weight: 500;
        font-size: 14px;
        color: #222325;
        text-transform: uppercase;
      }

      &-feature {
        margin-top: 5px;
        font-size: 10px;
        color: #9b9b9b;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__zones {
      width: 100%;
      display: flex;
      flex-direction: column;

      @include mediaquery(md) {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        margin-left: -5px;
        margin-right: -5px;
        margin-top: -10px;
        margin-bottom: 20px;
        width: calc(100% + 10px);
      }
    }

    .about {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      margin-bottom: 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
        cursor: pointer;
      }

      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 65%;

        @include mediaquery(xl) {
          flex-wrap: nowrap;
          justify-content: flex-start;
          width: auto;
          flex-direction: row;
          align-items: center;
        }
      }

      &__name {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(lg) {
          width: 200px;
        }

        @include mediaquery(xl) {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__address {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(xl) {
          width: 310px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &__service {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(50% - 5px);
        }

        @include mediaquery(xl) {
          width: 210px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &__mileage {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(50% - 5px);
        }

        @include mediaquery(xl) {
          width: 210px;
          margin-bottom: 0;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include mediaquery(md) {
          flex-direction: row;
          align-items: center;
        }
      }

      &__state {
        @include state;
        @include mediaquery(md) {
          margin: 0 10px;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 15px;
        width: 35%;
        margin-left: 10px;

        @include mediaquery(sm) {
          width: 30%;
        }
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__contract {
        font-size: 12px;
        font-weight: 500;
        margin: 5px 0 11px;
      }

      &__params {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
        }
      }

      &__param {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(25% - 10px);
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__vacation {
        @include base;

        width: 100%;
        padding: 10px 15px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-description {
          font-size: 10px;
          margin-top: 5px;
        }

        &-control {
          display: flex;
          align-items: center;
        }
      }

      &__features {
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        padding: 20px 0;
      }

      &__tabs {
        display: flex;
        justify-content: space-between;
      }

      &__feature {
        width: calc(100% / 3 - 10px);
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-description {
          font-size: 10px;
          margin-top: 5px;
        }

        &-control {
          display: flex;
          align-items: center;
        }
      }

      &__maintenance {
        padding-top: 20px;
      }

      &__documents {
        padding-top: 20px;
        display: flex;

        &-column {
          width: 50%;

          &:first-of-type {
            padding-right: 10px;
          }
        }
      }

      &__document {
        margin-bottom: 10px;
        &-wrapper {
          display: flex;
        }

        input {
          margin-right: 10px;
        }

        &-control {
          display: flex;
        }

        button {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 0 0;

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .maintenance {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;
      margin-bottom: 10px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__description {
        font-size: 10px;
        margin-top: 5px;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__body {
        display: none;
      }

      &__blocks {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        @include mediaquery(md) {
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: -10px;
          margin-right: -10px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      &__block {
        width: 100%;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;
        }
      }

      &__title {
        font-size: 9px;
        margin-bottom: 6px;
        color: #9b9b9b;
      }

      &__wrapper {
        display: flex;
        flex-direction: column;

        @include mediaquery(sm) {
          flex-direction: row;
        }

        input {
          margin-bottom: 10px;

          @include mediaquery(sm) {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }
    }

    .documents {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__description {
        font-size: 10px;
        margin-top: 5px;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__body {
        display: none;
      }

      &__preview {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        margin-top: -5px;
      }

      &__document {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-icon {
          width: 46px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-info {
          display: flex;
          align-items: center;
          margin-left: 17px;
        }

        &-name {
          font-size: 12px;
          font-weight: 500;
          color: #2b1a0a;
        }

        &-size {
          font-size: 10px;
          color: #2b1a0a;
          margin-left: 20px;
        }

        &-control {
          display: flex;

          button {
            margin-left: 10px;
          }
        }

        &-link {
          height: 36px;
          min-width: 36px;
          padding: 6px;
          border-radius: 6px;
          box-shadow: $shadowLight;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;

          @media (hover: hover) {
            &:hover {
              background-color: #fff;
            }
          }
        }

        &-icon {
          width: 20px;
          opacity: 0.5;
        }
      }

      &__title {
        @include title;
      }

      &__wrapper {
        display: flex;
        flex-direction: column;

        @include mediaquery(sm) {
          flex-direction: row;
        }

        input {
          margin-bottom: 10px;

          @include mediaquery(sm) {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }
    }

    .hum,
    .temp {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__body {
        display: block;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 85%;

        @include mediaquery(md) {
          width: 50%;
        }

        @include mediaquery(lg) {
          width: 85%;
          flex-direction: row;
        }
      }

      &__list {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(lg) {
          width: 200px;
          margin-bottom: 0;
        }
      }

      &__period {
        width: 100%;
        display: flex;
        flex-direction: column;
        cursor: auto;

        @include mediaquery(lg) {
          width: auto;
          flex-direction: row;
          margin-left: 10px;
        }
      }

      &__start,
      &__stop {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #222325;
        background-color: $colorMainLight;
        border: 1px solid #dcdcdc;
        border-radius: 6px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(lg) {
          margin-right: 10px;
          margin-bottom: 0;

          &:last-of-type {
            margin-right: 0;
          }
        }

        &-strong {
          font-weight: 500;
          margin-right: 3px;
        }

        &-date {
          margin-left: 10px;
          text-transform: uppercase;
          color: #fff;
          padding: 5px 15px;
          border-radius: 15px;
          font-size: 10px;
          text-align: center;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
        width: 15%;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__program {
        padding: 15px 0 10px;
        display: flex;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
        }
      }

      &__column {
        width: 100%;

        @include mediaquery(sm) {
          &:first-of-type {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
          }
        }

        @include mediaquery(md) {
          &:first-of-type {
            flex-direction: column;
            min-width: 200px;
            max-width: 200px;
            margin-right: 10px;
            margin-bottom: 0;
          }

          &:last-of-type {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 210px);
          }
        }
      }

      &__schedule {
        @include base;

        padding: 10px;
        width: 100%;

        @include mediaquery(sm) {
          width: 50%;
        }

        @include mediaquery(md) {
          width: 100%;
        }

        &-headline {
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
          margin-bottom: 13px;
        }

        &-period {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &[data-period="morning"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #8bd341;
            }
            .timepicker {
              background-color: #8bd341;
            }
          }

          &[data-period="day"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #ff6eda;
            }
            .timepicker {
              background-color: #ff6eda;
            }
          }

          &[data-period="evening"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #7976ff;
            }
            .timepicker {
              background-color: #7976ff;
            }
          }

          &[data-period="night"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #3a95ff;
            }
            .timepicker {
              background-color: #3a95ff;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-title {
          font-size: 12px;
          font-weight: 500;
        }

        &-range {
          display: flex;

          .timepicker {
            color: #fff;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }

      &__settings {
        @include base;

        padding: 10px;
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        @include mediaquery(sm) {
          width: 50%;
          margin: 0;
          margin-left: 10px;
        }

        @include mediaquery(md) {
          width: 100%;
          margin: 0;
          margin-top: 10px;
        }

        &-setting {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-wrapper {
          display: flex;
        }

        &-stage {
          font-weight: 500;
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
        }
      }

      &__zones {
        @include base;

        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        padding-bottom: 0;
        min-height: 191.5px;
        width: 100%;
      }

      &__select {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 5px;

        .checkbox {
          height: 28.5px;
          margin-bottom: 10px;
        }
      }

      .ps {
        display: flex;
        width: calc(100% - 55px);
        padding: 5px;
        padding-bottom: 10px;
      }

      &__zone {
        margin-left: 10px;
        width: 100px;
        min-width: 100px;

        &:first-of-type {
          margin-left: 0;
        }

        &-title {
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
          margin-bottom: 13px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-value {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          user-select: none;
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-button {
          position: absolute;
          border: 1px solid #bcbcbc;
          color: #bcbcbc;
          border-radius: 2px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          transition: color 0.3s, border-color 0.3s;

          &:hover {
            border-color: #222325;
            color: #222325;
          }

          &:first-of-type {
            left: 7px;
          }

          &:last-of-type {
            right: 7px;
          }
        }

        &-minus {
          height: 2px;
          width: 10px;
          background-color: #bcbcbc;
        }

        &-plus {
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: 4px;
            display: block;
            width: 10px;
            height: 2px;
            background: #bcbcbc;
          }

          &::before {
            transform: rotate(0deg);
          }

          &::after {
            transform: rotate(90deg);
          }
        }

        input {
          text-align: center;
          height: 28.5px;
          padding: 6px 14px;
          margin-bottom: 10px;
          font-weight: 500;

          &:last-of-type {
            margin-bottom: 0;
          }

          &[data-period="morning"] {
            color: #8bd341;
            -webkit-text-fill-color: #8bd341;
          }

          &[data-period="day"] {
            color: #ff6eda;
            -webkit-text-fill-color: #ff6eda;
          }

          &[data-period="evening"] {
            color: #7976ff;
            -webkit-text-fill-color: #7976ff;
          }

          &[data-period="night"] {
            color: #3a95ff;
            -webkit-text-fill-color: #3a95ff;
          }
        }
      }

      &__tuner {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 15px 0;

        @include mediaquery(sm) {
          margin: 10px 0;
        }

        &-period,
        &-common {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include mediaquery(sm) {
            justify-content: flex-start;
          }
        }

        &-period {
          margin-bottom: 20px;
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-title {
          font-weight: 500;
          font-size: 10px;
          color: #222325;
          text-transform: uppercase;

          @include mediaquery(sm) {
            min-width: 120px;
            margin-right: 10px;
          }
        }

        &-value {
          font-size: 12px;
          font-weight: 500;
          margin-left: 5px;
          color: #bcbcbc;
          min-width: 35px;
          text-align: right;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        @include mediaquery(sm) {
          flex-direction: row;
        }

        &-control {
          display: flex;
          flex-direction: column;
          width: 100%;

          @include mediaquery(sm) {
            flex-direction: row;
            justify-content: flex-end;
          }

          button {
            width: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }

            @include mediaquery(sm) {
              width: auto;
              margin-bottom: 0;

              &:first-of-type {
                margin-right: 10px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .hum {
      .hum__start-date,
      .hum__stop-date {
        background-color: $colorHum;
        min-width: 45px;
      }
    }

    .temp {
      .temp__start-date,
      .temp__stop-date {
        background-color: $colorTemp;
      }
    }

    .zone {
      @include base;

      width: 100%;
      border: 2px solid $colorMainLight;
      padding: 10px 20px 15px;
      margin-bottom: 10px;
      overflow-x: hidden;
      cursor: pointer;

      @include mediaquery(md) {
        width: calc(50% - 10px);
        margin: 5px;
      }

      @include mediaquery(xl) {
        width: calc(33.333333% - 10px);
      }

      @include mediaquery(hg) {
        width: calc(25% - 10px);
      }

      &_current {
        border: 2px solid #41c6eb;
      }

      &__body {
        display: flex;
        flex-direction: column;
      }

      &__name {
        color: #222325;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;

        &-input {
          border: 1px solid transparent;
          border-radius: 6px;
          background: none;
          font-family: inherit;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px;

          &:hover {
            border: 1px solid #dcdcdc;
          }

          &:focus {
            border: 1px solid #41c6eb;
          }

          &_valid {
            border-color: #41c6eb;
          }

          &_invalid {
            border-color: #ff8068;
          }
        }
      }

      &__program {
        display: flex;
        justify-content: center;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 12px;

        &-range {
          margin-left: 5px;
        }

        &[data-period="morning"] {
          .zone__program-title,
          .zone__program-range {
            color: #8bd341;
          }
        }

        &[data-period="day"] {
          .zone__program-title,
          .zone__program-range {
            color: #ff6eda;
          }
        }

        &[data-period="evening"] {
          .zone__program-title,
          .zone__program-range {
            color: #7976ff;
          }
        }

        &[data-period="night"] {
          .zone__program-title,
          .zone__program-range {
            color: #3a95ff;
          }
        }
      }

      &__info {
        display: flex;
      }

      &__caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 15px;
        color: #363636;
        font-size: 11px;
        font-weight: 500;

        &-line {
          background: #363636;
          border: 0;
          width: 100%;
          height: 1px;
          margin: 7px 0;
        }
      }

      &__indicators {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
      }

      &__indicator {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        box-shadow: $shadowLight;
        color: #fff;
        padding: 5px 10px;
        flex-grow: 1;
        width: calc(33.333333% - 10px);
        min-width: calc(33.333333% - 10px);
        min-height: 75px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &[data-param="temp"] {
          background-color: $colorTemp;
        }

        &[data-param="hum"] {
          background-color: $colorHum;
        }

        &[data-param="carb"] {
          background-color: $colorCarb;

          .zone__set,
          .zone__cur {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
          }

          .zone__set-unit,
          .zone__cur-unit {
            font-size: 7px;
            margin-top: -3px;
          }
        }
      }

      &__set {
        font-size: 19px;
        font-weight: 500;
        margin-top: 6px;
        white-space: nowrap;

        svg {
          visibility: hidden;
        }

        &-dash {
          font-weight: 400;

          &:not(:first-of-type) {
            margin-left: 3px;
          }

          ~ .zone__set-unit {
            visibility: hidden;
          }
        }
      }

      &__cur {
        display: flex;
        align-items: center;
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 6px;

        &-wrapper {
          white-space: nowrap;
        }

        &-value {
          margin-left: 2px;
        }
      }

      &__led {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 5px;
        right: 5px;

        &_on {
          background: linear-gradient(212.01deg, #dfffbe 13.85%, #9eff3d 82.31%);
        }

        &_off {
          background: linear-gradient(208.61deg, #fc4f4f 0.59%, #ce0909 92.94%);
          background: none;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      &__params {
        display: flex;
        flex-direction: column;
      }

      &__param {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        &[data-param="hum"] {
          .zone__set {
            color: $colorHum;
          }
        }

        &[data-param="temp"] {
          .zone__set {
            color: $colorTemp;
          }
        }

        &[data-param="carb"] {
          .zone__set {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
            color: $colorCarb;
          }

          .zone__set-unit {
            font-size: 7px;
            margin-top: -3px;
          }
        }
      }

      &__tuner {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        .slider {
          width: 100%;
          margin: 4px 0;
        }
      }

      &__icon {
        width: 28px;
        margin-left: 10px;
        margin-bottom: -3px;
      }

      &__control {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: space-between;
        }

        .button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: calc(50% - 5px);

            &:first-of-type {
              margin-bottom: 0px;
            }
          }
        }
      }

      &__arrow {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }
    }

    .notification {
      @include notification;

      &__title {
        font-size: 14px;
        font-weight: 700;
        color: #2b1a0a;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      &__text {
        font-size: 12px;
        color: #2b1a0a;
      }

      &__strong {
        font-weight: 700;
      }
    }
  }
</style>
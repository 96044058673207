<template>
  <header class="header">
    <div class="header__container">
      <div class="header__wrapper">
        <div class="header__toggle" @click="toggleNavbar">
          <span class="header__toggle-line"></span>
          <span class="header__toggle-line"></span>
          <span class="header__toggle-line"></span>
        </div>
        <div class="header__navbar">
          <div v-if="user.role === 'user'" class="header__navbar-list">
            <router-link
              v-for="(page, index) in content.user.pages"
              v-bind:class="[
                page.title === content.page
                  ? 'header__navbar-link header__navbar-link_active'
                  : 'header__navbar-link',
              ]"
              exact-active-class=""
              active-class=""
              v-bind:key="index"
              v-bind:data-name="page.name"
              v-bind:to="page.path"
              @click="logout(page.name)"
            >
              <img
                class="header__navbar-icon"
                :src="require(`../assets/images/${page.icon}.svg`)"
                alt=""
              />
              <div class="header__navbar-title">{{ page.title }}</div>
            </router-link>
          </div>
          <div v-if="user.role === 'engineer'" class="header__navbar-list">
            <router-link
              v-for="(page, index) in content.engineer.pages"
              v-bind:class="[
                page.title === content.page
                  ? 'header__navbar-link header__navbar-link_active'
                  : 'header__navbar-link',
              ]"
              exact-active-class=""
              active-class=""
              v-bind:key="index"
              v-bind:data-name="page.name"
              v-bind:to="page.path"
              @click="logout(page.name)"
            >
              <img
                class="header__navbar-icon"
                :src="require(`../assets/images/${page.icon}.svg`)"
                alt=""
              />
              <div class="header__navbar-title">{{ page.title }}</div>
            </router-link>
          </div>
        </div>
        <div class="header__page">
          {{ content.page === "/" ? "" : content.page }}
        </div>
      </div>
      <div class="header__user">
        <div
          class="header__callback"
          v-if="user.role === 'user'"
          @click="getHeaderCallback"
        >
          <font-awesome-icon icon="phone" />
        </div>
        <div class="header__user-wrapper">
          <div class="header__user-name">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div class="header__user-info">
            <div class="header__user-time">{{ this.headerTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  export default {
    name: "Header",
    data() {
      return {
        headerTime: null,
      };
    },
    computed: {
      ...mapGetters({
        user: "getAuthorizationUser",
        content: "getCommonContent",
      }),
    },
    methods: {
      ...mapActions(["logoutAuthorization"]),
      ...mapMutations(["createModal", "createPopup"]),
      getHeaderTime() {
        let date = new Date();
        let days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
        let months = [
          "Января",
          "Февраля",
          "Марта",
          "Апреля",
          "Мая",
          "Июня",
          "Июля",
          "Августа",
          "Сентября",
          "Октября",
          "Ноября",
          "Декабря",
        ];
        let hours =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let minutes =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

        this.headerTime = `${days[date.getDay()]} ${date.getDate()} ${
          months[date.getMonth()]
        } ${date.getFullYear()} ${hours}:${minutes}`;
      },
      toggleNavbar() {
        document
          .querySelector(".header__navbar")
          .classList.toggle("header__navbar_visible");
      },
      getHeaderCallback() {
        let payload = {
          type: "callback",
          params: {
            name: "phone",
            value:
              localStorage.getItem("authorization__user-phone") !== "null"
                ? localStorage.getItem("authorization__user-phone")
                : null,
          },
        };

        this.createModal(payload);
      },
      logout(name) {
        if (name === "Login") {
          this.logoutAuthorization().then(() => {
            this.$router.push("/login");
          });
        }

        document.querySelector(".header__navbar").className = "header__navbar";
      },
    },
    mounted() {
      setInterval(this.getHeaderTime, 1000);
    },
  };
</script>

<style lang="scss">
  .header {
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__logo {
      height: 65px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__toggle {
      @include base;

      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 10px;
      padding: 0 10px;
      cursor: pointer;

      @include mediaquery(sm) {
        display: none;
      }

      &-line {
        height: 2px;
        background-color: #222325;
        width: 100%;
        margin: 3px 0;
      }
    }

    &__navbar {
      @include base;
      background-color: #f0f0f0;
      position: absolute;
      z-index: $zIndexHeader;
      top: 50px;
      left: 0;
      width: 100%;
      display: none;

      &_visible {
        display: block;

        @include mediaquery(sm) {
          display: none;
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
      }

      &-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        text-transform: uppercase;
        margin: 5px 0;
        padding-left: 5px;
        color: inherit;

        &_active {
          .header__navbar-icon {
            opacity: 1;
          }
          .header__navbar-title {
            opacity: 1;
          }
        }
      }

      &-title {
        font-size: 18px;
        margin-left: 10px;
        opacity: 0.3;
      }

      &-icon {
        width: 20px;
        height: 20px;
        opacity: 0.3;
      }
    }

    &__page {
      text-transform: uppercase;
      font-size: 18px;

      @include mediaquery(sm) {
        font-size: 24px;
      }
    }

    &__callback {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid #41c6eb;
      border-radius: 6px;
      padding: 5px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      color: #41c6eb;
      font-size: 20px;

      @include mediaquery(sm) {
        width: 43px;
        height: 43px;
      }

      &-text {
        text-transform: uppercase;
        font-size: 9px;
      }
    }

    &__user {
      display: flex;
      align-items: flex-end;

      &-wrapper {
        display: none;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-left: 15px;

        @include mediaquery(sm) {
          display: flex;
        }
      }

      &-name {
        font-weight: 500;
        font-size: 14px;
      }

      &-info {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
      }

      &-time {
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
        min-height: 18px;
        min-width: 142px;
        text-align: center;
      }
    }
  }
</style>

<template>
  <div
    class="showPopup"
    v-if="showPopup.visible"
    :data-type="showPopup.current.type"
  >
    <div class="showPopup__window">
      <div class="showPopup__header">{{ showPopup.current.header }}</div>
      <div class="showPopup__body">{{ showPopup.current.body }}</div>
      <div class="showPopup__footer">
        <button @click="handleClick">Понятно</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";
  export default {
    name: "ShowPopup",
    components: {},
    computed: {
      ...mapGetters({
        showPopup: "getCommonShowPopup",
      }),
    },
    methods: {
      ...mapMutations(["destroyShowPopup"]),
      handleClick() {
        this.destroyShowPopup();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .showPopup {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    background-color: rgba(240, 240, 240, 0.8);
    z-index: $zIndexShowPopup;
    opacity: 1;

    &__window {
      @include base;

      position: relative;
      top: 100px;
      width: calc(100% - 60px);
      margin: 0 auto;
      padding: 20px;
      transform: scale(1);
      transition: transform 0.5s ease-in-out;
      background: linear-gradient(
        341.55deg,
        #3d93f8 0.22%,
        #6e5ff8 50.8%,
        #ba4ff5 101.38%
      );
      box-shadow: 2px 2px 4px #6312c9;

      @include mediaquery(sm) {
        width: 400px;
        padding: 50px;
      }
    }

    &__header {
      font-family: "BellotaText";
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }

    &__body {
      font-family: "BellotaText";
      font-size: 14px;
      margin: 20px 0;
      color: #fff;
      text-align: center;
    }

    &__footer {
      display: flex;
      justify-content: center;
    }

    button {
      background: linear-gradient(90deg, #af75ff 0%, #6a84ff 100%);
      box-shadow: 2px 2px 4px #6312c9;
      border-radius: 7px;
      border: 0;
      font-family: "BellotaText";
      font-weight: 500;
      font-size: 13px;
      color: #ffffff;
      padding: 9px 20px;
      cursor: pointer;
    }
  }
</style>

import axios from 'axios';

export default {
  state: {
    notification: {},
    navbar: {},
    about: {
      extended: localStorage.getItem('objects__about-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    hum: {
      list: {
        categories: [],
      },
      programs: [],
      program: {},
      previous: null,
      extended: localStorage.getItem('objects__hum-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    zones: [],
    backup: {},
  },
  getters: {
    getObjectsNotification: state => state.notification,
    getObjectsNavbar: state => state.navbar,
    getObjectsAbout: state => state.about,
    getObjectsHum: state => state.hum,
    getObjectsZones: state => state.zones,
  },
  mutations: {
    createObjectsNotification(state, payload) {
      state.notification = payload;
    },
    toggleObjectsSection(state, payload) {
      state[payload].extended = !state[payload].extended;

      localStorage.setItem(`objects__${payload}-extended`, state[payload].extended);
    },
    setObjectsActiveZone(state, payload) {
      state.zones.forEach((zone, index) => {
        if (payload.id !== zone.id) {
          zone.current = false;
          zone.cancellation = true;
          zone.validation = false;
          zone.extended = false;

          if (state.backup.zones && zone.id === state.backup.zones[index].id) {
            zone.hum = state.backup.zones[index].hum;
            zone.temp = state.backup.zones[index].temp;
            zone.name = state.backup.zones[index].name;
            zone.status = 'default';
            state.backup.zones[index] = {};
          }
        }
      });
      state.zones.forEach(zone => zone.id === payload.id ? zone.current = true : false);
    },
    extendObjectsZone(state, payload) {
      state.zones.forEach(zone => {
        if (zone.id === payload.id) zone.extended = true;
      });
    },
    collapseObjectsZone(state, payload) {
      state.zones.forEach(zone => {
        if (zone.id === payload.id) zone.extended = false;
      });
    },

    updateObjectsNavbar(state, payload) {
      state.navbar = payload;
    },
    updateObjectsAbout(state, payload) {
      state.about.id = payload.id;
      state.about.contract = payload.contract;
      state.about.from_user = payload.from_user;
      state.about.active_user = payload.active_user;
      state.about.active_srv = payload.active_srv;
      state.about.errors = payload.errors;
      state.about.date = payload.date;
      state.about.mileage = payload.mileage;
      state.about.pump_hours = payload.pump_hours;
      state.about.type = payload.type;
      state.about.system_type = payload.system_type;
      state.about.system_architecture = payload.system_architecture;
      state.about.name = {
        element: 'input',
        name: 'name',
        value: payload.name,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.address = payload.address;
      state.about.date_start = payload.date_start;
      state.about.date_install = payload.date_install;
      state.about.date_warranty = payload.date_warranty;
      state.about.date_maintenance_plan = payload.date_maintenance_plan;
      state.about.documents = payload.documents;

      if (payload.state === 4) {
        state.about.state = {
          value: 'inactive',
          text: 'не работает'
        }
      } else {
        state.about.state = {
          value: 'active',
          text: 'работает'
        }
      }

      /* if (payload.state === 0) {
        state.about.state = {
          value: 'disabled',
          text: 'выключена'
        }
      }

      if (payload.state === 1) {
        state.about.state = {
          value: 'enabled',
          text: 'включена'
        }
      }

      if (payload.state === 2) {
        state.about.state = {
          value: 'error',
          text: 'ошибка'
        }
      }

      if (payload.state === 3) {
        state.about.state = {
          value: 'active',
          text: 'работает'
        }
      }

      if (payload.state === 4) {
        state.about.state = {
          value: 'inactive',
          text: 'не работает'
        }
      } */
    },
    updateObjectsHum(state, payload) {
      state.hum.programs = payload.programs;

      if (state.hum.programs.length > 0) {
        state.hum.programs.forEach((program, index) => {
          if (program.type === 'hum') {
            if (program.active_user) {
              let day = new Date().getDay();

              if (
                day >= program.start_day && day <= program.stop_day ||
                day === program.start_day || day === program.stop_day
              ) {
                state.hum.current = program;
              }
            }
            program.max = payload.board.params.features.hum_max;
            program.min = payload.board.params.features.hum_min;
            program.period = {
              name: null,
              value: 50,
            };

            program.start_day_num = program.start_day === 0 ? 7 : program.start_day;
            program.stop_day_num = program.stop_day === 0 ? 7 : program.stop_day;

            state.hum.programs[index] = JSON.parse(JSON.stringify(program));

            if (state.hum.previous && state.hum.previous.id === program.id) {
              state.hum.program = state.hum.programs[index];
              state.hum.previous = state.hum.programs[index];
            } else state.hum.program = state.hum.programs[0];

            state.hum.list.type = 'difficult';
            state.hum.list.paint = 'blue';

            if (state.hum.previous && state.hum.previous.id === program.id) {
              state.hum.list.value = state.hum.programs[index].name;
            } else state.hum.list.value = state.hum.programs[0].name;

            let obj = {
              id: state.hum.programs[index].id,
              name: state.hum.programs[index].id,
              title: state.hum.programs[index].name,
            }

            state.hum.list.categories[index] = obj;
            state.hum.zones = JSON.parse(JSON.stringify(state.zones));

            for (let i = 0; i < state.hum.zones.length; i++) {
              state.hum.zones[i].hum.programs.forEach(prog => {
                if (prog.id === state.hum.program.id) {
                  let morning = {
                    value: prog.morning,
                    unit: '%',
                    status: 'default',
                    control: false,
                  }
                  let day = {
                    value: prog.day,
                    unit: '%',
                    status: 'default',
                    control: false,
                  }
                  let evening = {
                    value: prog.evening,
                    unit: '%',
                    status: 'default',
                    control: false,
                  }
                  let night = {
                    value: prog.night,
                    unit: '%',
                    status: 'default',
                    control: false,
                  }

                  state.hum.zones[i].hum.morning = morning;
                  state.hum.zones[i].hum.day = day;
                  state.hum.zones[i].hum.evening = evening;
                  state.hum.zones[i].hum.night = night;
                }
              })
            }

            state.hum.cancellation = true;
            state.hum.validation = false;
            state.backup.hum = {};
          }
        })
      }
    },
    updateObjectsZones(state, payload) {
      state.zones = JSON.parse(JSON.stringify(payload.zones));

      state.zones.forEach(zone => {
        zone.status = 'default';
        zone.changed = false;

        zone.temp.max = 30;
        zone.temp.min = 5;
        zone.hum.max = payload.board.params.features.hum_max;
        zone.hum.min = payload.board.params.features.hum_min;

        zone.current = false;
        zone.cancellation = true;
        zone.validation = false;
        zone.extended = false;

        state.hum.programs.forEach(program => {
          if (program.active_user === true) {
            let day = new Date().getDay();
            let hour = new Date().getHours();

            if (
              day >= program.start_day && day <= program.stop_day ||
              day === program.start_day || day === program.stop_day
            ) {
              program.periods.forEach(period => {
                if (
                  (period.start.hour <= hour && hour <= period.stop.hour) ||
                  (period.start.hour >= hour && hour < period.stop.hour && period.name === 'night') ||
                  (period.start.hour <= hour && hour >= period.stop.hour && period.name === 'night')

                ) {
                  let start = `${period.start.hour < 10 ? "0" + period.start.hour : period.start.hour}:${period.start.minute < 10 ? "0" + period.start.minute : period.start.minute}`;
                  let stop = `${period.stop.hour < 10 ? "0" + period.stop.hour : period.stop.hour}:${period.stop.minute < 10 ? "0" + period.stop.minute : period.stop.minute}`;

                  zone.program = {
                    id: program.id,
                    period: period.name,
                    title: period.title,
                    range: `${start} - ${stop}`,
                    name: program.name
                  }
                }
              });

              state.hum.zones.forEach(elem => {
                if (elem.id === zone.id) {

                  elem.hum.programs.forEach(prog => {
                    if (prog.id === zone.program.id) {
                      zone.hum.set = prog[zone.program.period]
                    }
                  });
                }
              });
            }
          }
        });
      });
    },

    handleObjectsChanges(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        if (payload.section === 'about') {
          if (isEmpty(state.backup.about)) {
            state.backup.about = JSON.parse(JSON.stringify(state.about));
          }

          state.about.cancellation = false;
          state.about.validation = true;

          if (state.about[payload.name].name === payload.name) {
            state.about[payload.name].value = payload.value;

            if (payload.name === 'name') state.about.extended = true;

            if (payload.value.match(state.about[payload.name].pattern)) {
              state.about[payload.name].status = 'valid';
              state.about[payload.name].error = false;
            } else {
              state.about[payload.name].status = 'invalid';
              state.about[payload.name].error = true;
            }
          }

          if (state.about[payload.name].status === 'invalid') {
            state.about.validation = false;
          }
        }
      }
    },
    handleObjectsProperty(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      if (isEmpty(state.backup[payload.property])) {
        state.backup[payload.property] = JSON.parse(JSON.stringify(state[payload.property]));
      }

      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        if (payload.param === 'programs') {
          state[payload.property].programs.forEach(element => {
            if (element.id == +payload.value) {
              state[payload.property].list.value = element.name;
              state[payload.property].program = element;

              for (let i = 0; i < state.hum.zones.length; i++) {
                state.hum.zones[i].hum.programs.forEach(prog => {
                  if (prog.id === +payload.value) {
                    state.hum.zones[i].hum.morning.value = prog.morning;
                    state.hum.zones[i].hum.day.value = prog.day;
                    state.hum.zones[i].hum.evening.value = prog.evening;
                    state.hum.zones[i].hum.night.value = prog.night;
                  }
                })
              }
            }
          });
        } else {
          if (payload.param === 'value') {
            if (payload.scope === 'period') {
              state[payload.property].program.period.value = payload.value;

              state[payload.property].zones.forEach(element => {
                element[payload.property][state[payload.property].program.period.name].value = payload.value;
                element[payload.property][state[payload.property].program.period.name].status = 'valid';

                element[payload.property].programs.forEach(prog => {
                  if (prog.id === state.hum.program.id) {
                    prog[state[payload.property].program.period.name] = payload.value;
                  }
                })
              });
            } else {
              state[payload.property].program.value = payload.value;
              state[payload.property].program.period.name = null;

              state[payload.property].zones.forEach(element => {
                element[payload.property].morning.value = payload.value;
                element[payload.property].morning.status = 'valid';
                element[payload.property].day.value = payload.value;
                element[payload.property].day.status = 'valid';
                element[payload.property].evening.value = payload.value;
                element[payload.property].evening.status = 'valid';
                element[payload.property].night.value = payload.value;
                element[payload.property].night.status = 'valid';

                element[payload.property].programs.forEach(prog => {
                  if (prog.id === state.hum.program.id) {
                    prog.morning = payload.value;
                    prog.day = payload.value;
                    prog.evening = payload.value;
                    prog.night = payload.value;
                  }
                })
              });
            }
          }

          if (payload.param === 'active_user') {
            state[payload.property].extended = true;
            state[payload.property].program.active_user = payload.value;
          }

          if (payload.param === 'period') {
            if (payload.type === 'day') {
              let popup = {
                type: "error",
                params: {
                  text: null
                }
              }

              if (state[payload.property].program.name === 'Будни') {
                if (payload.stage === 'start') {
                  if (payload.num >= state[payload.property].program.stop_day_num) {
                    popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                    this.commit('createPopup', popup);
                  }
                }
                if (payload.stage === 'stop') {
                  if (payload.num <= state[payload.property].program.start_day_num) {
                    popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                    this.commit('createPopup', popup);
                  } else if (payload.num >= state[payload.property].programs[1].start_day_num) {
                    popup.params.text = 'День окончания программы "Будни" должен быть меньше дня начала программы "Выходные".';
                    this.commit('createPopup', popup);
                  }
                }
              }

              if (state[payload.property].program.name === 'Выходные') {
                if (payload.stage === 'start') {
                  if (payload.num >= state[payload.property].program.stop_day_num) {
                    popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                    this.commit('createPopup', popup);
                  } else if (payload.num <= state[payload.property].programs[0].stop_day_num) {
                    popup.params.text = 'День начала программы "Выходные" должен быть больше дня окончания программы "Будни".';
                    this.commit('createPopup', popup);
                  }
                }
                if (payload.stage === 'stop') {
                  if (payload.num <= state[payload.property].program.start_day_num) {
                    popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                    this.commit('createPopup', popup);
                  }
                }
              }

              if (!popup.params.text) {
                state[payload.property].program[`${payload.stage}_${payload.type}`] = payload.value;
                state[payload.property].program[`${payload.stage}_${payload.type}_num`] = payload.num;
              }
            }

            if (payload.type === 'time') {
              state[payload.property].program[payload.stage][payload.unit] = payload.value;
            }
          }

          if (payload.param === 'range') {
            state[payload.property].program.periods.forEach((element, index) => {
              if (element.id == payload.id) {
                element[payload.stage][payload.unit] = payload.value;

                if (payload.stage === 'stop') {
                  if (index + 1 === state[payload.property].program.periods.length) {
                    state[payload.property].program.periods[0].start[payload.unit] = payload.value;
                  } else {
                    state[payload.property].program.periods[index + 1].start[payload.unit] = payload.value;
                  }
                }

                if (payload.stage === 'start') {
                  if (index === 0) {
                    state[payload.property].program.periods[state[payload.property].program.periods.length - 1].stop[payload.unit] = payload.value;
                  } else {
                    state[payload.property].program.periods[index - 1].stop[payload.unit] = payload.value;
                  }
                }
              }
            });
          }

          if (payload.param === 'select') {
            state[payload.property].program.period.name = payload.period;
            if (!payload.value) state[payload.property].program.period.name = null;
          }

          if (payload.param === "zone") {
            state[payload.property].zones.forEach(element => {
              if (element.id == payload.id) {
                if (payload.value > state.hum.program.max) payload.value = state.hum.program.max;
                if (payload.value < state.hum.program.min) payload.value = state.hum.program.min;

                element[payload.property][payload.period].value = payload.value;
                state[payload.property].program.period.name = null;

                if (String(payload.value).match(/^[0-9]{1,2}$/)) {
                  element[payload.property][payload.period].status = 'valid';
                } else {
                  element[payload.property][payload.period].status = 'invalid';
                }

                element[payload.property].programs.forEach(prog => {
                  if (prog.id === state.hum.program.id)
                    prog[payload.period] = payload.value;
                })
              }
            });
          }

          state[payload.property].cancellation = false;
          state[payload.property].validation = true;

          state.hum.zones.forEach(zone => {
            if (
              zone[payload.property].morning.status === 'invalid' ||
              zone[payload.property].day.status === 'invalid' ||
              zone[payload.property].evening.status === 'invalid' ||
              zone[payload.property].night.status === 'invalid'
            ) {
              state[payload.property].validation = false;
            }
          });
        }
      }

      if (payload.event === 'focus') {
        if (payload.param === "zone") {
          state[payload.property].zones.forEach(element => {
            if (element.id == payload.id) {
              element[payload.property][payload.period].unit = '';
              element[payload.property][payload.period].control = true;
              state[payload.property].program.period.name = null;
            }
          });
        }
      }

      if (payload.event === 'blur') {
        if (payload.param === "zone") {
          state[payload.property].zones.forEach(element => {
            if (element.id == payload.id) {
              if (payload.property === 'hum' && element[payload.property][payload.period].status !== 'invalid') {
                element[payload.property][payload.period].unit = '%';
              }
              if (payload.property === 'temp' && element[payload.property][payload.period].status !== 'invalid') {
                element[payload.property][payload.period].unit = '°';
              }
            }
          });
        }
      }

    },
    handleObjectsZone(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      state.zones.forEach((element, index) => {
        if (element.id === payload.id) {
          element.current = true;

          if (isEmpty(state.backup.zones)) {
            state.backup.zones = JSON.parse(JSON.stringify(state.zones));
          } else if (isEmpty(state.backup.zones[index])) {
            state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
          }
        }
      });

      if (payload.event === 'change' || payload.event === 'input') {
        state.zones.forEach((element, index) => {
          if (element.id == payload.id) {
            console.log(payload);
            if (isEmpty(state.backup.zones[index])) {
              state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
            }

            if (payload.param !== undefined) {
              element[payload.param][payload.name] = payload.value;
            } else {
              element[payload.name] = payload.value;
            }

            if (payload.name === 'set' && state.hum.current) {
              state.hum.current.changed = true;
            }

            element.cancellation = false;
            element.validation = true;

            if (payload.name === 'name') {
              if (payload.value.match(/^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/)) element.status = 'valid';
              else element.status = 'invalid';

              if (element.status === 'invalid') element.validation = false;
            }
          }
        });
      }
    },

    resetObjectsState(state) {
      if (state.zones) {
        state.zones.forEach((zone, index) => {
          if (state.backup.zones && state.backup.zones.length > 0) {
            if (zone.id === state.backup.zones[index].id) {
              zone.hum = state.backup.zones[index].hum;
              zone.temp = state.backup.zones[index].temp;
              zone.name = state.backup.zones[index].name;
              zone.status = 'default';
              state.backup.zones[index] = {};
            }
          }

          zone.current = false;
          zone.cancellation = true;
          zone.validation = false;
          zone.extended = false;
        });
      }
      state.hum.current = null;
    },
    cancelObjectsChanges(state, payload) {
      if (payload.param === "about") {
        state.about.cancellation = true;
        state.about.validation = false;
        state.about.name.value = state.backup.about.name.value;
        state.about.name.status = 'default';
        state.about.name.error = false;
        state.backup.about = {};
      }

      if (payload.param === "hum") {
        state.hum.cancellation = true;
        state.hum.validation = false;
        state.hum.current = null;
        state.hum = state.backup.hum;
        state.backup.hum = {};
      }

      if (payload.param === 'zone') {
        state.zones.forEach((element, index) => {
          if (element.id == payload.id) {
            element.hum = state.backup.zones[index].hum;
            element.temp = state.backup.zones[index].temp;
            element.name = state.backup.zones[index].name;
            element.status = 'default';
            element.cancellation = true;
            element.validation = false;
            element.extended = false;
            state.backup.zones[index] = {};
          }
        });
      }
    },
    clearObjectsSection(state, payload) {
      state[payload].cancellation = true;
      state[payload].validation = false;
      state.backup[payload] = {};
    },
    clearObjectsState(state) {
      state.notification = {};
      state.navbar = {};
      state.about = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.hum = {
        list: {
          categories: [],
        },
        programs: [],
        program: {},
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.zones = {};
      state.backup = {};
    },
    updateObjectsZonesSocket(state, payload) {
      if (state.zones !== undefined && state.zones.length > 0) {
        state.zones.forEach((zone, index) => {
          if (zone.id === payload.zones[index].id && !zone.current) {
            if (zone.changed) zone.changed = false;
            else {
              zone.hum.cur = payload.zones[index].hum.cur;
              zone.hum.set = payload.zones[index].hum.set;
              zone.hum.activity = payload.zones[index].hum.activity;

              zone.temp.cur = payload.zones[index].temp.cur;
              zone.temp.set = payload.zones[index].temp.set;
              zone.temp.activity = payload.zones[index].temp.activity;

              state.hum.programs.forEach(program => {
                if (program.active_user === true) {
                  let day = new Date().getDay();
                  let hour = new Date().getHours();

                  if (
                    day >= program.start_day && day <= program.stop_day ||
                    day === program.start_day || day === program.stop_day
                  ) {
                    program.periods.forEach(period => {
                      if (
                        (period.start.hour <= hour && hour <= period.stop.hour) ||
                        (period.start.hour >= hour && hour < period.stop.hour && period.name === 'night') ||
                        (period.start.hour <= hour && hour >= period.stop.hour && period.name === 'night')

                      ) {
                        let start = `${period.start.hour < 10 ? "0" + period.start.hour : period.start.hour}:${period.start.minute < 10 ? "0" + period.start.minute : period.start.minute}`;
                        let stop = `${period.stop.hour < 10 ? "0" + period.stop.hour : period.stop.hour}:${period.stop.minute < 10 ? "0" + period.stop.minute : period.stop.minute}`;

                        zone.program = {
                          id: program.id,
                          period: period.name,
                          title: period.title,
                          range: `${start} - ${stop}`,
                          name: program.name
                        }
                      }
                    });

                    state.hum.zones.forEach(elem => {
                      if (elem.id === zone.id) {

                        elem.hum.programs.forEach(prog => {
                          if (prog.id === zone.program.id) {
                            zone.hum.set = prog[zone.program.period]
                          }
                        });
                      }
                    });
                  }
                }
              });
            }

          }
        });
      }
    },
  },
  actions: {
    fetchObjects(context, payload) {
      context.commit("showPreloader");
      context.commit("clearObjectsState");

      let userID = localStorage.getItem('authorization__user-id');

      axios
        .get(`https://show-api.airwet.app/api/getObjects/${userID}`, this.state.common.headers)
        .then((response) => {
          if (response.data.length > 0) {
            context.commit('updateObjectsNavbar', response.data);

            response.data.forEach((element, index) => {
              if (element.id == payload) {
                element.isActive = true;
                context.commit('updateObjectsAbout', response.data[index]);
                context.commit('updateObjectsZones', response.data[index]);
                context.commit('updateObjectsHum', response.data[index]);
                context.commit('updateObjectsZones', response.data[index]);
              }
            });
          } else {
            let payload = {
              visible: true,
              title: 'Уведомление!',
              text: `
              В настоящий момент в вашей учетной записи нет прикрепленных
              объектов. Попробуйте проверить позднее или свяжитесь с
              техподдержкой.
              `,
              control: false,
            };
            context.commit('createObjectsNotification', payload);
          }

          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    changeObjectsActiveStatus(context, payload) {
      context.commit("showPreloader");

      let system = {
        data: {
          active_user: payload.active,
          active_srv: context.state.about.active_srv,
          errors: context.state.about.errors,
          sender: 'user',
        },
      }

      axios
        .put(`https://show-api.airwet.app/api/updateSystem/${payload.system}`, system, this.state.common.headers)
        .then((response) => {
          context.commit('updateObjectsAbout', response.data);
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    sendObjectsChanges(context, payload) {
      context.commit("showPreloader");

      if (payload === 'about') {
        let system = {
          data: {
            name: context.state.about.name.value,
          },
        }

        axios
          .put(`https://show-api.airwet.app/api/updateSystem/${context.state.about.id}`, system, this.state.common.headers)
          .then((response) => {
            context.commit('updateObjectsAbout', response.data);
            context.commit('clearObjectsSection', payload);
            context.commit("hidePreloader");
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    changeObjectsProgram(context, payload) {
      context.commit("showPreloader");

      let program = {
        data: context.state.hum.program,
      }
      let programZones = context.state.hum.zones;
      let backupZones = context.state.backup.hum.zones;

      context.state.hum.previous = context.state.hum.program;

      axios
        .put(`https://show-api.airwet.app/api/programs/${payload.id}`, program, this.state.common.headers)
        .then(() => {
          let endpoints = [];

          for (let i = 0; i < programZones.length; i++) {
            if (JSON.stringify(programZones[i]) !== JSON.stringify(backupZones[i])) {
              let obj = {
                url: `https://show-api.airwet.app/api/zones/${context.state.hum.zones[i].id}`,
                payload: {
                  data: {
                    hum: {
                      id: context.state.hum.zones[i].hum.id,
                      programs: context.state.hum.zones[i].hum.programs
                    }
                  },
                },
              };

              endpoints[i] = obj;
            }
          }

          if (endpoints.length > 0) {
            axios.all(endpoints.map((endpoint) => axios.put(endpoint.url, endpoint.payload, this.state.common.headers)))
              .then(() => {
                axios
                  .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                  .then((response) => {
                    context.commit('updateObjectsAbout', response.data);
                    context.commit('updateObjectsZones', response.data);
                    context.commit('updateObjectsHum', response.data);
                    context.commit("hidePreloader");
                  })
              })
              .catch((error) => context.commit("handleError", error));
          } else {
            axios
              .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
              .then((response) => {
                context.commit('updateObjectsAbout', response.data);
                context.commit('updateObjectsZones', response.data);
                context.commit('updateObjectsHum', response.data);
                context.commit("hidePreloader");
              })
              .catch((error) => context.commit("handleError", error));
          }

          context.state.backup.hum = {};
        })
        .catch((error) => context.commit("handleError", error));
    },
    changeObjectsZone(context, payload) {
      context.commit("showPreloader");

      let zone = {
        name: null,
        event: null,
        hum: {
          id: null,
          activity: null,
          active_user: null,
          set: null,
        },
        temp: {
          id: null,
          active_user: null,
          set: null,
        },
      }

      context.state.zones.forEach(elem => {
        if (elem.id === payload.zone) {
          zone.name = elem.name;
          zone.event = 'user_config';
          zone.hum.id = elem.hum.id;
          zone.hum.activity = elem.hum.activity;
          zone.hum.set = elem.hum.set;
          zone.hum.cur = elem.hum.cur;
          zone.temp.id = elem.temp.id;
          zone.temp.active_user = elem.temp.active_user;
          zone.temp.set = elem.temp.set;
          zone.temp.cur = elem.temp.cur;
        }
      });

      axios
        .put(`https://show-api.airwet.app/api/zones/${payload.zone}`, {
          data: zone,
        }, this.state.common.headers)
        .then(() => {
          let zones = JSON.parse(JSON.stringify(context.state.zones));

          for (let i = 0; i < zones.length; i++) {
            for (let key in zones[i]) {
              if (key !== 'id' && key !== 'num' && key !== 'hum') delete zones[i][key];
            }
            zones[i].hum = {
              id: zones[i].hum.id,
              activity: zones[i].hum.activity
            }
          }

          let common = {
            data: {
              manual_control: true,
              zones: zones,
            }
          }

          axios
            .put(`https://show-api.airwet.app/api/systems/${context.state.about.id}/manualControl`, common, this.state.common.headers)
            .then(() => {
              context.state.zones.forEach((elem, index) => {
                if (elem.id === payload.zone) {
                  context.state.backup.zones[index] = {};
                  elem.changed = true;
                  elem.current = false;
                  elem.status = 'default';
                  elem.cancellation = true;
                  elem.validation = false;
                  elem.extended = false;
                }
              });

              context.commit("hidePreloader");
              /* axios
                .get(`https://show-api.airwet.app/api/zones/?populate=devs,nozzles,hum,temp&filters[from_system][id][$eq]=${payload.system}`, this.state.common.headers)
                .then(() => {
                  if (context.state.hum.current && context.state.hum.current.changed) {
                    let program = {
                      data: {
                        active_user: false,
                      }
                    }

                    context.state.hum.previous = context.state.hum.program;

                    axios
                      .put(`https://show-api.airwet.app/api/programs/${context.state.hum.current.id}`, program, this.state.common.headers)
                      .then(() => {
                        axios
                          .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                          .then((response) => {
                            context.commit('updateObjectsHum', response.data);
                            context.commit('updateObjectsZones', response.data);
                            context.commit("hidePreloader");
                          })
                          .catch((error) => context.commit("handleError", error));

                        context.state.backup.hum = {};
                      })
                      .catch((error) => context.commit("handleError", error));
                  } else {
                    axios
                      .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                      .then((response) => {
                        context.commit('updateObjectsHum', response.data);
                        context.commit('updateObjectsZones', response.data);
                        context.commit("hidePreloader");
                      })
                  }

                  context.state.zones.forEach((elem, index) => {
                    if (elem.id === payload.zone) {
                      context.state.backup.zones[index] = {};
                    }
                  });
                }) */
            })
            .catch((error) => context.commit("handleError", error));
        })
        .catch((error) => context.commit("handleError", error));
    },
  }
}
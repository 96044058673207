<template>
  <main class="customers">
    <div class="customers__content">
      <div class="customers__holder" data-id="objects">
        <perfect-scrollbar>
          <table
            class="customers__table"
            :data-param="customers.sort.param"
            :data-order="customers.sort.order"
          >
            <thead class="customers__table-thead">
              <tr class="customers__table-row">
                <th class="customers__table-th">
                  <div class="customers__table-wrapper">
                    <div class="customers__table-content">Дата</div>
                  </div>
                </th>
                <th class="customers__table-th">
                  <div class="customers__table-wrapper">
                    <div class="customers__table-content">Фамилия</div>
                  </div>
                </th>
                <th class="customers__table-th">
                  <div class="customers__table-wrapper">
                    <div class="customers__table-content">Имя</div>
                  </div>
                </th>
                <th class="customers__table-th">
                  <div class="customers__table-wrapper">
                    <div class="customers__table-content">Отчество</div>
                  </div>
                </th>
                <th class="customers__table-th">
                  <div class="customers__table-wrapper">
                    <div class="customers__table-content">Должность</div>
                  </div>
                </th>
                <th class="customers__table-th">
                  <div class="customers__table-wrapper">
                    <div class="customers__table-content">Почта</div>
                  </div>
                </th>
                <th class="customers__table-th">
                  <div class="customers__table-wrapper">
                    <div class="customers__table-content">Телефон</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="customers__table-tbody">
              <tr
                class="customers__table-row"
                v-for="row in customers.list"
                :id="row.id"
                :key="row.id"
              >
                <td class="customers__table-td">
                  <div class="customers__table-wrapper">
                    {{ convertDate(row.createdAt) }}
                  </div>
                </td>
                <td class="customers__table-td">
                  <div class="customers__table-wrapper">
                    {{ row.last_name }}
                  </div>
                </td>
                <td class="customers__table-td">
                  <div class="customers__table-wrapper">
                    {{ row.first_name }}
                  </div>
                </td>
                <td class="customers__table-td">
                  <div class="customers__table-wrapper">
                    {{ row.middle_name }}
                  </div>
                </td>
                <td class="customers__table-td">
                  <div class="customers__table-wrapper">
                    {{ row.occupation }}
                  </div>
                </td>
                <td class="customers__table-td">
                  <div class="customers__table-wrapper">
                    {{ row.email }}
                  </div>
                </td>
                <td class="customers__table-td">
                  <div class="customers__table-wrapper">
                    {{ row.phone }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "Customers",
    computed: {
      ...mapGetters({
        customers: "getCustomers",
      }),
    },
    methods: {
      ...mapActions([
        "fetchCustomers",
      ]),
      convertDate(timestamp) {
        let newDate = new Date(Date.parse(timestamp));
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1;
        if (month < 10) month = "0" + month;
        let day = newDate.getDate();
        if (day < 10) day = "0" + day;
        let date = `${day}.${month}.${year}`;
        return date;
      },
    },
    created() {
      this.fetchCustomers();
    },
  };
</script>

<style lang="scss" scoped>
  .customers {
    position: relative;
    width: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;

      @include mediaquery(sm) {
        padding-bottom: 100px;
      }
    }

    &__header {
      width: 100%;
    }

    &__holder {
      @include base;
    }

    .ps {
      width: 100%;
      padding: 0 10px 10px;
    }

    &__table {
      width: 100%;
      min-width: 1100px;
      border-collapse: collapse;

      &-thead {
        .customers__table-wrapper {
          min-height: 36px;
          border-right: 0;
        }
      }

      &-tbody {
        @include mediaquery(md) {
          .customers__table-row {
            &:hover {
              cursor: pointer;
              outline: 2px solid #41c6eb;
            }
          }
        }
      }

      &-row {
        background-color: $colorMainLight;
      }

      &-th,
      &-td {
        padding: 10px 0;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        text-align: left;

        &:last-of-type {
          .customers__table-wrapper {
            border-right: 0;
          }
        }
      }

      &-th {
        border: 0;
        padding: 10px 5px;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      &-td {
        position: relative;
        .customers__table-wrapper {
          padding-left: 19px;
        }

        &:first-of-type {
          .customers__table-wrapper {
            padding-left: 15px;
          }
        }

        &:last-of-type {
          text-align: center;
        }
      }

      &-wrapper {
        border-right: 1px solid #dddddd;
        font-weight: 400;
        font-size: 12px;
        color: #222325;
        display: flex;
        align-items: center;
      }

      &-content {
        @include base;

        padding: 7px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        width: 100%;
        text-align: center;
      }

      &-sort {
        position: relative;
        background: none;
        padding: 0;
        display: flex;
        border: 0;
        height: 16px;
        width: 17px;
        cursor: pointer;
        margin-left: 20px;
      }

      &-up {
        position: absolute;
        left: 5px;
        top: 2px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(45deg);
      }

      &-down {
        position: absolute;
        left: 5px;
        bottom: 1px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(225deg);
      }

      &-type {
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 10px;

        &_pro {
          background-color: #ffc96f;
          color: #222325;
        }
      }

      &-kind {
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 10px;

        &_error {
          background-color: #ff7156;
        }

        &_success {
          background-color: #7ed321;
        }
      }

      &-state {
        @include state;
        margin: 0;
        font-size: 11px;
        min-width: auto;
      }

      &-delete {
        font-size: 16px;
        color: #ff7156;
        padding: 0;
        cursor: pointer;
        border: 0;
        background: none;

        @include mediaquery(md) {
          &:hover {
            color: #fc302e;
          }
        }
      }
    }
  }
</style>
<template>
  <main class="system" :data-id="about.id">
    <div class="system__content">
      <div class="notification" v-if="notification.visible">
        <div class="notification__info">
          <div class="notification__title">{{ notification.title }}</div>
          <div class="notification__text">{{ notification.text }}</div>
        </div>
      </div>
      <slot v-if="this.$route.params.id">
        <div class="system__navbar">
          <ul class="system__navbar-list">
            <li class="system__navbar-back" @click="goBack">
              <span class="system__navbar-arrow">
                <font-awesome-icon icon="arrow-left" />
              </span>
              <div class="system__navbar-info">
                <span class="system__navbar-name">Список систем</span>
                <span class="system__navbar-feature">2089</span>
              </div>
            </li>
            <li
              v-for="item in navbar.list"
              :class="[
                item.isActive
                  ? 'system__navbar-item system__navbar-item_current'
                  : 'system__navbar-item',
              ]"
              :id="item.id"
              :key="item.id"
              @click="setActiveItem"
            >
              <span class="system__navbar-quantity">{{ item.quantity }}</span>
              <div class="system__navbar-info">
                <span class="system__navbar-name">{{ item.name }}</span>
                <span class="system__navbar-feature">{{ item.feature }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="system__info" v-if="navbar.current === 1">
          <section class="about">
            <div class="about__header" @click="toggleSection('about')">
              <div class="about__wrapper">
                <div class="about__name">
                  <div class="about__title">{{ about.name.title }}</div>
                  <Input
                    :type="'text'"
                    :name="about.name.name"
                    :value="about.name.value"
                    :status="about.name.status"
                    @paste="handleChanges($event, about.name.element)"
                    @input="handleChanges($event, about.name.element)"
                    @change="handleChanges($event, about.name.element)"
                    @click.stop="false"
                  />
                  <span class="about__error" v-if="about.name.error">{{
                    about.name.errorText
                  }}</span>
                </div>
                <div class="about__address">
                  <div class="about__title">{{ about.address.title }}</div>
                  <Input
                    :type="'text'"
                    :name="about.address.name"
                    :value="about.address.value"
                    :status="about.address.status"
                    @paste="handleChanges($event, about.address.element)"
                    @input="handleChanges($event, about.address.element)"
                    @change="handleChanges($event, about.address.element)"
                    @click.stop="false"
                  />
                  <span class="about__error" v-if="about.address.error">{{
                    about.address.errorText
                  }}</span>
                </div>
                <div class="about__service">
                  <div class="about__title">Дата последнего ТО</div>
                  <div
                    ref="date_maintenance_last"
                    name="date_maintenance_last"
                    @change="handleChanges($event, 'datepicker')"
                    @click.stop="false"
                  >
                    <Datepicker
                      v-model="date_maintenance_last"
                      :format="format"
                      autoApply
                      hideInputIcon
                    ></Datepicker>
                  </div>
                </div>
                <div class="about__service">
                  <div class="about__title">Дата планового ТО</div>
                  <div
                    ref="date_maintenance_plan"
                    name="date_maintenance_plan"
                    @change="handleChanges($event, 'datepicker')"
                    @click.stop="false"
                  >
                    <Datepicker
                      v-model="date_maintenance_plan"
                      :format="format"
                      autoApply
                      hideInputIcon
                    ></Datepicker>
                  </div>
                </div>
                <div class="about__mileage">
                  <div class="about__title">Обороты | М/ч</div>
                  <Input
                    :type="'text'"
                    :value="`${about.mileage} | ${about.pump_hours}`"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="about__control">
                <div class="about__info">
                  <div
                    :class="`about__state about__state_${about.state.value}`"
                  >
                    {{ about.state.text }}
                  </div>
                  <slot v-if="about.state.value === 'error'">
                    <Switch
                      :paint="'blue'"
                      :status="false"
                      :disabled="true"
                      @click.stop="false"
                    />
                  </slot>
                  <slot v-else-if="about.state.value === 'inactive'">
                    <Switch
                      :paint="'blue'"
                      :status="about.active_user"
                      @change.prevent="changeSystemStatus"
                    />
                  </slot>
                  <slot v-else>
                    <Switch
                      :paint="'blue'"
                      :status="about.active_srv"
                      @change.prevent="changeSystemStatus"
                    />
                  </slot>
                </div>
                <button
                  class="about__button"
                  @click.stop="toggleSection('about')"
                >
                  <span
                    :class="[
                      about.extended
                        ? 'about__button-icon about__button-icon_rotate'
                        : 'about__button-icon',
                    ]"
                  ></span>
                </button>
              </div>
            </div>
            <div class="about__body" v-show="about.extended">
              <div class="about__contract">
                Договор {{ about.contract.value }} от
                {{ this.convertDate(about.contract.date) }}
              </div>
              <div class="about__params">
                <div class="about__param">
                  <div class="about__title">Конфигурация системы</div>
                  <slot v-if="about.type === 'pro'">
                    <Input
                      :type="'text'"
                      :value="`Airwet pro`"
                      :disabled="true"
                    />
                  </slot>
                  <slot v-if="about.type === 'home'">
                    <Input
                      :type="'text'"
                      :value="`Airwet ${about.type} ${
                        about.system_type !== null ? about.system_type : ''
                      } ${
                        zones.length > 0 ? this.declOfNum(zones.length) : ''
                      }`"
                      :disabled="true"
                    />
                  </slot>
                  <slot v-if="about.type === 'light'">
                    <Input
                      :type="'text'"
                      :value="`Airwet ${about.type} ${
                        zones.length > 0 ? this.declOfNum(zones.length) : ''
                      }`"
                      :disabled="true"
                    />
                  </slot>
                </div>
                <div class="about__param">
                  <div class="about__title">Дата запуска</div>
                  <div
                    ref="date_start"
                    name="date_start"
                    @change="handleChanges($event, 'datepicker')"
                    :status="about.date_start.status"
                  >
                    <Datepicker
                      v-model="date_start"
                      :format="format"
                      autoApply
                      hideInputIcon
                    ></Datepicker>
                  </div>
                </div>
                <div class="about__param">
                  <div class="about__title">Дата установки системы</div>
                  <div
                    ref="date_install"
                    name="date_install"
                    @change="handleChanges($event, 'datepicker')"
                  >
                    <Datepicker
                      v-model="date_install"
                      :format="format"
                      autoApply
                      hideInputIcon
                    ></Datepicker>
                  </div>
                </div>
                <div class="about__param">
                  <div class="about__title">Гарантия до</div>
                  <div
                    ref="date_warranty"
                    name="date_warranty"
                    @change="handleChanges($event, 'datepicker')"
                  >
                    <Datepicker
                      v-model="date_warranty"
                      :format="format"
                      autoApply
                      hideInputIcon
                    ></Datepicker>
                  </div>
                </div>
              </div>
              <div class="about__owner">
                <div
                  class="about__block"
                  v-for="param in about.owner"
                  :data-id="param.id"
                  :key="param.id"
                >
                  <div class="about__title">{{ param.title }}</div>
                  <Input
                    :type="param.type"
                    :name="param.name"
                    :value="param.value"
                    :status="param.status"
                    :disabled="true"
                  />
                </div>
                <div class="about__action">
                  <Button
                    :type="'action'"
                    :value="'edit'"
                    :paint="'gray'"
                    @click="changeOwner"
                  />
                </div>
              </div>
              <div
                class="about__contacts"
                v-if="
                  Array.isArray(about.contacts) && about.contacts.length > 0
                "
              >
                <div class="about__contacts-headline">Контакты Airwet</div>
                <div
                  class="about__contacts-blocks"
                  v-for="contact in about.contacts"
                  :data-id="contact.id"
                  :key="contact.id"
                >
                  <div class="about__block">
                    <div class="about__title">Фамилия</div>
                    <Input
                      :type="'text'"
                      :name="'last_name'"
                      :value="contact.last_name.value"
                      :status="contact.last_name.status"
                      @focus="handleChanges"
                      @paste="handleChanges"
                      @input="handleChanges"
                      @change="handleChanges"
                    />
                    <span class="about__error" v-if="contact.last_name.error">{{
                      contact.last_name.error
                    }}</span>
                  </div>
                  <div class="about__block">
                    <div class="about__title">Имя</div>
                    <Input
                      :type="'text'"
                      :name="'first_name'"
                      :value="contact.first_name.value"
                      :status="contact.first_name.status"
                      @focus="handleChanges"
                      @paste="handleChanges"
                      @input="handleChanges"
                      @change="handleChanges"
                    />
                    <span
                      class="about__error"
                      v-if="contact.first_name.error"
                      >{{ contact.first_name.error }}</span
                    >
                  </div>
                  <div class="about__block">
                    <div class="about__title">Отчество</div>
                    <Input
                      :type="'text'"
                      :name="'middle_name'"
                      :value="contact.middle_name.value"
                      :status="contact.middle_name.status"
                      @focus="handleChanges"
                      @paste="handleChanges"
                      @input="handleChanges"
                      @change="handleChanges"
                    />
                    <span
                      class="about__error"
                      v-if="contact.middle_name.error"
                      >{{ contact.middle_name.error }}</span
                    >
                  </div>
                  <div class="about__block">
                    <div class="about__title">Комментарий</div>
                    <Input
                      :type="'text'"
                      :name="'comment'"
                      :value="contact.comment.value"
                      :status="contact.comment.status"
                      @focus="handleChanges"
                      @paste="handleChanges"
                      @input="handleChanges"
                      @change="handleChanges"
                    />
                    <span class="about__error" v-if="contact.comment.error">{{
                      contact.comment.error
                    }}</span>
                  </div>
                  <div class="about__block">
                    <div class="about__title">Телефон</div>
                    <Input
                      :type="'tel'"
                      :name="'phone'"
                      :value="contact.phone.value"
                      :status="contact.phone.status"
                      @focus="handleChanges"
                      @paste="handleChanges"
                      @input="handleChanges"
                      @change="handleChanges"
                    />
                    <span class="about__error" v-if="contact.phone.error">{{
                      contact.phone.error
                    }}</span>
                  </div>
                  <div class="about__block">
                    <div class="about__title">Электронная почта</div>
                    <Input
                      :type="'email'"
                      :name="'email'"
                      :value="contact.email.value"
                      :status="contact.email.status"
                      @focus="handleChanges"
                      @paste="handleChanges"
                      @input="handleChanges"
                      @change="handleChanges"
                    />
                    <span class="about__error" v-if="contact.email.error">{{
                      contact.email.error
                    }}</span>
                  </div>
                  <div class="about__action">
                    <Button
                      :type="'action'"
                      :value="'delete'"
                      :paint="'gray'"
                      @click="deleteContact"
                    />
                  </div>
                </div>
              </div>
              <div class="about__vacation" v-if="false">
                <div class="about__vacation-info">
                  <div class="about__vacation-title">Отпуск</div>
                  <div class="about__vacation-description">
                    Перейти в режим отпуск
                  </div>
                </div>
                <div class="about__vacation-control">
                  <Switch :status="false" :paint="'blue'" />
                </div>
              </div>
              <section class="maintenance" v-if="false">
                <div class="maintenance__header" @click="toggleSubSection">
                  <div class="maintenance__info">
                    <div class="maintenance__name">01 МАЙ 2022</div>
                    <div class="maintenance__description">
                      дата ближайшего сервисного обслуживания
                    </div>
                  </div>
                  <div class="maintenance__control">
                    <button
                      class="maintenance__button"
                      @click.stop="toggleSubSection"
                    >
                      <span class="maintenance__button-icon"></span>
                    </button>
                  </div>
                </div>
                <div class="maintenance__body">
                  <div class="maintenance__blocks">
                    <div class="maintenance__block">
                      <div class="maintenance__title">01.05.2022</div>
                      <div class="maintenance__wrapper">
                        <Input
                          :type="'text'"
                          :value="'Техническое Обслуживание №1'"
                          :disabled="false"
                        />
                        <Button
                          :type="'text'"
                          :value="'Записаться'"
                          :paint="'blue'"
                          @click="getMaintenance"
                        />
                      </div>
                    </div>
                    <div class="maintenance__block">
                      <div class="maintenance__title">01.05.2022</div>
                      <div class="maintenance__wrapper">
                        <Input
                          :type="'text'"
                          :value="'Техническое Обслуживание №1'"
                          :disabled="true"
                        />
                        <Button
                          :type="'text'"
                          :value="'2 310 руб.'"
                          :paint="'gray'"
                          :disabled="true"
                        />
                      </div>
                    </div>
                    <div class="maintenance__block">
                      <div class="maintenance__title">01.05.2022</div>
                      <div class="maintenance__wrapper">
                        <Input
                          :type="'text'"
                          :value="'Техническое Обслуживание №1'"
                          :disabled="true"
                        />
                        <Button
                          :type="'text'"
                          :value="'2 310 руб.'"
                          :paint="'gray'"
                          :disabled="true"
                        />
                      </div>
                    </div>
                    <div class="maintenance__block">
                      <div class="maintenance__title">01.05.2022</div>
                      <div class="maintenance__wrapper">
                        <Input
                          :type="'text'"
                          :value="'Техническое Обслуживание №1'"
                          :disabled="true"
                        />
                        <Button
                          :type="'text'"
                          :value="'2 310 руб.'"
                          :paint="'gray'"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="documents">
                <div class="documents__header" @click="toggleSubSection">
                  <div class="documents__info">
                    <div class="documents__name">ДОКУМЕНТЫ</div>
                    <div class="documents__description">
                      все документы системы
                    </div>
                  </div>
                  <div class="documents__control">
                    <button
                      class="documents__button"
                      @click.stop="toggleSubSection"
                    >
                      <span class="documents__button-icon"></span>
                    </button>
                  </div>
                </div>
                <div class="documents__body">
                  <div class="documents__preview">
                    <div
                      class="documents__document"
                      v-for="document in about.documents"
                      :data-id="document.id"
                      :data-file="document.file.id"
                      :key="document.id"
                    >
                      <div class="documents__document-info">
                        <div class="documents__document-name">
                          {{ document.name }}.{{ document.file.ext.slice(1) }}
                        </div>
                        <div class="documents__document-size">
                          {{ document.file.size }} Кб
                        </div>
                      </div>
                      <div class="documents__document-control">
                        <Button
                          :type="'action'"
                          :value="'download'"
                          :paint="'gray'"
                          @click="downloadDocument(document)"
                        />
                        <Button
                          :type="'action'"
                          :value="'delete'"
                          :paint="'gray'"
                          @click="deleteDocument"
                        />
                      </div>
                    </div>
                    <div class="documents__new">
                      <div class="documents__new-title">Добавить документ</div>
                      <Button
                        :type="'action'"
                        :value="'add'"
                        :paint="'gray'"
                        @click="addDocument"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <div class="about__footer">
                <Button
                  :type="'text'"
                  :value="'Отмена'"
                  :paint="'gray'"
                  :disabled="about.cancellation"
                  @click="cancelChanges($event, 'about')"
                />
                <Button
                  :type="'text'"
                  :value="'Сохранить изменения'"
                  :paint="'blue'"
                  :disabled="!about.validation"
                  @click="sendChanges('about')"
                />
              </div>
            </div>
          </section>
          <section
            class="hum"
            v-if="Array.isArray(hum.programs) && hum.programs.length > 0"
          >
            <div class="hum__header" @click="toggleSection('hum')">
              <div class="hum__wrapper">
                <div class="hum__list">
                  <Dropdown
                    type="simple"
                    :items="hum.list.categories"
                    :value="hum.list.value"
                    paint="blue"
                    @click.stop="handleProperty($event, 'hum', 'programs')"
                  />
                </div>
                <div class="hum__period" @click.stop="true">
                  <div class="hum__start">
                    <span class="hum__start-wrapper">
                      <span class="hum__start-strong">Старт</span>
                      программы
                    </span>
                    <span class="hum__start-date">
                      {{ convertProgramTime(hum.program.start_day, "day") }}
                    </span>
                  </div>
                  <div class="hum__stop">
                    <span class="hum__stop-wrapper">
                      <span class="hum__stop-strong">Стоп</span>
                      программы
                    </span>
                    <span class="hum__stop-date">
                      {{ convertProgramTime(hum.program.stop_day, "day") }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="hum__control">
                <Switch
                  :status="hum.program.active_srv"
                  :paint="'blue'"
                  @change.prevent="handleProperty($event, 'hum', 'active_srv')"
                  @click.stop="false"
                />
                <button class="hum__button" @click.stop="toggleSection('hum')">
                  <span
                    :class="[
                      hum.extended
                        ? 'hum__button-icon hum__button-icon_rotate'
                        : 'hum__button-icon',
                    ]"
                  ></span>
                </button>
              </div>
            </div>
            <div class="hum__body" v-show="hum.extended">
              <div class="hum__program" :data-id="hum.program.id">
                <div class="hum__column">
                  <div class="hum__schedule">
                    <div class="hum__schedule-headline">ГРАФИК ПРОГРАММЫ</div>
                    <div class="hum__schedule-content">
                      <div
                        class="hum__schedule-period"
                        v-for="period in hum.program.periods"
                        :id="period.id"
                        :key="period.id"
                        :data-period="period.name"
                      >
                        <div class="hum__schedule-title">
                          {{ period.title }}
                        </div>
                        <div class="hum__schedule-range">
                          <Timepicker
                            :hour="
                              convertProgramTime(period.start.hour, 'hour')
                            "
                            :minute="
                              convertProgramTime(period.start.minute, 'minute')
                            "
                            data-stage="start"
                            @click="handleProperty($event, 'hum', 'range')"
                          />
                          <Timepicker
                            :hour="convertProgramTime(period.stop.hour, 'hour')"
                            :minute="
                              convertProgramTime(period.stop.minute, 'minute')
                            "
                            data-stage="stop"
                            @click="handleProperty($event, 'hum', 'range')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="hum__settings">
                    <div class="hum__settings-setting">
                      <div class="hum__settings-stage">СТАРТ</div>
                      <div class="hum__settings-wrapper">
                        <Daypicker
                          :day="
                            convertProgramTime(hum.program.start_day, 'day')
                          "
                          data-stage="start"
                          @click="handleProperty($event, 'hum', 'period')"
                        />
                      </div>
                    </div>
                    <div class="hum__settings-setting">
                      <div class="hum__settings-stage">СТОП</div>
                      <div class="hum__settings-wrapper">
                        <Daypicker
                          :day="convertProgramTime(hum.program.stop_day, 'day')"
                          data-stage="stop"
                          @click="handleProperty($event, 'hum', 'period')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hum__column">
                  <div class="hum__zones">
                    <div class="hum__select">
                      <Checkbox
                        data-period="morning"
                        :status="
                          hum.program.period.name === 'morning' ? true : false
                        "
                        @change="handleProperty($event, 'hum', 'select')"
                      />
                      <Checkbox
                        data-period="day"
                        :status="
                          hum.program.period.name === 'day' ? true : false
                        "
                        @change="handleProperty($event, 'hum', 'select')"
                      />
                      <Checkbox
                        data-period="evening"
                        :status="
                          hum.program.period.name === 'evening' ? true : false
                        "
                        @change="handleProperty($event, 'hum', 'select')"
                      />
                      <Checkbox
                        data-period="night"
                        :status="
                          hum.program.period.name === 'night' ? true : false
                        "
                        @change="handleProperty($event, 'hum', 'select')"
                      />
                    </div>
                    <perfect-scrollbar>
                      <div
                        class="hum__zone"
                        v-for="zone in hum.zones"
                        :id="zone.id"
                        :key="zone.id"
                      >
                        <div class="hum__zone-title">{{ zone.name.value }}</div>
                        <div class="hum__zone-value">
                          <button
                            class="hum__zone-button"
                            data-period="morning"
                            data-step="decrease"
                            @click="handleProperty($event, 'hum', 'zone')"
                            v-if="zone.hum.morning.control"
                          >
                            <span class="hum__zone-minus"></span>
                          </button>
                          <Input
                            :type="'tel'"
                            :value="
                              zone.hum.morning.value + zone.hum.morning.unit
                            "
                            :status="zone.hum.morning.status"
                            data-period="morning"
                            @focus="handleProperty($event, 'hum', 'zone')"
                            @change="handleProperty($event, 'hum', 'zone')"
                            @input="handleProperty($event, 'hum', 'zone')"
                            @blur="handleProperty($event, 'hum', 'zone')"
                          />
                          <button
                            class="hum__zone-button"
                            data-period="morning"
                            data-step="increase"
                            @click="handleProperty($event, 'hum', 'zone')"
                            v-if="zone.hum.morning.control"
                          >
                            <span class="hum__zone-plus"></span>
                          </button>
                        </div>
                        <div class="hum__zone-value">
                          <button
                            class="hum__zone-button"
                            data-period="day"
                            data-step="decrease"
                            @click="handleProperty($event, 'hum', 'zone')"
                            v-if="zone.hum.day.control"
                          >
                            <span class="hum__zone-minus"></span>
                          </button>
                          <Input
                            :type="'tel'"
                            :value="zone.hum.day.value + zone.hum.day.unit"
                            :status="zone.hum.day.status"
                            data-period="day"
                            @focus="handleProperty($event, 'hum', 'zone')"
                            @change="handleProperty($event, 'hum', 'zone')"
                            @input="handleProperty($event, 'hum', 'zone')"
                            @blur="handleProperty($event, 'hum', 'zone')"
                          />
                          <button
                            class="hum__zone-button"
                            data-period="day"
                            data-step="increase"
                            @click="handleProperty($event, 'hum', 'zone')"
                            v-if="zone.hum.day.control"
                          >
                            <span class="hum__zone-plus"></span>
                          </button>
                        </div>
                        <div class="hum__zone-value">
                          <button
                            class="hum__zone-button"
                            data-period="evening"
                            data-step="decrease"
                            @click="handleProperty($event, 'hum', 'zone')"
                            v-if="zone.hum.evening.control"
                          >
                            <span class="hum__zone-minus"></span>
                          </button>
                          <Input
                            :type="'tel'"
                            :value="
                              zone.hum.evening.value + zone.hum.evening.unit
                            "
                            :status="zone.hum.evening.status"
                            data-period="evening"
                            @focus="handleProperty($event, 'hum', 'zone')"
                            @change="handleProperty($event, 'hum', 'zone')"
                            @input="handleProperty($event, 'hum', 'zone')"
                            @blur="handleProperty($event, 'hum', 'zone')"
                          />
                          <button
                            class="hum__zone-button"
                            data-period="evening"
                            data-step="increase"
                            @click="handleProperty($event, 'hum', 'zone')"
                            v-if="zone.hum.evening.control"
                          >
                            <span class="hum__zone-plus"></span>
                          </button>
                        </div>
                        <div class="hum__zone-value">
                          <button
                            class="hum__zone-button"
                            data-period="night"
                            data-step="decrease"
                            @click="handleProperty($event, 'hum', 'zone')"
                            v-if="zone.hum.night.control"
                          >
                            <span class="hum__zone-minus"></span>
                          </button>
                          <Input
                            :type="'tel'"
                            :value="zone.hum.night.value + zone.hum.night.unit"
                            :status="zone.hum.night.status"
                            data-period="night"
                            @focus="handleProperty($event, 'hum', 'zone')"
                            @change="handleProperty($event, 'hum', 'zone')"
                            @input="handleProperty($event, 'hum', 'zone')"
                            @blur="handleProperty($event, 'hum', 'zone')"
                          />
                          <button
                            class="hum__zone-button"
                            data-period="night"
                            data-step="increase"
                            @click="handleProperty($event, 'hum', 'zone')"
                            v-if="zone.hum.night.control"
                          >
                            <span class="hum__zone-plus"></span>
                          </button>
                        </div>
                      </div>
                    </perfect-scrollbar>
                  </div>
                  <div class="hum__tuner">
                    <div class="hum__tuner-period">
                      <span class="hum__tuner-title">Влажность периода</span>
                      <div class="hum__tuner-wrapper">
                        <Slider
                          type="horizontal"
                          :min="hum.program.min"
                          :max="hum.program.max"
                          :value="hum.program.period.value"
                          :disabled="!hum.program.period.name"
                          data-scope="period"
                          @input.prevent="
                            handleProperty($event, 'hum', 'value')
                          "
                        />
                        <span class="hum__tuner-value"
                          >{{ hum.program.period.value }}%</span
                        >
                      </div>
                    </div>
                    <div class="hum__tuner-common">
                      <span class="hum__tuner-title">Общая влажность</span>
                      <div class="hum__tuner-wrapper">
                        <Slider
                          type="horizontal"
                          :min="hum.program.min"
                          :max="hum.program.max"
                          :value="hum.program.value"
                          :disabled="false"
                          data-scope="common"
                          @input.prevent="
                            handleProperty($event, 'hum', 'value')
                          "
                        />
                        <span class="hum__tuner-value"
                          >{{ hum.program.value }}%</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="hum__footer">
                    <div class="hum__footer-control">
                      <Button
                        :type="'text'"
                        :value="'Отмена'"
                        :paint="'gray'"
                        :disabled="hum.cancellation"
                        @click="cancelChanges($event, 'hum')"
                      />
                      <Button
                        :type="'text'"
                        :value="'Сохранить изменения'"
                        :paint="'blue'"
                        :disabled="!hum.validation"
                        @click="changeProgram"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="system__zones">
            <div
              v-for="zone in zones"
              :class="zone.current ? 'zone zone_current' : 'zone'"
              :data-id="zone.id"
              :key="zone.id"
              v-click-outside="commonClick"
              @click="setActiveZone"
            >
              <div class="zone__body">
                <div class="zone__name">
                  <input
                    :class="{
                      'zone__name-input': true,
                      'zone__name-input_valid': zone.name.status === 'valid',
                      'zone__name-input_invalid':
                        zone.name.status === 'invalid',
                    }"
                    type="text"
                    :name="'name'"
                    :status="zone.name.status"
                    :value="zone.name.value"
                    @input="handleZone($event, 'input')"
                    @change="handleZone($event, 'input')"
                  />
                </div>
                <div
                  class="zone__program"
                  :data-period="zone.program.period"
                  v-if="zone.program && hum.program.active_srv"
                >
                  <span class="zone__program-title">{{
                    zone.program.title
                  }}</span>
                  <span class="zone__program-range">{{
                    zone.program.range
                  }}</span>
                </div>
                <Dropdown
                  :type="zone.num.type"
                  :name="zone.num.name"
                  :items="zone.num.categories"
                  :value="zone.num.value"
                  :status="zone.num.status"
                  @click="handleZone($event, 'dropdown')"
                />
                <div class="zone__info">
                  <div class="zone__caption">
                    <div class="zone__caption-set">Целевое</div>
                    <hr class="zone__caption-line" />
                    <div class="zone__caption-cur">Текущее</div>
                  </div>
                  <div class="zone__indicators">
                    <div class="zone__indicator" data-param="hum">
                      <slot v-if="zone.hum.active_srv">
                        <div class="zone__set">
                          <font-awesome-icon icon="arrow-down" />
                          <span class="zone__set-value">{{
                            zone.hum.set
                          }}</span>
                          <span class="zone__set-unit">%</span>
                        </div>
                        <div class="zone__cur">
                          <font-awesome-icon
                            icon="arrow-up"
                            v-if="zone.hum.activity"
                          />
                          <font-awesome-icon icon="arrow-down" v-else />
                          <span class="zone__cur-value">{{
                            zone.hum.cur
                          }}</span>
                          <span class="zone__cur-unit">%</span>
                        </div>
                      </slot>
                      <slot v-else>
                        <div class="zone__set">
                          <span class="zone__set-dash">&mdash;</span>
                          <span class="zone__set-dash">&mdash;</span>
                          <span class="zone__set-unit">%</span>
                        </div>
                        <div class="zone__cur">
                          <span class="zone__cur-value">{{
                            zone.hum.cur
                          }}</span>
                          <span class="zone__cur-unit">%</span>
                        </div>
                      </slot>
                      <span
                        :class="
                          zone.hum.active_srv
                            ? 'zone__led zone__led_on'
                            : 'zone__led zone__led_off'
                        "
                      ></span>
                    </div>
                    <div class="zone__indicator" data-param="temp">
                      <slot v-if="zone.temp.active_srv">
                        <div class="zone__set">
                          <font-awesome-icon icon="arrow-down" />
                          <span class="zone__set-value">{{
                            zone.temp.set
                          }}</span>
                          <span class="zone__set-unit">&#176;</span>
                        </div>
                        <div class="zone__cur">
                          <font-awesome-icon
                            icon="arrow-up"
                            v-if="zone.temp.activity"
                          />
                          <font-awesome-icon icon="arrow-down" v-else />
                          <span class="zone__cur-value">{{
                            zone.temp.cur
                          }}</span>
                          <span class="zone__cur-unit">&#176;</span>
                        </div>
                      </slot>
                      <slot v-else>
                        <div class="zone__set">
                          <span class="zone__set-dash">&mdash;</span>
                          <span class="zone__set-dash">&mdash;</span>
                          <span class="zone__set-unit">&#176;</span>
                        </div>
                        <div class="zone__cur">
                          <span class="zone__cur-value">{{
                            zone.temp.cur
                          }}</span>
                          <span class="zone__cur-unit">&#176;</span>
                        </div>
                      </slot>
                      <span
                        :class="
                          zone.temp.active_srv
                            ? 'zone__led zone__led_on'
                            : 'zone__led zone__led_off'
                        "
                      ></span>
                    </div>
                    <div class="zone__indicator" data-param="carb" v-if="false">
                      <div class="zone__set">
                        <span class="zone__set-value">1189</span>
                        <span class="zone__set-unit">PPM</span>
                      </div>
                      <div class="zone__cur">
                        <div class="zone__cur-wrapper">
                          <font-awesome-icon
                            icon="arrow-up"
                            v-if="zone.carb.active_srv && zone.carb.activity"
                          />
                          <font-awesome-icon icon="arrow-down" v-else />
                          <span class="zone__cur-value">820</span>
                        </div>
                        <span class="zone__cur-unit">PPM</span>
                      </div>
                      <span
                        :class="
                          zone.carb.active_srv
                            ? 'zone__led zone__led_on'
                            : 'zone__led zone__led_off'
                        "
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="zone__footer" v-show="zone.extended">
                <div class="zone__params">
                  <div class="zone__param" data-param="hum">
                    <Switch
                      :paint="'blue'"
                      :name="'active_srv'"
                      :status="zone.hum.active_srv"
                      @change="handleZone($event, 'switch', 'hum')"
                    />
                    <div class="zone__tuner">
                      <div class="zone__set">
                        <span class="zone__set-value">{{ zone.hum.set }}</span>
                        <span class="zone__set-unit">%</span>
                      </div>
                      <Slider
                        type="horizontal"
                        :value="zone.hum.set"
                        :min="zone.hum.min"
                        :max="zone.hum.max"
                        :name="'set'"
                        :disabled="!zone.hum.active_srv"
                        @input="handleZone($event, 'slider', 'hum')"
                        @change="handleZone($event, 'slider', 'hum')"
                      />
                    </div>
                    <img
                      class="zone__icon"
                      src="../assets/images/hum.svg"
                      alt=""
                    />
                  </div>
                  <div class="zone__param" data-param="temp">
                    <Switch
                      :paint="'orange'"
                      :name="'active_srv'"
                      :status="zone.temp.active_srv"
                      @change="handleZone($event, 'switch', 'temp')"
                    />
                    <div class="zone__tuner">
                      <div class="zone__set">
                        <span class="zone__set-value">{{ zone.temp.set }}</span>
                        <span class="zone__set-unit">&#176;</span>
                      </div>
                      <Slider
                        type="horizontal"
                        :value="zone.temp.set"
                        :min="zone.temp.min"
                        :max="zone.temp.max"
                        :name="'set'"
                        :disabled="!zone.temp.active_srv"
                        @input="handleZone($event, 'slider', 'temp')"
                        @change="handleZone($event, 'slider', 'temp')"
                      />
                    </div>
                    <img
                      class="zone__icon"
                      src="../assets/images/temp.svg"
                      alt=""
                    />
                  </div>
                  <div class="zone__param" data-param="carb" v-if="false">
                    <Switch
                      :paint="'dark'"
                      :name="'active_srv'"
                      :status="true"
                      @change="handleZone($event, 'switch', 'carb')"
                    />
                    <div class="zone__tuner">
                      <div class="zone__set">
                        <span class="zone__set-value">4750</span>
                        <span class="zone__set-unit">PPM</span>
                      </div>
                      <Slider
                        type="horizontal"
                        :name="'set'"
                        @input="handleZone($event, 'slider', 'carb')"
                        @change="handleZone($event, 'slider', 'carb')"
                      />
                    </div>
                    <img
                      class="zone__icon"
                      src="../assets/images/carb.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div class="zone__block" data-block="devs">
                  <div class="zone__subblock" data-part="first">
                    <div class="zone__title">Тип устройства</div>
                    <Dropdown
                      :type="'simple'"
                      :items="zone.device.dropdown.categories"
                      :name="zone.device.dropdown.name"
                      :value="zone.device.dropdown.value"
                      :status="zone.device.dropdown.status"
                      @click="handleZone($event, 'dropdown')"
                    />
                  </div>
                  <div class="zone__subblock" data-part="last">
                    <div class="zone__title">Адрес</div>
                    <Input
                      :type="'text'"
                      :name="zone.device.input.name"
                      :value="zone.device.input.value"
                      :status="zone.device.input.status"
                      @input="handleZone($event, 'input')"
                      @change="handleZone($event, 'input')"
                    />
                  </div>
                  <Button
                    :type="'action'"
                    :value="'add'"
                    :paint="'gray'"
                    :disabled="zone.device.button.disabled"
                    @click.stop="handleZone($event, 'add')"
                  />
                </div>
                <div
                  class="zone__modules"
                  v-if="zone.devs.length > 0"
                  data-modules="devs"
                >
                  <div
                    class="zone__module"
                    v-for="(elem, index) in zone.devs"
                    :data-id="index"
                    :key="index"
                  >
                    <div class="zone__subblock">
                      <Input
                        :type="'text'"
                        :value="elem.type === 's' ? `Датчик` : `Гигростат`"
                        :disabled="true"
                      />
                    </div>
                    <div class="zone__subblock">
                      <Input
                        :type="'text'"
                        :value="elem.addr"
                        :disabled="true"
                      />
                    </div>
                    <Button
                      :type="'action'"
                      :value="'delete'"
                      :paint="'gray'"
                      @click.stop="handleZone($event, 'delete')"
                    />
                  </div>
                </div>
                <div class="zone__block" data-block="nozzles">
                  <div class="zone__subblock" data-part="first">
                    <div class="zone__title">Тип форсунок</div>
                    <Dropdown
                      :type="'simple'"
                      :items="zone.nozzle.dropdown.categories"
                      :name="zone.nozzle.dropdown.name"
                      :value="zone.nozzle.dropdown.value"
                      :status="zone.nozzle.dropdown.status"
                      @click="handleZone($event, 'dropdown')"
                    />
                  </div>
                  <div class="zone__subblock" data-part="last">
                    <div class="zone__title">Количество</div>
                    <Input
                      :type="'text'"
                      :name="zone.nozzle.input.name"
                      :value="zone.nozzle.input.value"
                      :status="zone.nozzle.input.status"
                      @input="handleZone($event, 'input')"
                      @change="handleZone($event, 'input')"
                    />
                  </div>
                  <Button
                    :type="'action'"
                    :value="'add'"
                    :paint="'gray'"
                    :disabled="zone.nozzle.button.disabled"
                    @click.stop="handleZone($event, 'add')"
                  />
                </div>
                <div
                  class="zone__modules"
                  v-if="zone.nozzles.length > 0"
                  data-modules="nozzles"
                >
                  <div
                    class="zone__module"
                    v-for="(elem, index) in zone.nozzles"
                    :data-id="index"
                    :key="index"
                  >
                    <div class="zone__subblock">
                      <Input
                        :type="'text'"
                        :value="elem.type"
                        :disabled="true"
                      />
                    </div>
                    <div class="zone__subblock">
                      <Input
                        :type="'text'"
                        :value="elem.quan"
                        :disabled="true"
                      />
                    </div>
                    <Button
                      :type="'action'"
                      :value="'delete'"
                      :paint="'gray'"
                      @click.stop="handleZone($event, 'delete')"
                    />
                  </div>
                </div>
                <div class="zone__control">
                  <Button
                    :type="'text'"
                    :value="'Отмена'"
                    :paint="'gray'"
                    :disabled="zone.cancellation"
                    @click="cancelChanges($event, 'zone')"
                  />
                  <Button
                    :type="'text'"
                    :value="'Сохранить'"
                    :paint="'blue'"
                    :disabled="!zone.validation"
                    @click="changeZone"
                  />
                </div>
              </div>
              <div
                class="zone__arrow"
                @click="collapseZone($event, zone.extended)"
              >
                <Chevron :orient="zone.extended ? 'up' : 'down'" />
              </div>
            </div>
            <div class="system__zones-footer">
              <Button
                :type="'text'"
                :value="'Удалить зону'"
                :paint="'gray'"
                :disabled="deleting"
                @click="deleteZone"
              />
              <Button
                :type="'text'"
                :value="'Добавить зону'"
                :paint="'blue'"
                @click="addZone"
              />
            </div>
          </div>
        </div>
        <div class="system__tasks" v-if="navbar.current === 2">
          <div class="system__tasks-data">
            <perfect-scrollbar>
              <table class="system__tasks-table">
                <thead class="system__tasks-thead">
                  <tr class="system__tasks-row">
                    <th class="system__tasks-th">
                      <div class="system__tasks-wrapper">
                        <div class="system__tasks-content">
                          Срок
                          <button class="system__tasks-sort">
                            <span class="system__tasks-up"></span>
                            <span class="system__tasks-down"></span>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th class="system__tasks-th">
                      <div class="system__tasks-wrapper">
                        <div class="system__tasks-content">Номер задачи</div>
                      </div>
                    </th>
                    <th class="system__tasks-th">
                      <div class="system__tasks-wrapper">
                        <div class="dropdown">
                          <div class="dropdown__field">Тип задачи</div>
                          <div
                            class="dropdown__placeholder"
                            style="display: none"
                          >
                            Тип задачи
                          </div>
                          <ul class="dropdown__list" style="display: none"></ul>
                          <button class="dropdown__button">
                            <span class="dropdown__icon"></span>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th class="system__tasks-th">
                      <div class="system__tasks-wrapper">
                        <div class="system__tasks-content">Комментарий</div>
                      </div>
                    </th>
                    <th class="system__tasks-th">
                      <div class="system__tasks-wrapper">
                        <div class="system__tasks-content">
                          Исполнитель
                          <button class="system__tasks-sort">
                            <span class="system__tasks-up"></span
                            ><span class="system__tasks-down"></span>
                          </button>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="system__tasks-tbody">
                  <tr
                    class="system__tasks-row"
                    v-for="task in tasks"
                    :id="task.id"
                    :key="task.id"
                  >
                    <td class="system__tasks-td">
                      <div class="system__tasks-wrapper">{{ task.period }}</div>
                    </td>
                    <td class="system__tasks-td">
                      <div class="system__tasks-wrapper">{{ task.number }}</div>
                    </td>
                    <td class="system__tasks-td">
                      <div class="system__tasks-wrapper">{{ task.type }}</div>
                    </td>
                    <td class="system__tasks-td">
                      <div class="system__tasks-wrapper">
                        {{ task.comment }}
                      </div>
                    </td>
                    <td class="system__tasks-td">
                      <div class="system__tasks-wrapper">Иванов Иван</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
          <div class="pagination" v-if="pagination.total > pagination.limit">
            <button
              class="pagination__button"
              :data-type="'prev'"
              :disabled="!pagination.prev"
              @click="handlePagination"
            >
              <span class="pagination__button-icon"></span>
            </button>
            <ul class="pagination__pages">
              <li
                v-for="page in pagination.pages"
                :class="[
                  page.current
                    ? 'pagination__page pagination__page_current'
                    : 'pagination__page',
                ]"
                :data-id="page.id"
                :key="page.id"
                @click="handlePagination"
              >
                {{ page.value }}
              </li>
            </ul>
            <button
              class="pagination__button"
              :data-type="'next'"
              :disabled="!pagination.next"
              @click="handlePagination"
            >
              <span class="pagination__button-icon"></span>
            </button>
          </div>
        </div>
        <div class="system__settings" v-if="navbar.current === 3">
          <section class="config">
            <div class="config__header" @click="toggleSection('config')">
              <div class="config__wrapper">
                <div class="config__name">Конфигурация локальной системы</div>
              </div>
              <div class="config__control">
                <div class="config__info">
                  <div class="config__mileage">{{ about.pump_hours }} М/ч</div>
                  <div
                    :class="`config__state config__state_${about.state.value}`"
                  >
                    {{ about.state.text }}
                  </div>
                </div>
                <button
                  class="config__button"
                  @click.stop="toggleSection('config')"
                >
                  <span
                    :class="[
                      config.extended
                        ? 'config__button-icon config__button-icon_rotate'
                        : 'config__button-icon',
                    ]"
                  ></span>
                </button>
              </div>
            </div>
            <div class="config__body" v-show="config.extended">
              <div class="config__blocks">
                <div class="config__main">
                  <div
                    class="config__block"
                    v-for="param in config.params"
                    :data-id="param.id"
                    :key="param.id"
                  >
                    <div class="config__title">{{ param.title }}</div>
                    <Dropdown
                      :type="param.type"
                      :name="param.name"
                      :items="param.categories"
                      :value="param.value"
                      :status="param.status"
                      @click="handleChanges($event, param.element)"
                    />
                  </div>
                </div>
                <div class="config__password" v-if="false">
                  <div class="config__title">Пароль системы</div>
                  <Button
                    :type="'text'"
                    :value="'Сбросить пароль'"
                    :paint="'blue'"
                    @click="resetPasswordLocalSystem()"
                  />
                </div>
                <div class="config__soft">
                  <div class="config__title">Версия ПО</div>
                  <div class="config__params">
                    <div class="config__param">Центральный Блок 1.0</div>
                    <div class="config__param">Гигростаты 2.1.6</div>
                    <div class="config__param">Блок Управления Насосом 2.1</div>
                    <div class="config__param">Датчики 18.9</div>
                  </div>
                </div>
              </div>
              <div class="config__footer">
                <Button
                  :type="'text'"
                  :value="'Отмена'"
                  :paint="'gray'"
                  :disabled="config.cancellation"
                  @click="cancelChanges($event, 'config')"
                />
                <Button
                  :type="'text'"
                  :value="'Сохранить изменения'"
                  :paint="'blue'"
                  :disabled="!config.validation"
                  @click="sendChanges('config')"
                />
              </div>
            </div>
          </section>
          <div class="system__left">
            <section class="state">
              <div class="state__header" @click="toggleSection('state')">
                <div class="state__wrapper">
                  <div class="state__name">Состояние системы</div>
                </div>
                <div class="state__control">
                  <button
                    class="state__button"
                    @click.stop="toggleSection('state')"
                  >
                    <span
                      :class="[
                        state.extended
                          ? 'state__button-icon state__button-icon_rotate'
                          : 'state__button-icon',
                      ]"
                    ></span>
                  </button>
                </div>
              </div>
              <div class="state__body" v-show="state.extended">
                <div class="state__params">
                  <div class="state__block">
                    <div class="state__title">Входное давление (Бар)</div>
                    <Input
                      :type="'text'"
                      :value="state.p_in"
                      :disabled="true"
                    />
                  </div>
                  <div class="state__block">
                    <div class="state__title">Выходное давление (Бар)</div>
                    <Input
                      :type="'text'"
                      :value="state.p_out"
                      :disabled="true"
                    />
                  </div>
                  <div class="state__block">
                    <div class="state__title">Входной клапан</div>
                    <Input
                      :type="'text'"
                      :value="state.valve_in === true ? 'Открыт' : 'Закрыт'"
                      :disabled="true"
                    />
                  </div>
                  <div class="state__block">
                    <div class="state__title">Дренажный клапан</div>
                    <Input
                      :type="'text'"
                      :value="state.valve_drain === true ? 'Открыт' : 'Закрыт'"
                      :disabled="true"
                    />
                  </div>
                  <div class="state__block">
                    <div class="state__title">Текущие обороты насоса</div>
                    <Input
                      :type="'text'"
                      :value="state.pump_rpm"
                      :disabled="true"
                    />
                  </div>
                </div>
                <slot v-if="state.zones.length > 0">
                  <div class="state__subtitle">
                    Состояние работы по Зонам/Этажам
                  </div>
                  <div class="state__zones">
                    <div
                      class="state__zone"
                      v-for="block in state.zones"
                      :id="block.id"
                      :key="block.id"
                    >
                      <div class="state__zone-name">
                        Зона {{ block.num }} - {{ block.name }}
                      </div>
                      <span
                        :class="
                          block.hum.activity === true
                            ? 'state__zone-status'
                            : 'state__zone-status state__zone-status_error'
                        "
                        >{{
                          block.hum.activity === true
                            ? "Работает"
                            : "Не работает"
                        }}</span
                      >
                    </div>
                  </div>
                </slot>
                <div class="state__subtitle">Текущее состояние платы</div>
                <div class="state__board">
                  <div class="state__board-status">
                    {{ state.status }}
                  </div>
                  <slot
                    v-if="
                      state.status === 'Активный режим' ||
                      state.status === 'Пауза'
                    "
                  >
                    <Input
                      :type="'text'"
                      :value="state.timer"
                      :disabled="true"
                    />
                  </slot>
                </div>
                <div class="state__subtitle">Текущее состояние сети</div>
                <div class="state__network">
                  <slot v-if="state.network.status === 0">
                    <div
                      class="state__network-status state__network-status_disconnected"
                    >
                      Соединение отсутствует
                    </div>
                  </slot>
                  <slot v-else-if="state.network.status === 1">
                    <div
                      class="state__network-status state__network-status_connected"
                    >
                      Соединение установлено
                    </div>
                  </slot>
                  <slot v-else-if="state.network.status === 2">
                    <div
                      class="state__network-status state__network-status_connecting"
                    >
                      Соединение устанавливается
                    </div>
                  </slot>
                  <span
                    :class="`state__network-indicator state__network-indicator_${state.network.indicator}`"
                  ></span>
                </div>
                <slot
                  v-if="Array.isArray(state.errors) && state.errors.length > 0"
                >
                  <div class="state__subtitle">
                    Сообщения об ошибках системы
                  </div>
                  <div class="state__errors">
                    <div
                      class="state__error"
                      v-for="(error, index) in state.errors"
                      :key="index"
                      :data-error="error.code"
                    >
                      <div class="state__error-wrapper">
                        <span class="state__error-dot"></span>
                        <div class="state__error-info">
                          <div class="state__error-date">
                            {{ convertDate(error.time, "full") }}
                          </div>
                          <div class="state__error-text">{{ error.text }}</div>
                        </div>
                      </div>
                      <slot v-if="error.code === 12">
                        <Button
                          :type="'action'"
                          :value="'reset'"
                          :paint="'gray'"
                          @click="resetErrors"
                        />
                      </slot>
                    </div>
                  </div>
                </slot>
              </div>
            </section>
            <section class="control">
              <div class="control__header" @click="toggleSection('control')">
                <div class="control__wrapper">
                  <div class="control__name">Ручное управление</div>
                </div>
                <div class="control__control">
                  <button
                    class="control__button"
                    @click.stop="toggleSection('control')"
                  >
                    <span
                      :class="[
                        control.extended
                          ? 'control__button-icon control__button-icon_rotate'
                          : 'control__button-icon',
                      ]"
                    ></span>
                  </button>
                </div>
              </div>
              <div class="control__body" v-show="control.extended">
                <div class="control__state">
                  <span
                    :class="
                      control.manual_control == true
                        ? 'control__state-status'
                        : 'control__state-status control__state-status_error'
                    "
                  >
                    Ручное управление
                    {{
                      control.manual_control == true ? "включено" : "выключено"
                    }}</span
                  >
                  <Switch
                    :status="control.manual_control"
                    :name="'manual_control'"
                    paint="blue"
                    @change="handleChanges($event, 'switch')"
                  />
                </div>
                <div
                  :class="
                    control.manual_control == true
                      ? 'control__subjects'
                      : 'control__subjects control__subjects_inactive'
                  "
                >
                  <div class="control__screen"></div>
                  <div class="control__zones">
                    <div
                      class="control__block"
                      v-for="block in control.zones"
                      :data-id="block.id"
                      :key="block.id"
                    >
                      <div class="control__title">
                        Зона {{ block.num }} - {{ block.name }}
                      </div>
                      <Switch
                        :status="
                          control.manual_control == true
                            ? block.hum.activity
                            : false
                        "
                        :name="'activity'"
                        paint="blue"
                        @change="handleChanges($event, 'switch')"
                      />
                    </div>
                  </div>
                  <div class="control__main">
                    <div class="control__block" id="valve_in">
                      <div class="control__title">Входной клапан</div>
                      <Switch
                        :status="
                          control.manual_control == true
                            ? control.valve_in
                            : false
                        "
                        :name="'valve_in'"
                        paint="blue"
                        @change="handleChanges($event, 'switch')"
                      />
                    </div>
                    <div class="control__block" id="valve_drain">
                      <div class="control__title">Дренажный клапан</div>
                      <Switch
                        :status="
                          control.manual_control == true
                            ? control.valve_drain
                            : false
                        "
                        :name="'valve_drain'"
                        paint="blue"
                        @change="handleChanges($event, 'switch')"
                      />
                    </div>
                    <div class="control__block" id="pump">
                      <div class="control__title">Насос</div>
                      <Switch
                        :status="
                          control.manual_control == true ? control.pump : false
                        "
                        :name="'pump'"
                        paint="blue"
                        @change="handleChanges($event, 'switch')"
                      />
                    </div>
                  </div>
                </div>
                <div class="control__footer">
                  <Button
                    :type="'text'"
                    :value="'Отмена'"
                    :paint="'gray'"
                    :disabled="control.cancellation"
                    @click="cancelChanges($event, 'control')"
                  />
                  <Button
                    :type="'text'"
                    :value="'Сохранить изменения'"
                    :paint="'blue'"
                    :disabled="!control.validation"
                    @click="sendChanges('control')"
                  />
                </div>
              </div>
            </section>
          </div>
          <div class="system__right">
            <section class="features">
              <div class="features__header" @click="toggleSection('features')">
                <div class="features__wrapper">
                  <div class="features__name">Параметры системы</div>
                </div>
                <div class="features__control">
                  <button
                    class="features__button"
                    @click.stop="toggleSection('features')"
                  >
                    <span
                      :class="[
                        features.extended
                          ? 'features__button-icon features__button-icon_rotate'
                          : 'features__button-icon',
                      ]"
                    ></span>
                  </button>
                </div>
              </div>
              <div class="features__body" v-show="features.extended">
                <div class="features__blocks">
                  <div
                    class="features__block"
                    v-for="feature in features.params"
                    :id="feature.id"
                    :key="feature.id"
                  >
                    <div class="features__title">{{ feature.title }}</div>
                    <Input
                      :type="'text'"
                      :name="feature.name"
                      :value="feature.value"
                      :status="feature.status"
                      @input="handleChanges($event, 'input')"
                      @change="handleChanges($event, 'input')"
                    />
                    <span class="features__error" v-if="feature.error">{{
                      feature.error
                    }}</span>
                  </div>
                </div>
                <div class="features__footer">
                  <Button
                    :type="'text'"
                    :value="'Отмена'"
                    :paint="'gray'"
                    :disabled="features.cancellation"
                    @click="cancelChanges($event, 'features')"
                  />
                  <Button
                    :type="'text'"
                    :value="'Сохранить изменения'"
                    :paint="'blue'"
                    :disabled="!features.validation"
                    @click="sendChanges('features')"
                  />
                </div>
              </div>
            </section>
            <section class="network">
              <div class="network__header" @click="toggleSection('network')">
                <div class="network__wrapper">
                  <div class="network__name">Настройки сети</div>
                </div>
                <div class="network__control">
                  <button
                    class="network__button"
                    @click.stop="toggleSection('network')"
                  >
                    <span
                      :class="[
                        network.extended
                          ? 'network__button-icon network__button-icon_rotate'
                          : 'network__button-icon',
                      ]"
                    ></span>
                  </button>
                </div>
              </div>
              <div class="network__body" v-show="network.extended">
                <div class="network__blocks">
                  <div
                    class="network__block"
                    v-for="network in network.params"
                    :id="network.id"
                    :key="network.id"
                  >
                    <div class="network__title">{{ network.title }}</div>
                    <Input
                      :type="'text'"
                      :value="network.value"
                      :status="network.status"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </slot>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Datepicker from "@vuepic/vue-datepicker";

  import Button from "../components/Button";
  import Checkbox from "../components/Checkbox";
  import Chevron from "../components/Chevron";
  import Daypicker from "../components/Daypicker";
  import Dropdown from "../components/Dropdown";
  import Input from "../components/Input";
  import Slider from "../components/Slider";
  import Switch from "../components/Switch";
  import Timepicker from "../components/Timepicker";

  export default {
    name: "System",
    data() {
      return {
        format: "dd.MM.yyyy",
      };
    },
    components: {
      Datepicker,
      Daypicker,
      Button,
      Checkbox,
      Chevron,
      Dropdown,
      Input,
      Slider,
      Switch,
      Timepicker,
    },
    computed: {
      ...mapGetters({
        notification: "getSystemNotification",
        navbar: "getSystemNavbar",
        about: "getSystemAbout",
        hum: "getSystemHum",
        zones: "getSystemZones",
        tasks: "getSystemTasks",
        config: "getSystemConfig",
        state: "getSystemState",
        control: "getSystemControl",
        features: "getSystemFeatures",
        network: "getSystemNetwork",
        deleting: "getSystemDeleting",
        pagination: "getSystemPagination",
      }),
      date_start: {
        get() {
          if (this.$store.state.system.about.date_start.value !== null) {
            return new Date(
              this.$store.state.system.about.date_start.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_start.dispatchEvent(event);
        },
      },
      date_install: {
        get() {
          if (this.$store.state.system.about.date_install.value !== null) {
            return new Date(
              this.$store.state.system.about.date_install.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_install.dispatchEvent(event);
        },
      },
      date_warranty: {
        get() {
          if (this.$store.state.system.about.date_warranty.value !== null) {
            return new Date(
              this.$store.state.system.about.date_warranty.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_warranty.dispatchEvent(event);
        },
      },
      date_maintenance_last: {
        get() {
          if (
            this.$store.state.system.about.date_maintenance_last.value !== null
          ) {
            return new Date(
              this.$store.state.system.about.date_maintenance_last.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_maintenance_last.dispatchEvent(event);
        },
      },
      date_maintenance_plan: {
        get() {
          if (
            this.$store.state.system.about.date_maintenance_plan.value !== null
          ) {
            return new Date(
              this.$store.state.system.about.date_maintenance_plan.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_maintenance_plan.dispatchEvent(event);
        },
      },
    },
    methods: {
      ...mapMutations([
        "toggleSystemSection",
        "setSystemActiveNavbarItem",
        "setSystemActiveZone",
        "extendSystemZone",
        "collapseSystemZone",
        "handleSystemChanges",
        "handleSystemProperty",
        "handleSystemZone",
        "handleSystemPagination",
        "cancelSystemChanges",
        "createModal",
        "createPopup",
        "updateSystemAboutSocket",
        "updateSystemState",
        "updateSystemControl",
        "updateSystemZonesSocket",
        "resetSystemsState",
      ]),
      ...mapActions([
        "fetchSystem",
        "fetchSystemTasks",
        "changeSystemActiveStatus",
        "addSystemDocument",
        "resetSystemErrors",
        "sendSystemChanges",
        "changeSystemProgram",
        "changeSystemZone",
      ]),
      toggleSection(section) {
        this.toggleSystemSection(section);
      },
      toggleSubSection(event) {
        let section = event.target.closest("section");
        let className = section.classList[0];
        let sectionBody = section.querySelector(`.${className}__body`);
        let sectionButton = section.querySelector(`.${className}__button-icon`);

        if (getComputedStyle(sectionBody).display === "none") {
          sectionBody.style.display = "block";
          sectionButton.className = `${className}__button-icon ${className}__button-icon_rotate`;
        } else if (getComputedStyle(sectionBody).display === "block") {
          sectionBody.style.display = "none";
          sectionButton.className = `${className}__button-icon`;
        }
      },
      convertDate(timestamp, type) {
        if (timestamp !== null) {
          let newDate = new Date(timestamp * 1000);
          let year = newDate.getFullYear();
          let month = newDate.getMonth() + 1;
          if (month < 10) month = "0" + month;
          let day = newDate.getDate();
          if (day < 10) day = "0" + day;
          let hour = newDate.getHours();
          if (hour < 10) hour = "0" + hour;
          let min = newDate.getMinutes();
          if (min < 10) min = "0" + min;
          let sec = newDate.getSeconds();
          if (sec < 10) sec = "0" + sec;

          if (type && type === "full")
            return `${day}.${month}.${year} ${hour}:${min}:${sec}`;
          else return `${day}.${month}.${year}`;
        }
      },
      convertProgramTime(date, param) {
        if (param === "day") {
          let days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
          let day = days[date];

          return day;
        } else {
          if (date < 10) date = "0" + date;

          return date;
        }
      },
      declOfNum(number) {
        number = Math.abs(number) % 100;
        let n1 = number % 10;
        let arr = ["зона", "зоны", "зон"];

        if (number > 10 && number < 20) return `${number} ${arr[2]}`;
        if (n1 > 1 && n1 < 5) return `${number} ${arr[1]}`;
        if (n1 == 1) return `${number} ${arr[0]}`;
        return `${number} ${arr[2]}`;
      },
      goBack() {
        this.$router.push("/systems");
      },
      commonClick(event) {
        if (!event.target.closest(".zone")) {
          this.resetSystemsState();
        }
      },
      setActiveItem(event) {
        let item = event.target.closest(".system__navbar-item");

        this.setSystemActiveNavbarItem(item);
      },
      changeSystemStatus(event) {
        let payload = {
          system: +event.target.closest(".system").getAttribute("data-id"),
          active: event.target.checked,
        };

        this.changeSystemActiveStatus(payload);
      },
      changeOwner() {
        let payload = {
          type: "owner",
          params: {
            owner: null,
            last_name: null,
            first_name: null,
            middle_name: null,
            phone: null,
            email: null,
            password: null,
          },
        };

        this.createModal(payload);
      },
      addDocument() {
        let payload = {
          type: "document",
          params: {
            name: null,
          },
        };

        this.createModal(payload);
      },
      downloadDocument(document) {
        window.location.href = `https://show-api.airwet.app${document.file.url}`;
      },
      deleteDocument(event) {
        let payload = {
          type: "document",
          params: {
            id: +event.target
              .closest(".documents__document")
              .getAttribute("data-id"),
            file: +event.target
              .closest(".documents__document")
              .getAttribute("data-file"),
          },
        };

        this.createPopup(payload);
      },
      resetPasswordLocalSystem() {
        let payload = {
          type: "zone",
        };

        this.createPopup(payload);
      },
      resetErrors(event) {
        let payload = {
          error: +event.target
            .closest(".state__error")
            .getAttribute("data-error"),
        };

        this.resetSystemErrors(payload);
      },
      handleChanges(event, element) {
        let payload = {};

        if (element === "datepicker") {
          payload = {
            event: event.type,
            name: event.target.getAttribute("name"),
            value: event.value,
          };
        }

        if (element === "input") {
          payload = {
            event: event.type,
            name: event.target.getAttribute("name"),
            value: event.target.value,
          };

          if (payload.name === "phone") {
            if (event.type === "paste") {
              let value = event.clipboardData.getData("text/plain");

              let pattern =
                /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
              let phoneNumbers = value.match(pattern);

              if (phoneNumbers !== null) {
                let correctNumber = phoneNumbers[0].replace(
                  pattern,
                  "+7 ($2) $3-$4-$5"
                );

                event.target.value = correctNumber;
              }
            } else if (event.type === "keydown" || event.type === "input") {
              let keyCode;
              event.keyCode && (keyCode = event.keyCode);

              if (event.target.selectionStart < 3) {
                event.target.value = `+7 ${event.data}`;
              }

              let mask = "+7 (___) ___-__-__";
              let index = 0;
              let def = mask.replace(/\D/g, "");
              let val = event.target.value.replace(/\D/g, "");
              let newVal = mask.replace(/[_\d]/g, (arg) =>
                index < val.length
                  ? val.charAt(index++) || def.charAt(index)
                  : arg
              );

              index = newVal.indexOf("_");

              if (index !== -1) {
                index < 5 && (index = 3);
                newVal = newVal.slice(0, index);
              }

              let reg = mask
                .substr(0, event.target.value.length)
                .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
                .replace(/[+()]/g, "\\$&");

              reg = new RegExp(`^${reg}$`);

              if (
                !reg.test(event.target.value) ||
                event.target.value.length < 5 ||
                (keyCode > 47 && keyCode < 58)
              ) {
                event.target.value = newVal;
              }
            }

            payload.value = event.target.value;
          }
        }

        if (element === "dropdown") {
          if (event.target.closest(".dropdown__item")) {
            payload = {
              name: event.target.closest(".dropdown").getAttribute("name"),
              value: event.target
                .closest(".dropdown__item")
                .querySelector(".dropdown__title").innerHTML,
              event: "change",
            };
          } else return false;
        }

        if (element === "switch") {
          if (event.target.closest(".control__block") !== null) {
            payload = {
              value: event.target.checked,
              name: event.target.getAttribute("name"),
              id:
                event.target
                  .closest(".control__block")
                  .getAttribute("data-id") !== null
                  ? +event.target
                      .closest(".control__block")
                      .getAttribute("data-id")
                  : null,

              event: "change",
            };
          } else {
            payload = {
              value: event.target.checked,
              name: event.target.getAttribute("name"),
              event: "change",
            };
          }
        }

        payload.element = element;
        payload.section = event.target.closest("section").className;

        this.handleSystemChanges(payload);
      },
      handleProperty(event, property, param) {
        let payload = {};

        if (param === "programs") {
          if (event.target.closest(".dropdown__item")) {
            payload = {
              event: "change",
              value: event.target
                .closest(".dropdown__item")
                .getAttribute("data-name"),
            };
          }
        }

        if (param === "value") {
          payload = {
            event: event.type,
            scope: event.target.closest(".slider").getAttribute("data-scope"),
            value: +event.target.value,
          };
        }

        if (param === "active_srv") {
          payload = {
            event: event.type,
            value: event.target.checked,
          };
        }

        if (param === "period") {
          if (event.target.closest(".daypicker")) {
            if (event.target.closest(".daypicker__item")) {
              payload = {
                event: "change",
                type: "day",
                stage: event.target
                  .closest(".daypicker")
                  .getAttribute("data-stage"),
                value: +event.target
                  .closest(".daypicker__item")
                  .getAttribute("data-id"),
                num: +event.target
                  .closest(".daypicker__item")
                  .getAttribute("data-num"),
              };
            }
          }

          if (event.target.closest(".timepicker")) {
            if (event.target.closest(".timepicker__item")) {
              payload = {
                event: "change",
                type: "time",
                stage: event.target
                  .closest(".timepicker")
                  .getAttribute("data-stage"),
                unit: event.target
                  .closest(".timepicker__list")
                  .getAttribute("data-unit"),
                value: parseInt(
                  event.target.closest(".timepicker__item").innerHTML
                ),
              };
            }
          }
        }

        if (param === "range") {
          if (event.target.closest(".timepicker")) {
            if (event.target.closest(".timepicker__item")) {
              payload = {
                event: "change",
                id: event.target.closest(`.${property}__schedule-period`).id,
                type: "time",
                stage: event.target
                  .closest(".timepicker")
                  .getAttribute("data-stage"),
                unit: event.target
                  .closest(".timepicker__list")
                  .getAttribute("data-unit"),
                value: parseInt(
                  event.target.closest(".timepicker__item").innerHTML
                ),
              };
            }
          }
        }

        if (param === "select") {
          payload = {
            event: "change",
            value: event.target.checked,
            period: event.target.closest(".checkbox").getAttribute("data-period"),
          };
        }

        if (param === "zone") {
          payload = {
            event: event.type,
            id: +event.target.closest(`.${property}__zone`).id,
            value: +event.target.value,
            period:
              event.target.getAttribute("data-period") ||
              event.target
                .closest(".hum__zone-value")
                .querySelector(".input")
                .getAttribute("data-period"),
          };

          if (event.target.closest(".hum__zone-button")) {
            payload.event = "change";
            payload.step = event.target
              .closest(".hum__zone-button")
              .getAttribute("data-step");

            let value = parseInt(
              event.target.closest(".hum__zone-value").querySelector(".input")
                .value
            );

            if (payload.step === "increase") {
              value++;
              if (value > 58) value = 58;
            }

            if (payload.step === "decrease") {
              value--;
              if (value < 0) value = 0;
            }

            payload.value = value;
          }
        }

        payload.property = property;
        payload.param = param;

        this.handleSystemProperty(payload);
      },
      handlePagination(event) {
        let payload = {};

        if (event.target.closest(".pagination__button")) {
          payload.type = event.target.closest("button").getAttribute("data-type");
        }

        if (event.target.closest(".pagination__page")) {
          payload.page = +event.target
            .closest(".pagination__page")
            .getAttribute("data-id");
        }

        this.fetchSystemTasks(payload);
      },
      cancelChanges(event, param) {
        let payload = {
          param: param,
        };

        if (param === "zone") {
          payload.id = +event.target.closest(".zone").getAttribute("data-id");
        }

        this.cancelSystemChanges(payload);
      },
      sendChanges(payload) {
        this.sendSystemChanges(payload);
      },
      changeProgram(event) {
        let payload = {
          id: +event.target.closest(".hum__program").getAttribute("data-id"),
        };

        this.changeSystemProgram(payload);
      },
      addZone() {
        let payload = {
          type: "zone",
          params: {
            name: null,
            num: null,
            zones: this.zones,
            valve: false,
            devs: [],
            nozzles: [],
            hum: {
              id: null,
              status: false,
              set: 45,
              cur: 45,
              programs: [
                {
                  id: this.hum.programs[0].id,
                  morning: 0,
                  day: 0,
                  evening: 0,
                  night: 0,
                },
                {
                  id: this.hum.programs[1].id,
                  morning: 0,
                  day: 0,
                  evening: 0,
                  night: 0,
                },
              ],
            },
            temp: {
              id: null,
              status: false,
              set: 22,
              cur: 22,
            },
            from_system: +document
              .querySelector(".system")
              .getAttribute("data-id"),
          },
        };

        this.createModal(payload);
      },
      setActiveZone(event) {
        let payload = {
          id: +event.target.closest(".zone").getAttribute("data-id"),
        };

        this.setSystemActiveZone(payload);
        this.extendSystemZone(payload);
      },
      handleZone(event, element, param) {
        let payload = {};

        payload.event = event.type;

        if (element === "input") {
          payload.value = event.target.value;
          payload.name = event.target.getAttribute("name");
          payload.param = event.target.getAttribute("data-param");
        }

        if (element === "switch") {
          payload.value = event.target.checked;
          payload.name = event.target.getAttribute("name");
          payload.param = param;
        }

        if (element === "slider") {
          payload.value = +event.target.value;
          payload.name = event.target.getAttribute("name");
          payload.param = param;
        }

        if (element === "dropdown") {
          if (event.target.closest(".dropdown").getAttribute("name") === "num") {
            if (
              event.target.closest(".dropdown__button") ||
              event.target.closest(".dropdown__field")
            ) {
              payload = {
                event: "change",
                open: true,
                name: "num",
              };
            } else if (event.target.closest(".dropdown__item")) {
              payload = {
                name: event.target.closest(".dropdown").getAttribute("name"),
                alias: event.target
                  .closest(".dropdown__item")
                  .getAttribute("data-name"),
                value: event.target
                  .closest(".dropdown__item")
                  .querySelector(".dropdown__title").innerHTML,
                event: "change",
              };
            } else return false;
          } else {
            if (event.target.closest(".dropdown__item")) {
              payload = {
                name: event.target.closest(".dropdown").getAttribute("name"),
                alias: event.target
                  .closest(".dropdown__item")
                  .getAttribute("data-name"),
                value: event.target
                  .closest(".dropdown__item")
                  .querySelector(".dropdown__title").innerHTML,
                event: "change",
              };
            } else return false;
          }
        }

        if (element === "add") {
          payload = {
            block: event.target
              .closest(".zone__block")
              .getAttribute("data-block"),
            event: "change",
          };
        }

        if (element === "delete") {
          payload = {
            modules: event.target
              .closest(".zone__modules")
              .getAttribute("data-modules"),
            index: +event.target.closest(".zone__module").getAttribute("data-id"),
            event: "change",
          };
        }

        payload.id = +event.target.closest(".zone").getAttribute("data-id");
        payload.element = element;
        payload.system = +document
          .querySelector(".system")
          .getAttribute("data-id");

        this.extendSystemZone(payload);
        this.handleSystemZone(payload);
      },
      changeZone(event) {
        let payload = {
          zone: +event.target.closest(".zone").getAttribute("data-id"),
          system: +document.querySelector(".system").getAttribute("data-id"),
        };

        this.changeSystemZone(payload);
      },
      collapseZone(event, extended) {
        let payload = {
          id: +event.target.closest(".zone").getAttribute("data-id"),
        };

        if (extended) {
          event.stopPropagation();
          this.collapseSystemZone(payload);
        }
      },
      deleteZone() {
        let payload = {
          type: "zone",
          params: {
            zone: +document
              .querySelector(".zone_current")
              .getAttribute("data-id"),
            system: +document.querySelector(".system").getAttribute("data-id"),
          },
        };

        this.createPopup(payload);
      },
    },
    created() {
      this.fetchSystem(this.$route.params.id);
      this.fetchSystemTasks();

      this.$soketio.on(`system/${this.$route.params.id}/state`, (data) => {
        if (this.$route.params.id == data.id) {
          this.updateSystemState(data);
          this.updateSystemAboutSocket(data);
          this.updateSystemZonesSocket(data);
        }
      });

      this.$soketio.on(`system/${this.$route.params.id}/control`, (data) => {
        if (this.$route.params.id == data.id) this.updateSystemControl(data);
      });
    },
  };
</script>

<style lang="scss" scoped>
  .system {
    position: relative;
    width: 100%;

    @include mediaquery(sm) {
      width: calc(100% - 75px);
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;

      @include mediaquery(sm) {
        padding-bottom: 100px;
      }
    }

    &__navbar {
      width: 100%;
      margin-bottom: 20px;

      &-list {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
          margin-left: -5px;
          margin-right: -5px;
          margin-top: -5px;
          margin-bottom: -10px;
          width: calc(100% + 10px);
        }
      }

      &-back {
        display: flex;
        width: 100%;
        border-radius: 6px;
        border: 2px solid #41c6eb;
        background-color: #41c6eb;
        box-shadow: $shadowLight;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 10px);
        }

        .system__navbar-arrow {
          color: #41c6eb;
          background-color: #fff;
        }

        .system__navbar-name {
          color: #fff;
        }

        .system__navbar-feature {
          color: #fff;
        }
      }

      &-item {
        @include base;

        display: flex;
        width: 100%;
        border: 2px solid $colorMainLight;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;

          &:last-of-type {
            margin-bottom: 5px;
          }
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 10px);
        }

        &_current {
          border: 2px solid #41c6eb;

          .system__navbar-quantity {
            color: #41c6eb;
            background-color: #fff;
          }

          .system__navbar-name {
            color: #222325;
          }
        }
      }

      &-arrow,
      &-quantity {
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        overflow: hidden;
      }

      &-name {
        font-weight: 500;
        font-size: 14px;
        color: #9b9b9b;
        text-transform: uppercase;
      }

      &-feature {
        margin-top: 5px;
        font-size: 10px;
        color: #9b9b9b;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__tasks {
      &-data {
        @include base;
      }

      .ps {
        width: 100%;
        padding: 0 10px 10px;
      }

      &-table {
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
      }

      &-thead {
        .system__tasks-wrapper {
          min-height: 36px;
          border-right: 0;
        }
      }

      &-tbody {
        .system__tasks-row {
          &:hover {
            cursor: pointer;
            outline: 2px solid #41c6eb;
          }
        }
      }

      &-row {
        background-color: $colorMainLight;
      }

      &-th,
      &-td {
        padding: 10px 0;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        text-align: left;

        &:last-of-type {
          .system__tasks-wrapper {
            border-right: 0;
          }
        }
      }

      &-th {
        border: 0;
        padding: 10px 5px;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      &-td {
        .system__tasks-wrapper {
          padding-left: 19px;
        }

        &:first-of-type {
          .system__tasks-wrapper {
            padding-left: 15px;
          }
        }
      }

      &-wrapper {
        border-right: 1px solid #dddddd;
        font-weight: 400;
        font-size: 12px;
        color: #222325;
        display: flex;
        align-items: center;
      }

      &-content {
        @include base;

        padding: 7px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        width: 100%;
        text-align: center;
      }

      &-sort {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 15px;
        width: 11px;
        cursor: pointer;
        margin-left: 20px;
      }

      &-up {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(45deg);
      }

      &-down {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(225deg);
      }
    }

    &__info,
    &__settings {
      display: flex;
      flex-wrap: wrap;
    }

    &__left {
      width: 100%;
      margin-top: 20px;

      @include mediaquery(md) {
        width: 50%;
        padding-right: 10px;
      }
    }

    &__right {
      width: 100%;
      margin-top: 20px;

      @include mediaquery(md) {
        width: 50%;
        padding-left: 10px;
      }
    }

    &__programs {
      width: 100%;
      margin-top: 20px;
    }

    &__zones {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 5px;

      @include mediaquery(sm) {
        margin-top: -5px;
      }

      @include mediaquery(md) {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        margin-left: -5px;
        margin-right: -5px;
        margin-top: -10px;
        margin-bottom: 20px;
        width: calc(100% + 10px);
      }

      &-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: flex-end;
        }

        @include mediaquery(md) {
          padding: 0 5px;
        }

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: auto;

            &:first-of-type {
              margin-bottom: 0px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .notification {
      @include notification;

      &__title {
        font-size: 14px;
        font-weight: 700;
        color: #2b1a0a;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      &__text {
        font-size: 12px;
        color: #2b1a0a;
      }

      &__strong {
        font-weight: 700;
      }
    }

    .data,
    .statistic,
    .systems,
    .config,
    .state,
    .control,
    .features,
    .network {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        text-transform: uppercase;
      }

      &__notification {
        margin-left: 10px;
        display: flex;
        align-items: center;

        &-date {
          font-size: 9px;
          font-weight: 500;
          background-color: #41c6eb;
          color: #fff;
          border-radius: 10px;
          padding: 3px 10px;
          margin-right: 5px;
        }

        &-text {
          font-size: 10px;
          color: #41c6eb;
        }
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        button {
          &:first-of-type {
            margin-right: 10px;
          }
        }
      }
    }

    .about {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      margin-bottom: 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
        cursor: pointer;
      }

      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 65%;

        @include mediaquery(xl) {
          flex-wrap: nowrap;
          justify-content: flex-start;
          width: auto;
          flex-direction: row;
          align-items: center;
        }
      }

      &__name {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(lg) {
          width: 200px;
        }

        @include mediaquery(xl) {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__address {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(xl) {
          width: 310px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &__service {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(50% - 5px);
        }

        @include mediaquery(xl) {
          width: 150px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &__mileage {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(50% - 5px);
        }

        @include mediaquery(xl) {
          width: 150px;
          margin-bottom: 0;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include mediaquery(md) {
          flex-direction: row;
          align-items: center;
        }
      }

      &__state {
        @include state;
        @include mediaquery(md) {
          margin: 0 10px;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 15px;
        width: 35%;
        margin-left: 10px;

        @include mediaquery(sm) {
          width: 30%;
        }
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__contract {
        font-size: 12px;
        font-weight: 500;
        margin: 5px 0 11px;
      }

      &__params {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
        }
      }

      &__param {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(25% - 10px);
        }
      }

      &__owner {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 10px 0 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          justify-content: flex-start;
          align-items: flex-end;

          .about__block {
            &:nth-last-child(2) {
              margin-bottom: 0;
              margin-right: 10px;
            }

            @include mediaquery(lg) {
              width: calc(20% - 19px);
              margin-bottom: 0;
            }
          }
        }
      }

      &__contacts {
        border-bottom: 1px solid #e1e1e1;
        padding: 10px 0 20px;
        margin-bottom: 25px;

        &-headline {
          font-size: 12px;
          font-weight: 500;
        }

        &-blocks {
          display: flex;
          flex-wrap: wrap;
        }

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          align-items: flex-end;
        }
      }

      &__block {
        position: relative;
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(20% - 10px);
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__vacation {
        @include base;

        width: 100%;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-description {
          font-size: 10px;
          margin-top: 5px;
        }

        &-control {
          display: flex;
          align-items: center;
        }
      }

      &__features {
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        padding: 20px 0;
      }

      &__tabs {
        display: flex;
        justify-content: space-between;
      }

      &__feature {
        @include base;

        width: 32%;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-description {
          font-size: 10px;
          margin-top: 5px;
        }

        &-control {
          display: flex;
          align-items: center;
        }
      }

      &__documents {
        padding-top: 20px;
        display: flex;

        &-column {
          width: 50%;

          &:first-of-type {
            padding-right: 10px;
          }
        }
      }

      &__document {
        margin-bottom: 10px;
        &-wrapper {
          display: flex;
        }

        input {
          margin-right: 10px;
        }

        &-control {
          display: flex;
        }

        button {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }

      &__action {
        width: 36px;
        display: flex;
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .data {
      &__items {
        margin-top: 20px;
      }
      &__item {
        @include base;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__name {
        font-weight: 500;
        font-size: 12px;
        color: #2b1a0a;
        text-transform: uppercase;
      }

      &__description {
        font-size: 10px;
        color: #2b1a0a;
        margin-top: 5px;
      }

      &__blocks {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: -10px;
      }

      &__block {
        display: block;
        flex-direction: column;
        margin-bottom: 10px;
        width: calc(50% - 10px);
      }

      &__title {
        @include title;
      }
    }

    .statistic {
      &__filter {
        display: flex;
        align-items: flex-end;
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 15px;
        margin-top: 20px;
        width: 100%;

        .statistic__block {
          margin-right: 10px;
        }

        button {
          width: 33.3333%;
        }
      }

      &__params {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        width: calc(100% + 10px);
        margin-top: 15px;
        margin-bottom: -10px;
        margin-left: -5px;
        margin-right: -5px;

        .statistic__block {
          margin: 0 5px;
          margin-bottom: 10px;
          width: calc(33.3333% - 10px);
        }

        button {
          width: calc(33.3333% - 10px);
          margin: 0 5px;
          margin-bottom: 10px;
        }
      }

      &__block {
        width: 33.333333%;
      }

      &__title {
        @include title;
      }
    }

    .maintenance {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;
      margin-bottom: 10px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__description {
        font-size: 10px;
        margin-top: 5px;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__body {
        display: none;
      }

      &__blocks {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
      }

      &__block {
        width: calc(50% - 10px);
        margin: 5px;
      }

      &__title {
        font-size: 9px;
        margin-bottom: 6px;
        color: #9b9b9b;
      }

      &__wrapper {
        display: flex;

        input {
          margin-right: 10px;
        }
      }
    }

    .documents {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;
      margin: 20px 0;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__description {
        font-size: 10px;
        margin-top: 5px;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__body {
        display: none;
      }

      &__preview {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        margin-top: -5px;
      }

      &__new {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-title {
          font-weight: 500;
          font-size: 12px;
          margin-left: 18px;
          color: #2b1a0a;
        }
      }

      &__document {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-icon {
          width: 46px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-info {
          display: flex;
          align-items: center;
          margin-left: 17px;
        }

        &-name {
          font-size: 12px;
          font-weight: 500;
          color: #2b1a0a;
        }

        &-size {
          font-size: 10px;
          color: #2b1a0a;
          margin-left: 20px;
        }

        &-control {
          display: flex;

          button {
            margin-left: 10px;
          }
        }

        &-link {
          height: 36px;
          min-width: 36px;
          padding: 6px;
          border-radius: 6px;
          box-shadow: $shadowLight;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;

          @media (hover: hover) {
            &:hover {
              background-color: #fff;
            }
          }
        }

        &-icon {
          width: 20px;
          opacity: 0.5;
        }
      }

      &__blocks {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
      }

      &__block {
        width: calc(50% - 10px);
        margin: 5px;
      }

      &__title {
        @include title;
      }

      &__wrapper {
        display: flex;

        input {
          margin-right: 10px;
        }
      }

      &__file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        padding: 0;
        border-radius: 6px;
        box-shadow: $shadowLight;
        border: 0;
        cursor: pointer;
        overflow-x: hidden;

        &-input {
          position: absolute;
          width: 0;
          height: 0;
          opacity: 0;
          overflow: hidden;
          z-index: -1;
        }

        &-icon {
          width: 16px;
        }
      }
    }

    .systems {
      width: 100%;
      margin-top: 20px;
    }

    .config {
      &__main {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          margin-bottom: -5px;
        }

        @include mediaquery(md) {
          width: auto;
          margin-top: -5px;
          margin-left: -5px;
          margin-right: -5px;
        }

        @include mediaquery(xl) {
          width: 75%;
          margin: 0;
          justify-content: space-between;
        }
      }

      &__soft {
        width: 100%;
        margin-top: 20px;

        @include mediaquery(md) {
          width: 50%;
        }

        @include mediaquery(xl) {
          padding-left: 20px;
          margin-top: 0;
          width: 25%;
        }
      }

      &__wrapper {
        display: flex;
        width: 65%;

        @include mediaquery(md) {
          width: auto;
          flex-direction: row;
          align-items: center;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include mediaquery(md) {
          flex-direction: row;
          align-items: center;
        }
      }

      &__mileage {
        font-size: 12px;
        font-weight: 500;
      }

      &__state {
        @include state;

        @include mediaquery(md) {
          margin: 0 0 0 10px;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 35%;
        margin-left: 10px;

        @include mediaquery(sm) {
          width: 25%;
        }
      }

      &__params {
        display: flex;
        flex-wrap: wrap;
      }

      &__password {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;
      }

      &__param {
        width: 49%;
        margin-bottom: 5px;
        font-size: 10px;
        color: #222325;
      }

      &__blocks {
        margin-top: 20px;

        @include mediaquery(xl) {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
        }
      }

      &__block {
        width: 100%;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
          margin: 5px;
        }

        @include mediaquery(xl) {
          width: calc(20% - 10px);
          margin: 0;
        }
      }

      &__title {
        @include title;
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .state {
      margin-bottom: 20px;

      &__params {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          margin-bottom: -5px;
        }
      }

      &__block {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(sm) {
          margin: 5px;
          width: calc(50% - 10px);
        }

        @include mediaquery(md) {
          width: 100%;
        }

        @include mediaquery(lg) {
          margin: 5px;
          width: calc(50% - 10px);
        }
      }

      &__title {
        @include title;
      }

      &__subtitle {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        margin: 18px 0 15px;
      }

      &__zones {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          margin: 0;
        }
      }

      &__zone {
        @include base;

        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(md) {
          width: 100%;
          margin: 0;
          margin-bottom: 10px;
        }

        @include mediaquery(lg) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        &-name {
          font-size: 12px;
          color: #2b1a0a;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-status {
          font-size: 9px;
          font-weight: 500;
          background-color: #41c6eb;
          color: #fff;
          border-radius: 10px;
          padding: 3px 10px;
          margin-left: 10px;
          min-width: 75px;
          text-align: center;

          &_error {
            background-color: #ff8068;
          }
        }
      }

      &__board {
        @include base;

        width: 100%;
        min-height: 46px;
        margin-bottom: 10px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-status {
          font-size: 9px;
          font-weight: 500;
          background-color: #41c6eb;
          color: #fff;
          border-radius: 10px;
          padding: 3px 10px;
          margin-right: 10px;
        }

        input {
          width: 75px;
          height: 26px;
        }
      }

      &__network {
        @include base;

        width: 100%;
        min-height: 46px;
        margin-bottom: 10px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-status {
          font-size: 9px;
          font-weight: 500;
          color: #fff;
          border-radius: 10px;
          padding: 3px 10px;
          margin-right: 10px;

          &_connecting {
            background-color: #ccc;
          }

          &_connected {
            background-color: #41c6eb;
          }

          &_disconnected {
            background-color: #ff8068;
          }
        }

        &-indicator {
          width: 13px;
          min-width: 13px;
          height: 13px;
          border-radius: 50%;

          &_default {
            background-color: #26ba11;
          }

          &_blink {
            background-color: #31f015;
          }

          &_off {
            background-color: #d2151c;
          }
        }
      }

      &__errors {
        display: flex;
        flex-wrap: wrap;
      }

      &__error {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-dot {
          background-color: #ff5e3f;
          width: 4px;
          height: 4px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 9px;
        }

        &-date {
          font-size: 9px;
          color: #000000;
        }

        &-text {
          font-size: 12px;
          color: #ff5e3f;
          text-transform: uppercase;
        }
      }
    }

    .control {
      &__state {
        @include base;

        width: 100%;
        min-height: 46px;
        margin-top: 10px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-status {
          font-size: 9px;
          font-weight: 500;
          background-color: #41c6eb;
          color: #fff;
          border-radius: 10px;
          padding: 3px 10px;

          &_error {
            background-color: #ff8068;
          }
        }
      }

      &__subjects {
        position: relative;

        &_inactive {
          .control__screen {
            z-index: 100;
          }

          .control__block {
            opacity: 0.5;
          }
        }
      }

      &__screen {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      &__zones {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 10px;
        padding-bottom: 30px;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
        }

        @include mediaquery(md) {
          margin: 0;
          margin-top: 10px;
        }

        @include mediaquery(lg) {
          margin-left: -5px;
          margin-right: -5px;
        }

        @include mediaquery(xl) {
          .control__block {
            width: calc(50% - 10px);
          }
        }
      }

      &__main {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-top: 30px;
        border-top: 1px solid #e5e5e5;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          justify-content: space-between;
        }

        @include mediaquery(md) {
          margin: 0;
        }

        @include mediaquery(lg) {
          margin-left: -5px;
          margin-right: -5px;
        }
      }

      &__block {
        @include base;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        width: 100%;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(md) {
          width: 100%;
          margin: 0;
          margin-bottom: 10px;
        }

        @include mediaquery(lg) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(xl) {
          width: calc(33.333333% - 10px);

          &:last-of-type {
            margin-bottom: 5px;
          }
        }

        .switch {
          margin-left: 10px;
        }
      }

      &__title {
        font-size: 12px;
        color: #2b1a0a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }

        @include mediaquery(md) {
          flex-direction: column;

          button {
            width: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }

        @include mediaquery(lg) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .features {
      margin-bottom: 20px;

      &__blocks {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          margin-bottom: -5px;
        }
      }

      &__block {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(md) {
          width: 100%;
        }

        @include mediaquery(lg) {
          width: calc(50% - 10px);
          margin: 5px;
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }

        @include mediaquery(md) {
          flex-direction: column;

          button {
            width: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }

        @include mediaquery(lg) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }
    }

    .network {
      &__blocks {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          margin-bottom: -5px;
        }
      }

      &__block {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(md) {
          width: 100%;
        }

        @include mediaquery(lg) {
          width: calc(50% - 10px);
          margin: 5px;
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }

        @include mediaquery(md) {
          flex-direction: column;

          button {
            width: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }

        @include mediaquery(lg) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .hum,
    .temp {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 85%;

        @include mediaquery(md) {
          width: 50%;
        }

        @include mediaquery(lg) {
          width: 85%;
          flex-direction: row;
        }
      }

      &__list {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(lg) {
          width: 200px;
          margin-bottom: 0;
        }
      }

      &__period {
        width: 100%;
        display: flex;
        flex-direction: column;
        cursor: auto;

        @include mediaquery(lg) {
          width: auto;
          flex-direction: row;
          margin-left: 10px;
        }
      }

      &__start,
      &__stop {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #222325;
        background-color: $colorMainLight;
        border: 1px solid #dcdcdc;
        border-radius: 6px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(lg) {
          margin-right: 10px;
          margin-bottom: 0;

          &:last-of-type {
            margin-right: 0;
          }
        }

        &-strong {
          font-weight: 500;
          margin-right: 3px;
        }

        &-date {
          margin-left: 10px;
          text-transform: uppercase;
          color: #fff;
          padding: 5px 15px;
          border-radius: 15px;
          font-size: 10px;
          text-align: center;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
        width: 15%;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__program {
        padding: 15px 0 10px;
        display: flex;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
        }
      }

      &__column {
        width: 100%;

        @include mediaquery(sm) {
          &:first-of-type {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
          }
        }

        @include mediaquery(md) {
          &:first-of-type {
            flex-direction: column;
            min-width: 200px;
            max-width: 200px;
            margin-right: 10px;
            margin-bottom: 0;
          }

          &:last-of-type {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 210px);
          }
        }
      }

      &__schedule {
        @include base;

        padding: 10px;
        width: 100%;

        @include mediaquery(sm) {
          width: 50%;
        }

        @include mediaquery(md) {
          width: 100%;
        }

        &-headline {
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
          margin-bottom: 13px;
        }

        &-period {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &[data-period="morning"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #8bd341;
            }
            .timepicker {
              background-color: #8bd341;
            }
          }

          &[data-period="day"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #ff6eda;
            }
            .timepicker {
              background-color: #ff6eda;
            }
          }

          &[data-period="evening"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #7976ff;
            }
            .timepicker {
              background-color: #7976ff;
            }
          }

          &[data-period="night"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #3a95ff;
            }
            .timepicker {
              background-color: #3a95ff;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-title {
          font-size: 12px;
          font-weight: 500;
        }

        &-range {
          display: flex;

          .timepicker {
            color: #fff;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }

      &__settings {
        @include base;

        padding: 10px;
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        @include mediaquery(sm) {
          width: 50%;
          margin: 0;
          margin-left: 10px;
        }

        @include mediaquery(md) {
          width: 100%;
          margin: 0;
          margin-top: 10px;
        }

        &-setting {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-wrapper {
          display: flex;
        }

        &-stage {
          font-weight: 500;
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
        }
      }

      &__zones {
        @include base;

        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        padding-bottom: 0;
        min-height: 191.5px;
        width: 100%;
      }

      &__select {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 5px;

        .checkbox {
          height: 28.5px;
          margin-bottom: 10px;
        }
      }

      .ps {
        display: flex;
        width: calc(100% - 55px);
        padding: 5px;
        padding-bottom: 10px;
      }

      &__zone {
        margin-left: 10px;
        width: 100px;
        min-width: 100px;

        &:first-of-type {
          margin-left: 0;
        }

        &-title {
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
          margin-bottom: 13px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-value {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          user-select: none;
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-button {
          position: absolute;
          border: 1px solid #bcbcbc;
          color: #bcbcbc;
          border-radius: 2px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          transition: color 0.3s, border-color 0.3s;

          &:hover {
            border-color: #222325;
            color: #222325;
          }

          &:first-of-type {
            left: 7px;
          }

          &:last-of-type {
            right: 7px;
          }
        }

        &-minus {
          height: 2px;
          width: 10px;
          background-color: #bcbcbc;
        }

        &-plus {
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: 4px;
            display: block;
            width: 10px;
            height: 2px;
            background: #bcbcbc;
          }

          &::before {
            transform: rotate(0deg);
          }

          &::after {
            transform: rotate(90deg);
          }
        }

        input {
          text-align: center;
          height: 28.5px;
          padding: 6px 14px;
          margin-bottom: 10px;
          font-weight: 500;

          &:last-of-type {
            margin-bottom: 0;
          }

          &[data-period="morning"] {
            color: #8bd341;
            -webkit-text-fill-color: #8bd341;
          }

          &[data-period="day"] {
            color: #ff6eda;
            -webkit-text-fill-color: #ff6eda;
          }

          &[data-period="evening"] {
            color: #7976ff;
            -webkit-text-fill-color: #7976ff;
          }

          &[data-period="night"] {
            color: #3a95ff;
            -webkit-text-fill-color: #3a95ff;
          }
        }
      }

      &__tuner {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 15px 0;

        @include mediaquery(sm) {
          margin: 10px 0;
        }

        &-period,
        &-common {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include mediaquery(sm) {
            justify-content: flex-start;
          }
        }

        &-period {
          margin-bottom: 15px;

          @include mediaquery(md) {
            margin-bottom: 10px;
          }
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-title {
          font-weight: 500;
          font-size: 10px;
          color: #222325;
          text-transform: uppercase;

          @include mediaquery(sm) {
            min-width: 120px;
            margin-right: 10px;
          }
        }

        &-value {
          font-size: 12px;
          font-weight: 500;
          margin-left: 5px;
          color: #bcbcbc;
          min-width: 35px;
          text-align: right;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        @include mediaquery(sm) {
          flex-direction: row;
        }

        &-control {
          display: flex;
          flex-direction: column;
          width: 100%;

          @include mediaquery(sm) {
            flex-direction: row;
            justify-content: flex-end;
          }

          button {
            width: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }

            @include mediaquery(sm) {
              width: auto;
              margin-bottom: 0;

              &:first-of-type {
                margin-right: 10px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .hum {
      .hum__start-date,
      .hum__stop-date {
        background-color: $colorHum;
        min-width: 45px;
      }
    }

    .temp {
      .temp__start-date,
      .temp__stop-date {
        background-color: $colorTemp;
      }
    }

    .zone {
      @include base;

      width: 100%;
      border: 2px solid $colorMainLight;
      padding: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include mediaquery(md) {
        width: calc(50% - 10px);
        margin: 5px;
      }

      @include mediaquery(xl) {
        width: calc(33.333333% - 10px);
      }

      @include mediaquery(hg) {
        width: calc(25% - 10px);
      }

      &_current {
        border: 2px solid #41c6eb;
      }

      &__body {
        position: relative;
      }

      &__name {
        color: #222325;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;

        &-input {
          border: 1px solid transparent;
          border-radius: 6px;
          background: none;
          font-family: inherit;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px;

          &:hover {
            border: 1px solid #dcdcdc;
          }

          &:focus {
            border: 1px solid #41c6eb;
          }

          &_valid {
            border-color: #41c6eb;
          }

          &_invalid {
            border-color: #ff8068;
          }
        }
      }

      &__program {
        display: flex;
        justify-content: center;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 12px;

        &-range {
          margin-left: 5px;
        }

        &[data-period="morning"] {
          .zone__program-title,
          .zone__program-range {
            color: #8bd341;
          }
        }

        &[data-period="day"] {
          .zone__program-title,
          .zone__program-range {
            color: #ff6eda;
          }
        }

        &[data-period="evening"] {
          .zone__program-title,
          .zone__program-range {
            color: #7976ff;
          }
        }

        &[data-period="night"] {
          .zone__program-title,
          .zone__program-range {
            color: #3a95ff;
          }
        }
      }

      &__info {
        display: flex;
        margin-top: 15px;
      }

      &__caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 15px;
        color: #363636;
        font-size: 11px;
        font-weight: 500;

        &-line {
          background: #363636;
          border: 0;
          width: 100%;
          height: 1px;
          margin: 7px 0;
        }
      }

      &__indicators {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
      }

      &__indicator {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        box-shadow: $shadowLight;
        color: #fff;
        padding: 5px 10px;
        flex-grow: 1;
        width: calc(33.333333% - 10px);
        min-width: calc(33.333333% - 10px);
        min-height: 75px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &[data-param="temp"] {
          background-color: $colorTemp;
        }

        &[data-param="hum"] {
          background-color: $colorHum;
        }

        &[data-param="carb"] {
          background-color: $colorCarb;

          .zone__set,
          .zone__cur {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
          }

          .zone__set-unit,
          .zone__cur-unit {
            font-size: 7px;
            margin-top: -3px;
          }
        }
      }

      &__set {
        font-size: 19px;
        font-weight: 500;
        margin-top: 6px;
        white-space: nowrap;

        svg {
          visibility: hidden;
        }

        &-dash {
          font-weight: 400;

          &:not(:first-of-type) {
            margin-left: 3px;
          }

          ~ .zone__set-unit {
            visibility: hidden;
          }
        }
      }

      &__cur {
        display: flex;
        align-items: center;
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 6px;

        &-wrapper {
          white-space: nowrap;
        }

        &-value {
          margin-left: 2px;
        }
      }

      &__led {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 5px;
        right: 5px;

        &_on {
          background: linear-gradient(212.01deg, #dfffbe 13.85%, #9eff3d 82.31%);
        }

        &_off {
          background: linear-gradient(208.61deg, #fc4f4f 0.59%, #ce0909 92.94%);
          background: none;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      &__params {
        display: flex;
        flex-direction: column;
      }

      &__param {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        &[data-param="hum"] {
          .zone__set {
            color: $colorHum;
          }
        }

        &[data-param="temp"] {
          .zone__set {
            color: $colorTemp;
          }
        }

        &[data-param="carb"] {
          .zone__set {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
            color: $colorCarb;
          }

          .zone__set-unit {
            font-size: 7px;
            margin-top: -3px;
          }
        }
      }

      &__tuner {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        .slider {
          width: 100%;
          margin: 4px 0;
        }
      }

      &__icon {
        width: 28px;
        margin-left: 10px;
        margin-bottom: -3px;
      }

      &__block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 10px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      &__modules {
        display: flex;
        flex-wrap: wrap;
      }

      &__module {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__title {
        @include title;
      }

      &__subblock {
        position: relative;

        &:first-of-type {
          width: calc(50% - 5px);
        }
        &:last-of-type {
          width: calc(50% - 51px);
        }
      }

      &__control {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: space-between;
        }

        .button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: calc(50% - 5px);

            &:first-of-type {
              margin-bottom: 0px;
            }
          }
        }
      }

      &__arrow {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      &__button {
        @include base;

        position: relative;
        height: 36px;
        min-width: 36px;
        padding: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          .pagination__button-icon {
            transform: rotate(135deg);
          }
        }

        &:last-child {
          .pagination__button-icon {
            transform: rotate(-45deg);
          }
        }

        &:hover {
          .pagination__button-icon {
            border-color: #bcbcbc;
          }
        }

        &:disabled {
          cursor: auto;
          pointer-events: none;

          .pagination__button-icon {
            border-color: #9b9b9b;
          }
        }

        &-icon {
          width: 7px;
          height: 7px;
          border: solid #000;
          border-width: 0 2px 2px 0;
        }
      }

      &__pages {
        margin: 0;
        padding: 0 10px;
        list-style: none;
        display: flex;
        align-items: center;
      }

      &__page {
        font-size: 14px;
        margin: 0 5px;
        color: #9b9b9b;
        cursor: pointer;

        &:hover {
          color: #474747;
        }

        &_current {
          color: #000;
        }
      }
    }
  }
</style>
import {
  createStore
} from 'vuex';

import authorization from './authorization';
import common from './common';
import customers from './customers';
import messenger from './messenger';
import objects from './objects';
import profile from './profile';
import promo from './promo';
import recover from './recover';
import register from './register';
import reports from './reports';
import reset from './reset';
import system from './system';
import systems from './systems';

export const store = createStore({
  modules: {
    authorization,
    common,
    customers,
    messenger,
    objects,
    profile,
    promo,
    recover,
    register,
    reports,
    reset,
    system,
    systems,
  },
})
<template>
  <main class="profile">
    <div class="profile__content">
      <div class="notification" v-if="notification.visible">
        <div class="notification__info">
          <div class="notification__title">{{ notification.title }}</div>
          <div class="notification__text">{{ notification.text }}</div>
        </div>
        <div class="notification__control" v-if="notification.control">
          <button class="notification__ok" @click="hideNotification">Ок</button>
        </div>
      </div>
      <section class="about">
        <div class="about__header" @click="toggleSection('about')">
          <div class="about__main">
            <div class="about__avatar">
              <slot v-if="about.avatar">
                <img
                  class="about__avatar-picture"
                  :src="`https://show-api.airwet.app${about.avatar.url}`"
                  alt=""
                />
                <button
                  class="about__avatar-delete"
                  @click.stop="deleteAvatar(about.avatar.id)"
                >
                  <span class="about__avatar-action">
                    <font-awesome-icon icon="fa-xmark" />
                  </span>
                </button>
              </slot>
              <slot v-else>
                <label class="about__file" @change.stop="addAvatar">
                  <input class="about__file-input" type="file" name="avatar" />
                  <span class="about__file-action">
                    <font-awesome-icon icon="fa-plus" />
                  </span>
                </label>
              </slot>
            </div>
            <div class="about__block">
              <div class="about__title">ФИО владельца</div>
              <Input
                :type="'text'"
                :value="`${about.last_name} ${about.first_name} ${about.middle_name}`"
                :disabled="true"
              />
            </div>
          </div>
          <div class="about__control">
            <button class="about__button" @click.stop="toggleSection('about')">
              <span
                :class="[
                  about.extended
                    ? 'about__button-icon about__button-icon_rotate'
                    : 'about__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="about__body" v-show="about.extended">
          <div class="about__owner">
            <div class="about__block">
              <div class="about__title">Телефон</div>
              <Input :value="about.phone.value" :disabled="true" />
            </div>
            <div class="about__block">
              <div class="about__title">Электронная почта</div>
              <Input :value="about.email.value" :disabled="true" />
            </div>
            <div class="about__block">
              <div class="about__title">Эл.почта доп.</div>
              <Input
                :type="about.email_add.type"
                :name="about.email_add.name"
                :value="about.email_add.value"
                :status="about.email_add.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="about__error" v-if="about.email_add.error">{{
                about.email_add.error
              }}</span>
            </div>
            <div class="about__block">
              <div class="about__title">Комментарий</div>
              <Input
                :type="about.comment.type"
                :name="about.comment.name"
                :value="about.comment.value"
                :status="about.comment.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="about__error" v-if="about.comment.error">{{
                about.comment.error
              }}</span>
            </div>
            <div class="about__action">
              <Button
                :type="'action'"
                :value="'add'"
                :paint="'gray'"
                @click="addContact"
              />
            </div>
          </div>
          <div
            class="about__contacts"
            v-if="about.contacts && about.contacts.length > 0"
          >
            <div
              class="about__contact"
              v-for="contact in about.contacts"
              :data-id="contact.id"
              :key="contact.id"
            >
              <div class="about__block">
                <div class="about__title">Фамилия</div>
                <Input
                  :type="'text'"
                  :name="'last_name'"
                  :value="contact.last_name.value"
                  :status="contact.last_name.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.last_name.error">{{
                  contact.last_name.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Имя</div>
                <Input
                  :type="'text'"
                  :name="'first_name'"
                  :value="contact.first_name.value"
                  :status="contact.first_name.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.first_name.error">{{
                  contact.first_name.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Отчество</div>
                <Input
                  :type="'text'"
                  :name="'middle_name'"
                  :value="contact.middle_name.value"
                  :status="contact.middle_name.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.middle_name.error">{{
                  contact.middle_name.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Комментарий</div>
                <Input
                  :type="'text'"
                  :name="'comment'"
                  :value="contact.comment.value"
                  :status="contact.comment.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.comment.error">{{
                  contact.comment.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Телефон</div>
                <Input
                  :type="'tel'"
                  :name="'phone'"
                  :value="contact.phone.value"
                  :status="contact.phone.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.phone.error">{{
                  contact.phone.error
                }}</span>
              </div>
              <div class="about__block">
                <div class="about__title">Электронная почта</div>
                <Input
                  :type="'email'"
                  :name="'email'"
                  :value="contact.email.value"
                  :status="contact.email.status"
                  @focus="handleChanges"
                  @paste="handleChanges"
                  @input="handleChanges"
                  @change="handleChanges"
                />
                <span class="about__error" v-if="contact.email.error">{{
                  contact.email.error
                }}</span>
              </div>
              <div class="about__action">
                <Button
                  :type="'action'"
                  :value="'delete'"
                  :paint="'gray'"
                  @click="deleteContact"
                />
              </div>
            </div>
          </div>
          <div class="about__footer">
            <Button
              :type="'text'"
              :value="'Отмена'"
              :paint="'gray'"
              :disabled="about.cancellation"
              @click="cancelChanges('about')"
            />
            <Button
              :type="'text'"
              :value="'Сохранить изменения'"
              :paint="'blue'"
              :disabled="!about.validation"
              @click="sendChanges('about')"
            />
          </div>
        </div>
      </section>
      <section
        class="systems"
        v-if="user.role === 'user' && systems.list.length > 0"
      >
        <div class="systems__header" @click="toggleSection('systems')">
          <div class="systems__main">
            <div class="systems__headline">Мои системы</div>
            <span class="systems__badge">{{ systems.list.length }}</span>
          </div>
          <div class="systems__control">
            <button
              class="systems__button"
              @click.stop="toggleSection('systems')"
            >
              <span
                :class="[
                  systems.extended
                    ? 'systems__button-icon systems__button-icon_rotate'
                    : 'systems__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="systems__body" v-show="systems.extended">
          <div class="systems__list">
            <div
              class="systems__system"
              v-for="system in systems.list"
              :id="system.id"
              :key="system.id"
              @click="goToObject"
            >
              <div class="systems__info">
                <div class="systems__name">
                  {{ system.name }} {{ system.contract }}
                </div>
                <div class="systems__address">
                  {{ system.address }}
                </div>
              </div>
              <div class="systems__wrapper">
                <div
                  :class="`systems__state systems__state_${system.state.value}`"
                >
                  {{ system.state.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pay" v-if="false">
        <div class="pay__header" @click="toggleSection('pay')">
          <div class="pay__main">
            <div class="pay__headline">Способ оплаты</div>
            <span class="pay__badge">{{ pay.length }}</span>
          </div>
          <div class="pay__control">
            <button class="pay__button" @click.stop="toggleSection('pay')">
              <span
                :class="[
                  pay.extended
                    ? 'pay__button-icon pay__button-icon_rotate'
                    : 'pay__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="pay__body" v-show="pay.extended">
          <div class="pay__preview">
            <div
              class="pay__card"
              v-for="card in pay"
              :data-id="card.id"
              :key="card.id"
            >
              <div class="pay__card-wrapper">
                <div class="pay__card-system">
                  <img
                    class="pay__card-icon"
                    :src="`https://show-api.airwet.app/images/cards/${card.system}.svg`"
                  />
                </div>
                <div class="pay__card-props">
                  <div class="pay__card-info">
                    <div class="pay__card-number">{{ card.number }}</div>
                    <div class="pay__card-holder">{{ card.holder }}</div>
                  </div>
                  <div class="pay__card-expire">
                    {{ card.month }}/{{ card.year }}
                  </div>
                </div>
              </div>
              <Button
                :type="'action'"
                :value="'delete'"
                :paint="'gray'"
                @click="deleteCard"
              />
            </div>
            <div class="pay__new">
              <div class="pay__new-title">Добавить карту</div>
              <Button
                :type="'action'"
                :value="'add'"
                :paint="'gray'"
                @click="addCard"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="payments" v-if="false">
        <div class="payments__header">
          <div class="payments__main">
            <div class="payments__headline">История платежей</div>
            <span class="payments__badge">{{ payments.badge }}</span>
          </div>
          <div class="payments__control">
            <button class="payments__button">
              <span class="payments__button-icon"></span>
            </button>
          </div>
        </div>
        <div class="payments__body">
          <div class="payments__preview">
            <div class="payments__payment">
              <div class="payments__block">
                <div class="payments__title">Тип платежа</div>
                <Input
                  :type="'text'"
                  :value="'Техническое Обслуживание №3'"
                  :disabled="true"
                />
              </div>
              <div class="payments__block">
                <div class="payments__title">Сумма платежа</div>
                <Input
                  :type="'text'"
                  :value="'1 389 руб. 09 коп.'"
                  :disabled="true"
                />
              </div>
              <div class="payments__action">
                <Button
                  :type="'text'"
                  :value="'Оплатить'"
                  :paint="'blue'"
                  @click="makePayment"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="appearance" v-if="false">
        <div class="appearance__header" @click="toggleSection('appearance')">
          <div class="appearance__main">
            <div class="appearance__headline">Настройка интерфейса</div>
          </div>
          <div class="appearance__control">
            <button class="appearance__button">
              <span
                :class="[
                  appearance.extended
                    ? 'appearance__button-icon appearance__button-icon_rotate'
                    : 'appearance__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="appearance__body" v-show="appearance.extended">
          <div class="appearance__blocks">
            <div class="appearance__block">
              <Switch
                :paint="'dark'"
                :name="'theme'"
                :status="appearance.dark"
                @change="handleChanges"
              />
              <div class="appearance__title">Темная тема</div>
            </div>
          </div>
          <div class="appearance__footer">
            <Button
              :type="'text'"
              :value="'Отмена'"
              :paint="'gray'"
              :disabled="appearance.cancellation"
              @click="cancelChanges('appearance')"
            />
            <Button
              :type="'text'"
              :value="'Сохранить изменения'"
              :paint="'blue'"
              :disabled="!appearance.validation"
              @click="sendChanges('appearance')"
            />
          </div>
        </div>
      </section>
      <section class="access">
        <div class="access__header" @click="toggleSection('access')">
          <div class="access__main">
            <div class="access__headline">Настройка доступа</div>
          </div>
          <div class="access__control">
            <button
              class="access__button"
              @click.stop="toggleSection('access')"
            >
              <span
                :class="[
                  access.extended
                    ? 'access__button-icon access__button-icon_rotate'
                    : 'access__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="access__body" v-show="access.extended">
          <div class="access__blocks">
            <div class="access__block">
              <div class="access__title">Введите старый пароль</div>
              <Input
                :type="access.password_old.type"
                :name="access.password_old.name"
                :value="access.password_old.value"
                :status="access.password_old.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="access__error" v-if="access.password_old.error">{{
                access.password_old.error
              }}</span>
            </div>
            <div class="access__block">
              <div class="access__title">Введите новый пароль</div>
              <Input
                :type="access.password_new.type"
                :name="access.password_new.name"
                :value="access.password_new.value"
                :status="access.password_new.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="access__error" v-if="access.password_new.error">{{
                access.password_new.error
              }}</span>
            </div>
            <div class="access__block">
              <div class="access__title">Подтвердите пароль</div>
              <Input
                :type="access.password_confirm.type"
                :name="access.password_confirm.name"
                :value="access.password_confirm.value"
                :status="access.password_confirm.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span
                class="access__error"
                v-if="access.password_confirm.error"
                >{{ access.password_confirm.error }}</span
              >
            </div>
          </div>
          <div class="access__footer">
            <Button
              :type="'text'"
              :value="'Отмена'"
              :paint="'gray'"
              :disabled="access.cancellation"
              @click="cancelChanges('access')"
            />
            <Button
              :type="'text'"
              :value="'Сохранить изменения'"
              :paint="'blue'"
              :disabled="!access.validation"
              @click="sendChanges('access')"
            />
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Button from "../components/Button";
  import Input from "../components/Input";
  import Switch from "../components/Switch";

  export default {
    name: "Profile",
    components: {
      Button,
      Input,
      Switch,
    },
    computed: {
      ...mapGetters({
        user: "getAuthorizationUser",
        notification: "getProfileNotification",
        about: "getProfileAbout",
        systems: "getProfileSystems",
        pay: "getProfilePay",
        payments: "getProfilePayments",
        appearance: "getProfileAppearance",
        access: "getProfileAccess",
      }),
    },
    methods: {
      ...mapMutations([
        "hideProfileNotification",
        "toggleProfileSection",
        "handleProfileChanges",
        "cancelProfileChanges",
        "createModal",
        "createPopup",
        "changeTheme",
        "setTheme",
      ]),
      ...mapActions([
        "fetchProfile",
        "addProfileAvatar",
        "sendProfileChanges",
        "changeProfilePassword",
      ]),
      hideNotification() {
        this.hideProfileNotification();
      },
      toggleSection(section) {
        this.toggleProfileSection(section);
      },
      handleChanges(event) {
        let payload = {};

        payload = {
          section: event.target.closest("section").className,
          event: event.type,
          name: event.target.getAttribute("name"),
          value: event.target.value,
        };

        if (payload.name === "phone") {
          if (event.type === "paste") {
            let value = event.clipboardData.getData("text/plain");

            let pattern =
              /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
            let phoneNumbers = value.match(pattern);

            if (phoneNumbers !== null) {
              let correctNumber = phoneNumbers[0].replace(
                pattern,
                "+7 ($2) $3-$4-$5"
              );

              event.target.value = correctNumber;
            }
          } else if (event.type === "keydown" || event.type === "input") {
            let keyCode;
            event.keyCode && (keyCode = event.keyCode);

            if (event.target.selectionStart < 3) {
              event.target.value = `+7 ${event.data}`;
            }

            let mask = "+7 (___) ___-__-__";
            let index = 0;
            let def = mask.replace(/\D/g, "");
            let val = event.target.value.replace(/\D/g, "");
            let newVal = mask.replace(/[_\d]/g, (arg) =>
              index < val.length ? val.charAt(index++) || def.charAt(index) : arg
            );

            index = newVal.indexOf("_");

            if (index !== -1) {
              index < 5 && (index = 3);
              newVal = newVal.slice(0, index);
            }

            let reg = mask
              .substr(0, event.target.value.length)
              .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
              .replace(/[+()]/g, "\\$&");

            reg = new RegExp(`^${reg}$`);

            if (
              !reg.test(event.target.value) ||
              event.target.value.length < 5 ||
              (keyCode > 47 && keyCode < 58)
            ) {
              event.target.value = newVal;
            }
          }

          payload.value = event.target.value;
        }

        if (payload.name === "theme") {
          payload.value = event.target.checked;
        }

        if (event.target.closest(".about__contacts")) {
          payload.contacts = true;
          payload.id = +event.target
            .closest(".about__contact")
            .getAttribute("data-id");
        }

        this.handleProfileChanges(payload);
      },
      cancelChanges(section) {
        this.cancelProfileChanges(section);
      },
      sendChanges(section) {
        let payload = {};

        if (section === "about") {
          payload.section = section;

          this.sendProfileChanges(payload);
        }

        if (section === "appearance") {
          payload.section = section;

          this.sendProfileChanges(payload);
        }

        if (section === "access") {
          payload.password_old = this.access.password_old.value;
          payload.password_new = this.access.password_new.value;
          payload.password_confirm = this.access.password_confirm.value;

          this.changeProfilePassword(payload);
        }
      },
      addAvatar(event) {
        this.addProfileAvatar(event.target.files[0]);
      },
      deleteAvatar(id) {
        let payload = {
          type: "avatar",
          params: {
            id: +id,
          },
        };

        this.createPopup(payload);
      },
      addContact() {
        let payload = {
          type: "contact",
          params: {
            last_name: null,
            first_name: null,
            middle_name: null,
            comment: null,
            phone: null,
            email: null,
          },
        };

        this.createModal(payload);
      },
      deleteContact(event) {
        let payload = {
          type: "contact",
          params: {
            id: +event.target.closest(".about__contact").getAttribute("data-id"),
          },
        };

        this.createPopup(payload);
      },
      goToObject(event) {
        let payload = {
          id: +event.target.closest(".systems__system").id,
        };

        this.$router.push(`/objects/${payload.id}`);
      },
      deleteCard(event) {
        let payload = {
          type: "card",
          params: {
            id: +event.target.closest(".pay__card").getAttribute("data-id"),
          },
        };

        this.createPopup(payload);
      },
      addCard() {
        let payload = {
          type: "card",
          params: {
            holder: null,
            number: null,
            system: null,
            month: null,
            year: null,
            code: null,
          },
        };

        this.createModal(payload);
      },
      makePayment() {
        let payload = {
          type: "payment",
          params: {},
        };

        this.createModal(payload);
      },
    },
    created() {
      this.fetchProfile();
    },
  };
</script>

<style lang="scss" scoped>
  .profile {
    position: relative;
    width: 100%;

    &__content {
      display: flex;
      flex-wrap: wrap;
    }

    .notification {
      @include notification;

      flex-direction: column;

      @include mediaquery(md) {
        flex-direction: row;
      }

      &__info {
        width: 100%;

        @include mediaquery(md) {
          padding-right: 10px;
        }
      }

      &__title {
        font-size: 14px;
        font-weight: 700;
        color: #2b1a0a;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      &__text {
        font-size: 12px;
        color: #2b1a0a;
      }

      &__strong {
        font-weight: 700;
      }

      &__control {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
        }

        @include mediaquery(md) {
          flex-direction: column;
          width: 27%;
        }

        @include mediaquery(lg) {
          flex-direction: row;
          width: auto;
        }
      }

      &__link {
        text-decoration: none;
        background-color: #222325;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        border-radius: 6px;
        margin-bottom: 10px;
        height: 36px;
        padding: 10px 13px;
        width: 100%;
        text-align: center;

        @include mediaquery(sm) {
          width: 50%;
          margin-right: 10px;
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }

        @include mediaquery(lg) {
          width: auto;
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__ok {
        width: 100%;
        height: 36px;
        background-color: #fff;
        border-radius: 6px;
        border: 0;
        font-size: 12px;
        color: #e8b423;
        cursor: pointer;

        @include mediaquery(sm) {
          width: 50%;
        }

        @include mediaquery(md) {
          width: 100%;
        }

        @include mediaquery(lg) {
          width: 36px;
        }
      }
    }

    .about {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        user-select: none;

        input {
          cursor: pointer;
        }

        .about__block {
          width: 100%;
        }

        @include mediaquery(md) {
          .about__block {
            width: 400px;
          }
        }
      }

      &__main {
        display: flex;
        width: 100%;

        @include mediaquery(md) {
          .about__block {
            width: 50%;
          }
        }
      }

      &__avatar {
        @include base;

        width: 60px;
        min-width: 60px;
        height: 60px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;

        @media (hover: hover) {
          &:hover {
            .about__avatar-picture {
              opacity: 0;
            }

            .about__avatar-delete {
              opacity: 1;
            }
          }
        }

        &-picture {
          width: 100%;
          transition: opacity 0.3s;
        }

        &-delete {
          position: absolute;
          cursor: pointer;
          background: none;
          padding: 0;
          border: 0;
          opacity: 0;
          transition: opacity 0.3s;
          font-size: 18px;
        }

        &-action {
          color: #ff8168;
        }
      }

      &__file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        font-size: 18px;
        cursor: pointer;
        overflow-x: hidden;
        -webkit-tap-highlight-color: transparent;

        &-input {
          position: absolute;
          width: 0;
          height: 0;
          opacity: 0;
          overflow: hidden;
          z-index: -1;
        }

        &-action {
          color: #41c6eb;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        margin-top: 15px;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__owner {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 10px;

        .about__block {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(md) {
          flex-direction: row;

          .about__block {
            width: calc(25% - 19px);
            margin-bottom: 0;

            &:last-of-type {
              width: calc(25% + 27px);
            }
          }
        }
      }

      &__contacts {
        padding: 20px 0;
        margin-top: 20px;
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
      }

      &__contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-bottom: 10px;

        .about__block {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(md) {
          justify-content: flex-start;
          align-items: flex-end;

          .about__block {
            width: calc(33.333333% - 22px);
            margin-right: 10px;
          }

          button {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(xl) {
          flex-wrap: nowrap;

          .about__block {
            width: calc(25% - 19px);
            margin-right: 10px;
            margin-bottom: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          button {
            margin-bottom: 0;
          }
        }
      }

      &__block {
        position: relative;
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__action {
        width: 36px;
        display: flex;
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 20px;

        button {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .systems {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__list {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
      }

      &__system {
        @include base;

        padding: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 5px;
        cursor: pointer;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
      }

      &__address {
        font-size: 10px;
        margin-top: 5px;
      }

      &__wrapper {
        display: flex;
        align-items: center;
      }

      &__state {
        @include state;
      }
    }

    .pay {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__preview {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        margin-top: -5px;
      }

      &__card {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-icon {
          width: 46px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-props {
          display: flex;
          align-items: center;
        }

        &-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 17px;

          @include mediaquery(lg) {
            flex-direction: row;
            align-items: center;
          }
        }

        &-number {
          font-size: 12px;
          font-weight: 500;
          color: #2b1a0a;
        }

        &-holder {
          font-size: 10px;
          color: #2b1a0a;
          text-transform: uppercase;

          @include mediaquery(lg) {
            margin-left: 20px;
          }
        }

        &-expire {
          margin-left: 20px;
          font-size: 10px;
          color: #2b1a0a;
          text-transform: uppercase;
        }
      }

      &__new {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-title {
          font-weight: 500;
          font-size: 12px;
          margin-left: 18px;
          color: #2b1a0a;
        }
      }
    }

    .payments {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        color: #222325;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__preview {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }

      &__payment {
        display: flex;
        align-items: flex-end;
        width: 50%;
      }

      &__block {
        width: 100%;
        margin-right: 10px;
      }

      &__title {
        font-size: 9px;
        margin-bottom: 6px;
        color: #9b9b9b;
      }
    }

    .appearance {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__blocks {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(md) {
          flex-direction: row;
        }
      }

      &__block {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          margin-right: 20px;
          margin-bottom: 0;
        }
      }

      &__title {
        font-size: 13px;
        font-weight: 500;
        margin-left: 10px;
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 20px;

        button {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(sm) {
          flex-direction: row;
          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .access {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__blocks {
        display: flex;
        flex-direction: column;
        padding: 10px 0 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(md) {
          flex-direction: row;
        }
      }

      &__block {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(25% - 8px);
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 20px;

        button {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(sm) {
          flex-direction: row;
          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
</style>
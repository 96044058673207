import {
  createRouter,
  createWebHistory
} from 'vue-router'

import Confirm from "../pages/Confirm.vue";
import Customers from "../pages/Customers.vue";
import Discount from "../pages/Discount.vue";
//import Home from "../pages/Home.vue";
import Login from "../pages/Login.vue";
//import Mail from "../pages/Mail.vue";
//import Messenger from "../pages/Messenger.vue";
import Nothing from "../pages/Nothing.vue";
import Objects from "../pages/Objects.vue";
import Profile from "../pages/Profile.vue";
import Promo from "../pages/Promo.vue";
import Recover from "../pages/Recover.vue";
import Register from "../pages/Register.vue";
//import Reports from "../pages/Reports.vue";
import Reset from "../pages/Reset.vue";
import System from "../pages/System.vue";
import Systems from "../pages/Systems.vue";


const routes = [{
    path: '/',
    redirect: '/login'
  }, {
    path: '/confirm',
    name: 'confirm',
    component: Confirm,
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers,
  },
  {
    path: '/discount',
    name: 'discount',
    component: Discount,
  },
  /* {
    path: '/home',
    name: 'home',
    component: Home
  }, */
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  /* {
    path: '/mail',
    name: 'mail',
    component: Mail
  }, */
  /* {
    path: '/messenger',
    name: 'messenger',
    component: Messenger
  }, */
  {
    path: '/objects/:id',
    name: 'objects',
    component: Objects
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/promo',
    name: 'promo',
    component: Promo
  },
  {
    path: '/recover',
    name: 'recover',
    component: Recover
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  /* {
    path: '/reports',
    name: 'reports',
    component: Reports
  }, */
  {
    path: '/reset',
    name: 'reset',
    component: Reset
  },
  {
    path: '/system/:id',
    name: 'system',
    component: System,
  },
  {
    path: '/systems',
    name: 'systems',
    component: Systems
  },

  {
    path: '/:pathMatch(.*)',
    name: 'nothing',
    component: Nothing
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  const publicPages = [
    '/confirm',
    '/customers',
    '/discount',
    '/home',
    '/login',
    /* '/mail', */
    '/nothing',
    '/promo',
    '/recover',
    '/register',
    '/reset',
  ];

  const engineerPages = [
    '/systems',
    `/system/${to.params.id}`,
  ];

  const userPages = [
    '/objects',
  ];

  const authRequired = !publicPages.includes(to.path);
  const engineerRequired = engineerPages.includes(to.path);
  const userRequired = userPages.includes(to.path);
  const loggedIn = localStorage.getItem('authorization__user-role');

  if (authRequired && !loggedIn) {
    next('/login');
  } else if (engineerRequired && loggedIn !== 'engineer') {
    next('/nothing');
  } else if (userRequired && loggedIn !== 'user') {
    next('/nothing');
  } else {
    next();
  }
});

export default router
import axios from 'axios'

export default {
  state: {
    customers: {
      sort: {
        param: null,
        order: null,
      },
      list: []
    },
  },
  getters: {
    getCustomers: state => state.customers,
  },
  mutations: {
    updateCustomers(state, payload) {
      state.customers.list = payload;
    },
    clearCustomersState(state) {
      state.customers.list = [];
    }
  },
  actions: {
    fetchCustomers(context) {
      context.commit("showPreloader");

      axios
        .get("https://show-api.airwet.app/api/customers")
        .then((response) => {
          context.commit('updateCustomers', response.data.data);
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
  }
}
<template>
  <div class="reset">
    <div class="reset__wrapper">
      <img class="reset__logo" src="../assets/images/logo.svg" alt="" />
      <div class="reset__title">Сброс пароля</div>
      <form class="reset__form" @submit.prevent="reset">
        <div class="reset__form-body">
          <div class="reset__form-block">
            <div class="reset__form-title">Введите пароль</div>
            <Input
              :type="password.type"
              :name="password.name"
              :value="password.value"
              :status="password.status"
              @paste="handleChange"
              @input="handleChange"
              @change="handleChange"
            />
            <span class="reset__form-error" v-if="password.error">{{
              password.error
            }}</span>
          </div>
          <div class="reset__form-block">
            <div class="reset__form-title">Подтвердите пароль</div>
            <Input
              :type="confirm.type"
              :name="confirm.name"
              :value="confirm.value"
              :status="confirm.status"
              @paste="handleChange"
              @input="handleChange"
              @change="handleChange"
            />
            <span class="reset__form-error" v-if="confirm.error">{{
              confirm.error
            }}</span>
          </div>
        </div>
        <div class="reset__form-footer">
          <Button
            :type="'text'"
            :value="'Отправить'"
            :paint="'blue'"
            :disabled="!validation"
            @click="handleFormSumbit"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";
  import Button from "../components/Button";
  import Input from "../components/Input";

  export default {
    name: "Reset",
    components: {
      Button,
      Input,
    },
    computed: {
      ...mapGetters({
        password: "getResetPassword",
        confirm: "getResetConfirm",
        validation: "getResetValidation",
      }),
    },
    methods: {
      ...mapMutations(["handleResetChanges", "setResetCode"]),
      ...mapActions(["sendResetChanges"]),
      handleChange(event) {
        let payload = {
          page: "reset",
          event: event.type,
          name: event.target.getAttribute("name"),
          value: event.target.value,
        };

        this.handleResetChanges(payload);
      },
      handleFormSumbit() {
        this.sendResetChanges();
      },
    },
    created() {
      let url = this.$route.fullPath;
      let position = url.indexOf("?code=");

      if (position !== -1) {
        let code = url.slice(position + 6);

        this.setResetCode(code);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .reset {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    @include mediaquery(md) {
      min-height: calc(100vh - 20px);
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @include mediaquery(sm) {
        width: 400px;
      }
    }

    &__logo {
      width: 50px;
      margin-bottom: 24px;

      @include mediaquery(sm) {
        margin-bottom: 44px;
      }
    }

    &__title {
      width: 100%;
      margin-bottom: 20px;
      text-transform: uppercase;
      text-align: center;
      font-size: 24px;
    }

    &__form {
      @include base;

      width: 100%;
      padding: 20px;

      @include mediaquery(sm) {
        padding: 46px 40px 25px;
      }

      &-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-title {
        font-size: 9px;
        margin-bottom: 6px;
        color: #9b9b9b;
      }

      &-error {
        position: absolute;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &-footer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: space-between;
          margin-top: 20px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
</style>
import axios from 'axios';

export default {
  state: {
    notification: {},
    navbar: {},
    about: {
      extended: localStorage.getItem('system__about-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    hum: {
      list: {
        categories: [],
      },
      programs: [],
      program: {},
      previous: null,
      extended: localStorage.getItem('system__hum-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    zones: [],
    tasks: [],
    config: {
      extended: localStorage.getItem('system__config-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    state: {
      network: {
        type: null,
        status: null,
        indicator: null,
        timer: null,
        countdown: null,
      },
      extended: localStorage.getItem('system__state-extended') === 'true' ? true : false || false,
    },
    control: {
      extended: localStorage.getItem('system__control-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    features: {
      extended: localStorage.getItem('system__features-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    network: {
      extended: localStorage.getItem('system__network-extended') === 'true' ? true : false || false,
    },
    pagination: {
      start: 0,
      total: 8,
      limit: 2,
      pages: [],
      prev: false,
      next: true,
    },
    deleting: true,
    backup: {},
  },
  getters: {
    getSystemNotification: state => state.notification,
    getSystemNavbar: state => state.navbar,
    getSystemAbout: state => state.about,
    getSystemHum: state => state.hum,
    getSystemZones: state => state.zones,
    getSystemTasks: state => state.tasks,
    getSystemConfig: state => state.config,
    getSystemState: state => state.state,
    getSystemControl: state => state.control,
    getSystemFeatures: state => state.features,
    getSystemNetwork: state => state.network,
    getSystemDeleting: state => state.deleting,
    getSystemPagination: state => state.pagination,
  },
  mutations: {
    createSystemNotification(state, payload) {
      state.notification = payload;
    },
    toggleSystemSection(state, payload) {
      state[payload].extended = !state[payload].extended;

      localStorage.setItem(`system__${payload}-extended`, state[payload].extended);
    },
    setSystemActiveNavbarItem(state, item) {
      state.navbar.list.forEach(element => element.isActive = false);
      state.navbar.list.forEach(element => {
        if (element.id == item.id) {
          element.isActive = true;
          state.navbar.current = +item.id;
        }
      });
    },
    setSystemActiveZone(state, payload) {
      state.zones.forEach((zone, index) => {
        if (payload.id !== zone.id) {
          zone.current = false;
          zone.cancellation = true;
          zone.validation = false;
          zone.extended = false;

          if (state.backup.zones && zone.id === state.backup.zones[index].id) {
            zone.hum = state.backup.zones[index].hum;
            zone.temp = state.backup.zones[index].temp;
            zone.name = state.backup.zones[index].name;
            zone.num = state.backup.zones[index].num;
            zone.device = state.backup.zones[index].device;
            zone.devs = state.backup.zones[index].devs;
            zone.nozzle = state.backup.zones[index].nozzle;
            zone.nozzles = state.backup.zones[index].nozzles;
            zone.status = 'default';
            state.backup.zones[index] = {};
          }
        }
      });
      state.zones.forEach(zone => zone.id === payload.id ? zone.current = true : false);
      state.deleting = false;
    },
    toggleSystemZone(state, payload) {
      state.zones.forEach(zone => {
        if (zone.id === payload.id) {
          zone.extended = !zone.extended;
        }
      });
    },
    extendSystemZone(state, payload) {
      state.zones.forEach(zone => {
        if (zone.id === payload.id) {
          zone.extended = true;
        }
      });
    },
    collapseSystemZone(state, payload) {
      state.zones.forEach(zone => {
        if (zone.id === payload.id) zone.extended = false;
      });
    },

    updateSystemNavbar(state, payload) {
      state.navbar = {
        current: 1,
        list: [{
            id: 1,
            quantity: 6,
            name: 'Информация',
            feature: payload.contract.value,
            isActive: true,
          },
          {
            id: 2,
            quantity: payload.tasks.length,
            name: 'Задачи',
            feature: payload.contract.value,
            isActive: false,
          },
          {
            id: 3,
            quantity: 6,
            name: 'Настройка',
            feature: payload.contract.value,
            isActive: false,
          },
        ],
      };
    },
    updateSystemAbout(state, payload) {
      state.about.id = payload.id;
      state.about.contract = payload.contract;
      state.about.active_user = payload.active_user;
      state.about.active_srv = payload.active_srv;
      state.about.errors = payload.errors;
      state.about.date = payload.date;
      state.about.mileage = +payload.mileage * 10000;
      state.about.pump_hours = payload.pump_hours;
      state.about.type = payload.type;
      state.about.system_type = payload.system_type;
      state.about.system_architecture = payload.system_architecture;
      state.about.from_user = payload.from_user;
      state.about.name = {
        element: 'input',
        name: 'name',
        title: 'Имя объекта',
        value: payload.name,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.address = {
        element: 'input',
        name: 'address',
        title: 'Адрес объекта',
        value: payload.address,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_start = {
        element: 'datepicker',
        name: 'date_start',
        title: 'Адрес объекта',
        value: payload.date_start,
        pattern: /^[0-9]{2,250}$/,
        status: 'valid',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_install = {
        element: 'datepicker',
        name: 'date_install',
        title: 'Адрес объекта',
        value: payload.date_install,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'valid',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_warranty = {
        element: 'datepicker',
        name: 'date_warranty',
        title: 'Адрес объекта',
        value: payload.date_warranty,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_maintenance_last = {
        element: 'datepicker',
        name: 'date_maintenance_last',
        value: payload.date_maintenance_last,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_maintenance_plan = {
        element: 'datepicker',
        name: 'date_maintenance_plan',
        value: payload.date_maintenance_plan,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.owner = [{
          id: 0,
          element: 'input',
          type: "text",
          name: 'last_name',
          title: 'Фамилия владельца',
          value: payload.from_user.last_name,
          pattern: /^[а-яА-ЯёЁ]{1,30}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
        {
          id: 1,
          element: 'input',
          type: "text",
          name: 'first_name',
          title: 'Имя владельца',
          value: payload.from_user.first_name,
          pattern: /^[а-яА-ЯёЁ]{1,30}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
        {
          id: 2,
          element: 'input',
          type: "text",
          name: 'middle_name',
          title: 'Отчество владельца',
          value: payload.from_user.middle_name,
          pattern: /^[а-яА-ЯёЁ]{1,30}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
        {
          id: 3,
          element: 'input',
          type: "tel",
          name: 'phone',
          title: 'Телефон',
          value: payload.from_user.phone,
          pattern: /^[+]\d{1}\s[(]\d{3}[)]\s\d{3}[-]\d{2}[-]\d{2}$|^7[0-9]{10,10}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
        {
          id: 4,
          element: 'input',
          type: "email",
          name: 'email',
          title: 'Электронная почта',
          value: payload.from_user.email,
          pattern: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
      ];
      state.about.documents = payload.documents;

      if (payload.state === 0) {
        state.about.state = {
          value: 'disabled',
          text: 'выключена'
        }
      }

      if (payload.state === 1) {
        state.about.state = {
          value: 'enabled',
          text: 'включена'
        }
      }

      if (payload.state === 2) {
        state.about.state = {
          value: 'error',
          text: 'ошибка'
        }
      }

      if (payload.state === 3) {
        state.about.state = {
          value: 'active',
          text: 'работает'
        }
      }

      if (payload.state === 4) {
        state.about.state = {
          value: 'inactive',
          text: 'не работает'
        }
      }

      localStorage.setItem('system__system-id', payload.id);
      localStorage.setItem('system__board-id', payload.board.id);
    },
    updateSystemHum(state, payload) {
      state.hum.programs = payload.programs;

      if (state.hum.programs.length > 0) {
        state.hum.programs.forEach((program, index) => {
          if (program.type === 'hum') {
            if (program.active_srv) {
              let day = new Date().getDay();

              if (
                day >= program.start_day && day <= program.stop_day ||
                day === program.start_day || day === program.stop_day
              ) {
                state.hum.current = program;
              }
            }
            program.max = payload.board.params.features.hum_max;
            program.min = payload.board.params.features.hum_min;
            program.period = {
              name: null,
              value: 50,
            };

            program.start_day_num = program.start_day === 0 ? 7 : program.start_day;
            program.stop_day_num = program.stop_day === 0 ? 7 : program.stop_day;

            state.hum.programs[index] = JSON.parse(JSON.stringify(program));

            if (state.hum.previous && state.hum.previous.id === program.id) {
              state.hum.program = state.hum.programs[index];
              state.hum.previous = state.hum.programs[index];
            } else state.hum.program = state.hum.programs[0];

            state.hum.list.type = 'difficult';
            state.hum.list.paint = 'blue';

            if (state.hum.previous && state.hum.previous.id === program.id) {
              state.hum.list.value = state.hum.programs[index].name;
            } else state.hum.list.value = state.hum.programs[0].name;

            let obj = {
              id: state.hum.programs[index].id,
              name: state.hum.programs[index].id,
              title: state.hum.programs[index].name,
            }

            state.hum.list.categories[index] = obj;
            state.hum.zones = JSON.parse(JSON.stringify(state.zones));

            for (let i = 0; i < state.hum.zones.length; i++) {
              state.hum.zones[i].hum.programs.forEach(prog => {
                if (prog.id === state.hum.program.id) {
                  let morning = {
                    value: prog.morning,
                    unit: '%',
                    status: 'default',
                  }
                  let day = {
                    value: prog.day,
                    unit: '%',
                    status: 'default',
                  }
                  let evening = {
                    value: prog.evening,
                    unit: '%',
                    status: 'default',
                  }
                  let night = {
                    value: prog.night,
                    unit: '%',
                    status: 'default',
                  }

                  state.hum.zones[i].hum.morning = morning;
                  state.hum.zones[i].hum.day = day;
                  state.hum.zones[i].hum.evening = evening;
                  state.hum.zones[i].hum.night = night;
                }
              })
            }

            state.hum.cancellation = true;
            state.hum.validation = false;
            state.backup.hum = {};
          }
        })
      }
    },
    updateSystemZones(state, payload) {
      state.zones = JSON.parse(JSON.stringify(payload.zones));

      state.zones.forEach(zone => {
        zone.name = {
          value: JSON.parse(JSON.stringify(zone.name)),
          status: 'default',
        }

        zone.num = {
          type: 'simple',
          name: 'num',
          val: zone.num,
          value: `Зона ${zone.num}`,
          status: 'default',
          error: false,
          errorMessage: 'Неверный формат',
          categories: [],
        };

        zone.hum.unit = '%';
        zone.hum.status = 'default';
        zone.hum.default = 45;
        zone.hum.control = false;
        zone.hum.max = payload.board.params.features.hum_max;
        zone.hum.min = payload.board.params.features.hum_min;

        zone.temp.unit = '°';
        zone.temp.status = 'default';
        zone.temp.default = 22;
        zone.temp.control = false;
        zone.temp.max = 30;
        zone.temp.min = 5;

        let device = {
          dropdown: {
            name: "device",
            value: null,
            alias: null,
            status: "default",
            categories: [{
                id: 1,
                name: "s",
                title: "Датчик",
              },
              {
                id: 2,
                name: "g",
                title: "Гигростат",
              },
            ],
          },
          input: {
            name: "address",
            value: null,
            pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          button: {
            value: "add",
            disabled: true,
          },
        };

        let nozzle = {
          dropdown: {
            name: "nozzle",
            value: null,
            alias: null,
            status: "default",
            categories: [{
                id: 1,
                name: "01L",
                title: "01L",
              },
              {
                id: 2,
                name: "02L",
                title: "02L",
              },
            ],
          },
          input: {
            name: "quan",
            value: null,
            pattern: /^[0-9]{1,5}$/,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          button: {
            value: "add",
            disabled: true,
          },
        };

        zone.device = JSON.parse(JSON.stringify(device));
        zone.nozzle = JSON.parse(JSON.stringify(nozzle));

        zone.current = false;
        zone.extended = false;
        zone.cancellation = true;
        zone.validation = false;

        state.hum.programs.forEach(program => {
          if (program.active_srv === true) {
            let day = new Date().getDay();
            let hour = new Date().getHours();

            if (
              day >= program.start_day && day <= program.stop_day ||
              day === program.start_day || day === program.stop_day
            ) {
              program.periods.forEach(period => {
                if (
                  (period.start.hour <= hour && hour <= period.stop.hour) ||
                  (period.start.hour >= hour && hour < period.stop.hour && period.name === 'night') ||
                  (period.start.hour <= hour && hour >= period.stop.hour && period.name === 'night')

                ) {
                  let start = `${period.start.hour < 10 ? "0" + period.start.hour : period.start.hour}:${period.start.minute < 10 ? "0" + period.start.minute : period.start.minute}`;
                  let stop = `${period.stop.hour < 10 ? "0" + period.stop.hour : period.stop.hour}:${period.stop.minute < 10 ? "0" + period.stop.minute : period.stop.minute}`;

                  zone.program = {
                    id: program.id,
                    period: period.name,
                    title: period.title,
                    range: `${start} - ${stop}`,
                    name: program.name
                  }
                }
              });

              state.hum.zones.forEach(elem => {
                if (elem.id === zone.id) {

                  elem.hum.programs.forEach(prog => {
                    if (prog.id === zone.program.id) {
                      zone.hum.set = prog[zone.program.period]
                    }
                  });
                }
              });
            }
          }
        });
      });

      state.deleting = true;
    },
    updateSystemTasks(state, payload) {
      state.tasks = payload.data;
    },
    updateSystemConfig(state, payload) {
      state.config.params = [{
          id: 0,
          element: 'dropdown',
          type: 'simple',
          name: 'system_type',
          title: 'Тип системы',
          value: payload.system_type,
          error: null,
          status: 'default',
          categories: [{
              id: 1,
              name: '1',
              title: 'Uno'
            }, {
              id: 2,
              name: '2',
              title: 'Multi'
            },
            {
              id: 3,
              name: '3',
              title: 'Profi'
            },
          ],
        },
        {
          id: 1,
          element: 'dropdown',
          type: 'simple',
          name: 'system_architecture',
          title: 'Архитектура системы',
          value: payload.system_architecture,
          error: null,
          status: 'default',
          categories: [{
              id: 1,
              name: '1',
              title: 'Лучевая'
            }, {
              id: 2,
              name: '2',
              title: 'Кольцевая'
            },
            {
              id: 3,
              name: '4',
              title: 'Комбинированная'
            },
          ],
        },
        {
          id: 2,
          element: 'dropdown',
          type: 'simple',
          name: 'pump_model',
          title: 'Модель насоса',
          value: payload.pump_model,
          error: null,
          status: 'default',
          categories: [{
            id: 1,
            name: '1',
            title: 'Pro max'
          }, {
            id: 2,
            name: '2',
            title: 'Pro'
          }, ],
        },
        {
          id: 3,
          element: 'dropdown',
          type: 'simple',
          name: 'pump_block',
          title: 'Насосный блок',
          value: payload.pump_block,
          error: null,
          status: 'default',
          categories: [{
            id: 1,
            name: '1',
            title: 'WetPRO-18'
          }, {
            id: 2,
            name: '2',
            title: 'WetPRO-20'
          }, ],
        },
        {
          id: 4,
          element: 'dropdown',
          type: 'simple',
          name: 'water_prepare',
          title: 'Водоподготовка',
          value: payload.water_prepare,
          error: null,
          status: 'default',
          categories: [{
            id: 1,
            name: '1',
            title: 'Clean-018'
          }, {
            id: 2,
            name: '2',
            title: 'Clean-020'
          }, ],
        },
      ];
    },
    updateSystemState(state, payload) {
      let formatTime = (function () {
        function num(val) {
          val = Math.floor(val);
          return val < 10 ? '0' + val : val;
        }

        return function (ms) {
          let sec = ms / 1000,
            hours = sec / 3600 % 24,
            minutes = sec / 60 % 60,
            seconds = sec % 60;

          return num(hours) + ":" + num(minutes) + ":" + num(seconds);
        };
      })();

      let zones = JSON.parse(JSON.stringify(payload.zones));

      state.state.p_in = payload.p_in;
      state.state.p_out = payload.p_out;
      state.state.valve_in = payload.valve_in;
      state.state.valve_drain = payload.valve_drain;
      state.state.pump_rpm = payload.pump_rpm;
      state.state.active_srv = payload.active_srv;
      state.state.status = payload.status;
      state.state.timer = payload.timer;
      state.state.errors = payload.errors;
      state.state.zones = zones.sort((a, b) => a.num - b.num);
      state.state.timer = formatTime(state.state.timer * 1000);

      if (state.state.status === 0) state.state.status = 'Ожидание';
      if (state.state.status === 1) state.state.status = 'Набор давления';
      if (state.state.status === 2) state.state.status = 'Активный режим';
      if (state.state.status === 3) state.state.status = 'Снижение давления';
      if (state.state.status === 4) state.state.status = 'Сброс воды';
      if (state.state.status === 5) state.state.status = 'Цикл завершён';
      if (state.state.status === 10) state.state.status = 'Пауза';
      if (state.state.status === 11) state.state.status = 'Ручной режим';
      if (state.state.status === 101) state.state.status = 'Недостаточное входное давление';
      if (state.state.status === 102) state.state.status = 'Высокое входное давление';
      if (state.state.status === 103) state.state.status = 'Не удалось набрать выходное давление';
      if (state.state.status === 104) state.state.status = 'Не удалось поддерживать выходное давление';
      if (state.state.status === 105) state.state.status = 'Превышено выходное давление';
      if (state.state.status === 106) state.state.status = 'Не удалось снизить давление';
      if (state.state.status === 107) state.state.status = 'Не удалось сбросить воду';
      if (state.state.status === 108) state.state.status = 'Ошибка связи с насосом';
      if (state.state.status === 109) state.state.status = 'Авария, утечка';

      if (state.state.errors !== null) {
        state.state.errors.forEach(element => {
          if (element.code === 1) element.text = 'Ошибка ethernet';
          if (element.code === 2) element.text = 'Ошибка часов';
          if (element.code === 3) element.text = 'Ошибка внешней flash';
          if (element.code === 4) element.text = 'Ошибка радиомодуля';
          if (element.code === 5) element.text = 'Ошибка модуля расширения';
          if (element.code === 6) element.text = 'Ошибка датчика';
          if (element.code === 7) element.text = 'Ошибка сервера времени';
          if (element.code === 8) element.text = 'Ошибка DNS';
          if (element.code === 9) element.text = 'Ошибка связи с сервером';
          if (element.code === 10) element.text = 'Работа заблокирована';
          if (element.code === 11) element.text = 'Ошибка насоса';
          if (element.code === 12) element.text = 'Превышено число попыток набора давления';
        });
      }

      if (state.state.network) {
        if (state.state.network.type === 'request') {
          state.state.network.status = 2;
          state.state.network.type = null;
        } else {
          state.state.network.status = 1;
          state.state.network.indicator = 'blink';
        }

        setTimeout(() => state.state.network.indicator = 'default', 1000);
        clearInterval(state.state.network.timer)

        state.state.network.timer = null;
        state.state.network.countdown = 30;

        state.state.network.timer = setInterval(() => {
          state.state.network.countdown--;

          if (state.state.network.countdown <= 1) {
            state.state.network.countdown = 30;
            state.state.network.status = 0;
            state.state.network.indicator = 'off';

            setTimeout(() => {
              clearInterval(state.state.network.timer);
              state.state.network.timer = null;
            }, 1000);
          }
        }, 1000);
      }
    },
    updateSystemControl(state, payload) {
      let control = JSON.parse(JSON.stringify(payload));

      state.control.manual_control = control.manual_control;
      state.control.valve_drain = control.valve_drain;
      state.control.valve_in = control.valve_in;
      state.control.pump = control.pump;
      state.control.zones = control.zones.sort((a, b) => a.num - b.num);
    },
    updateSystemFeatures(state, payload) {
      state.features.params = [{
          id: 0,
          name: 'p_in_min',
          title: 'Входное давление min (бар)',
          value: payload.board.params.features.p_in_min,
          error: null,
          status: 'default',
        },
        {
          id: 1,
          name: 'p_in_max',
          title: 'Входное давление max (бар)',
          value: payload.board.params.features.p_in_max,
          error: null,
          status: 'default',
        },
        {
          id: 2,
          name: 'p_out_min',
          title: 'Выходное давление min (бар)',
          value: payload.board.params.features.p_out_min,
          error: null,
          status: 'default',
        },
        {
          id: 3,
          name: 'p_out_nom',
          title: 'Выходное давление nom (бар)',
          value: payload.board.params.features.p_out_nom,
          error: null,
          status: 'default',
        },
        {
          id: 4,
          name: 'p_out_max',
          title: 'Выходное давление max (бар)',
          value: payload.board.params.features.p_out_max,
          error: null,
          status: 'default',
        },
        {
          id: 5,
          name: 'p_precharge',
          title: 'P предварительное (бар)',
          value: payload.board.params.features.p_precharge,
          error: null,
          status: 'default',
        },
        {
          id: 6,
          name: 'precharge_timeout',
          title: 'Таймаут набора давления (с)',
          value: payload.board.params.features.precharge_timeout,
          error: null,
          status: 'default',
        },
        {
          id: 7,
          name: 'p_delay',
          title: 'Задержка контроля давления (с)',
          value: payload.board.params.features.p_delay,
          error: null,
          status: 'default',
        },
        {
          id: 8,
          name: 'p_timeout',
          title: 'Таймаут ошибки давления (c)',
          value: payload.board.params.features.p_timeout,
          error: null,
          status: 'default',
        },
        {
          id: 9,
          name: 'pump_rpm',
          title: 'Vmax (об/мин)',
          value: payload.board.params.features.pump_rpm,
          error: null,
          status: 'default',
        },
        {
          id: 10,
          name: 'Kp',
          title: 'Регулятор давления Кп',
          value: payload.board.params.features.Kp,
          error: null,
          status: 'default',
        },
        {
          id: 11,
          name: 'Ki',
          title: 'Регулятор давления Ки',
          value: payload.board.params.features.Ki,
          error: null,
          status: 'default',
        },
        {
          id: 12,
          name: 'Kd',
          title: 'Регулятор давления Кд',
          value: payload.board.params.features.Kd,
          error: null,
          status: 'default',
        },
        {
          id: 13,
          name: 'p_drain',
          title: 'Давление сброса',
          value: payload.board.params.features.p_drain,
          error: null,
          status: 'default',
        },
        {
          id: 14,
          name: 'discharge_timeout',
          title: 'Таймаут снижения давления (с)',
          value: payload.board.params.features.discharge_timeout,
          error: null,
          status: 'default',
        },
        {
          id: 15,
          name: 't_drain',
          title: 'Время сброса',
          value: payload.board.params.features.t_drain,
          error: null,
          status: 'default',
        },
        {
          id: 16,
          name: 't_open',
          title: 'Длительность работы',
          value: payload.board.params.features.t_open,
          error: null,
          status: 'default',
        },
        {
          id: 17,
          name: 't_close',
          title: 'Длительность паузы',
          value: payload.board.params.features.t_close,
          error: null,
          status: 'default',
        },
        {
          id: 18,
          name: 'hum_min',
          title: 'Минимальная влажность зон',
          value: payload.board.params.features.hum_min,
          error: null,
          status: 'default',
        },
        {
          id: 19,
          name: 'hum_max',
          title: 'Максимальная влажность зон',
          value: payload.board.params.features.hum_max,
          error: null,
          status: 'default',
        },
      ];
    },
    updateSystemNetwork(state, payload) {
      state.network.params = [{
          id: 0,
          name: 'MAC',
          title: 'MAC',
          value: payload.board.params.network.MAC,
          error: null,
          status: "default",
        },
        {
          id: 1,
          name: 'IP',
          title: 'IP',
          value: payload.board.params.network.IP,
          error: null,
          status: "default",
        },
        {
          id: 2,
          name: 'mask',
          title: 'Маска',
          value: payload.board.params.network.mask,
          error: null,
          status: "default",
        },
        {
          id: 3,
          name: 'gateway',
          title: 'Основной шлюз',
          value: payload.board.params.network.gateway,
          error: null,
          status: "default",
        },
        {
          id: 4,
          name: 'DNS',
          title: 'DNS',
          value: payload.board.params.network.DNS,
          error: null,
          status: "default",
        },
      ];
    },
    updateSystemPagination(state, payload) {
      state.pagination.start = payload.start;
      state.pagination.limit = payload.limit;
      state.pagination.total = payload.total;
      state.pagination.pages = [];

      let pagesLength = Math.ceil(state.pagination.total / state.pagination.limit);

      for (let i = 1; i < pagesLength + 1; i++) {
        let page = {
          id: i,
          value: i,
          current: (state.pagination.start + state.pagination.limit) / state.pagination.limit == i ? true : false,
        }

        state.pagination.pages.push(page);
      }
    },

    handleSystemChanges(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        if (payload.section === 'about') {
          if (isEmpty(state.backup.about)) {
            state.backup.about = JSON.parse(JSON.stringify(state.about));
          }

          state.about.cancellation = false;
          state.about.validation = true;

          if (payload.name === 'name' || payload.name === 'address') {
            if (state.about[payload.name].name === payload.name) {
              state.about[payload.name].value = payload.value;
              state.about.extended = true;

              if (payload.value.match(state.about[payload.name].pattern)) {
                state.about[payload.name].status = 'valid';
                state.about[payload.name].error = false;
              } else {
                state.about[payload.name].status = 'invalid';
                state.about[payload.name].error = true;
              }
            }
          } else if (
            payload.name === 'date_start' ||
            payload.name === 'date_install' ||
            payload.name === 'date_warranty' ||
            payload.name === 'date_maintenance_last' ||
            payload.name === 'date_maintenance_plan') {
            if (state.about[payload.name].name === payload.name) {
              state.about[payload.name].value = Math.round(payload.value);

              if (payload.name === 'date_maintenance_last' || payload.name === 'date_maintenance_plan') state.about.extended = true;

              if (String(payload.value).match(state.about[payload.name].pattern)) {
                state.about[payload.name].status = 'valid';
                state.about[payload.name].error = false;
              } else {
                state.about[payload.name].status = 'invalid';
                state.about[payload.name].error = true;
              }
            }
          } else {
            state.about.owner.forEach(param => {
              if (param.name === payload.name) {
                param.value = payload.value;

                if (payload.value.match(param.pattern)) {
                  param.status = 'valid';
                  param.error = false;
                } else {
                  param.status = 'invalid';
                  param.error = true;
                }
              }
            });
          }

          state.about.owner.forEach(param => {
            if (param.status === 'invalid' || state.about.name.status === 'invalid' || state.about.address.status === 'invalid') {
              state.about.validation = false;
            }
          });
        }

        if (payload.section === 'config') {
          if (isEmpty(state.backup.config)) {
            state.backup.config = JSON.parse(JSON.stringify(state.config));
          }

          state.config.params.forEach(param => {
            if (param.name === payload.name) {
              param.value = payload.value;
              param.status = 'valid';
            }
          });

          state.config.cancellation = false;
          state.config.validation = true;

          state.config.params.forEach(param => {
            if (param.status === 'invalid') {
              state.config.validation = false;
            }
          });
        }

        if (payload.section === 'control') {
          if (isEmpty(state.backup.control)) {
            state.backup.control = JSON.parse(JSON.stringify(state.control));
          }

          if (payload.name === 'manual_control') state.control.manual_control = payload.value;
          if (payload.name === 'pump') state.control.pump = payload.value;
          if (payload.name === 'valve_drain') state.control.valve_drain = payload.value;
          if (payload.name === 'valve_in') state.control.valve_in = payload.value;

          state.control.zones.forEach(zone => {
            if (zone.id === payload.id) {
              zone.hum.activity = payload.value;
              zone.changed = true;
            }
          });

          state.control.cancellation = false;
          state.control.validation = true;
        }

        if (payload.section === 'features') {
          if (isEmpty(state.backup.features)) {
            state.backup.features = JSON.parse(JSON.stringify(state.features));
          }

          state.features.params.forEach(param => {
            if (param.name === payload.name) {
              param.value = payload.value;

              if (this.state.common.patterns[payload.name].test(payload.value)) {
                param.status = 'valid';
                param.error = null;
              } else {
                param.status = 'invalid';
                param.error = 'Неверный формат';
              }
            }
          });

          state.features.validation = true;
          state.features.cancellation = false;

          state.features.params.forEach(param => {
            if (param.status === 'invalid') {
              state.features.validation = false;
            }
          });
        }
      }
    },
    handleSystemProperty(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      if (isEmpty(state.backup[payload.property])) {
        state.backup[payload.property] = JSON.parse(JSON.stringify(state[payload.property]));
      }

      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        if (payload.param === 'programs') {
          state[payload.property].programs.forEach(element => {
            if (element.id == +payload.value) {
              state[payload.property].list.value = element.name;
              state[payload.property].program = element;

              for (let i = 0; i < state.hum.zones.length; i++) {
                state.hum.zones[i].hum.programs.forEach(prog => {
                  if (prog.id === +payload.value) {
                    state.hum.zones[i].hum.morning.value = prog.morning;
                    state.hum.zones[i].hum.day.value = prog.day;
                    state.hum.zones[i].hum.evening.value = prog.evening;
                    state.hum.zones[i].hum.night.value = prog.night;
                  }
                })
              }
            }
          });
        } else {
          if (payload.param === 'value') {
            if (payload.scope === 'period') {
              state[payload.property].program.period.value = payload.value;

              state[payload.property].zones.forEach(element => {
                element[payload.property][state[payload.property].program.period.name].value = payload.value;
                element[payload.property][state[payload.property].program.period.name].status = 'valid';

                element[payload.property].programs.forEach(prog => {
                  if (prog.id === state.hum.program.id) {
                    prog[state[payload.property].program.period.name] = payload.value;
                  }
                })
              });
            } else {
              state[payload.property].program.value = payload.value;
              state[payload.property].program.period.name = null;

              state[payload.property].zones.forEach(element => {
                element[payload.property].morning.value = payload.value;
                element[payload.property].morning.status = 'valid';
                element[payload.property].day.value = payload.value;
                element[payload.property].day.status = 'valid';
                element[payload.property].evening.value = payload.value;
                element[payload.property].evening.status = 'valid';
                element[payload.property].night.value = payload.value;
                element[payload.property].night.status = 'valid';

                element[payload.property].programs.forEach(prog => {
                  if (prog.id === state.hum.program.id) {
                    prog.morning = payload.value;
                    prog.day = payload.value;
                    prog.evening = payload.value;
                    prog.night = payload.value;
                  }
                })
              });
            }
          }

          if (payload.param === 'active_srv') {
            state[payload.property].extended = true;
            state[payload.property].program.active_srv = payload.value
          }

          if (payload.param === 'period') {
            if (payload.type === 'day') {
              let popup = {
                type: "error",
                params: {
                  text: null
                }
              }

              if (state[payload.property].program.name === 'Будни') {
                if (payload.stage === 'start') {
                  if (payload.num >= state[payload.property].program.stop_day_num) {
                    popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                    this.commit('createPopup', popup);
                  }
                }
                if (payload.stage === 'stop') {
                  if (payload.num <= state[payload.property].program.start_day_num) {
                    popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                    this.commit('createPopup', popup);
                  } else if (payload.num >= state[payload.property].programs[1].start_day_num) {
                    popup.params.text = 'День окончания программы "Будни" должен быть меньше дня начала программы "Выходные".';
                    this.commit('createPopup', popup);
                  }
                }
              }

              if (state[payload.property].program.name === 'Выходные') {
                if (payload.stage === 'start') {
                  if (payload.num >= state[payload.property].program.stop_day_num) {
                    popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                    this.commit('createPopup', popup);
                  } else if (payload.num <= state[payload.property].programs[0].stop_day_num) {
                    popup.params.text = 'День начала программы "Выходные" должен быть больше дня окончания программы "Будни".';
                    this.commit('createPopup', popup);
                  }
                }
                if (payload.stage === 'stop') {
                  if (payload.num <= state[payload.property].program.start_day_num) {
                    popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                    this.commit('createPopup', popup);
                  }
                }
              }

              if (!popup.params.text) {
                state[payload.property].program[`${payload.stage}_${payload.type}`] = payload.value;
                state[payload.property].program[`${payload.stage}_${payload.type}_num`] = payload.num;
              }
            }

            if (payload.type === 'time') {
              state[payload.property].program[payload.stage][payload.unit] = payload.value;
            }
          }

          if (payload.param === 'range') {
            state[payload.property].program.periods.forEach((element, index) => {
              if (element.id == payload.id) {
                element[payload.stage][payload.unit] = payload.value;

                if (payload.stage === 'stop') {
                  if (index + 1 === state[payload.property].program.periods.length) {
                    state[payload.property].program.periods[0].start[payload.unit] = payload.value;
                  } else {
                    state[payload.property].program.periods[index + 1].start[payload.unit] = payload.value;
                  }
                }

                if (payload.stage === 'start') {
                  if (index === 0) {
                    state[payload.property].program.periods[state[payload.property].program.periods.length - 1].stop[payload.unit] = payload.value;
                  } else {
                    state[payload.property].program.periods[index - 1].stop[payload.unit] = payload.value;
                  }
                }
              }
            });
          }

          if (payload.param === 'select') {
            state[payload.property].program.period.name = payload.period;
            if (!payload.value) state[payload.property].program.period.name = null;
          }

          if (payload.param === "zone") {
            state[payload.property].zones.forEach(element => {
              if (element.id == payload.id) {
                if (payload.value > state.hum.program.max) payload.value = state.hum.program.max;
                if (payload.value < state.hum.program.min) payload.value = state.hum.program.min;

                element[payload.property][payload.period].value = payload.value;
                state[payload.property].program.period.name = null;

                if (String(payload.value).match(/^[0-9]{1,2}$/)) {
                  element[payload.property][payload.period].status = 'valid';
                } else {
                  element[payload.property][payload.period].status = 'invalid';
                }

                element[payload.property].programs.forEach(prog => {
                  if (prog.id === state.hum.program.id)
                    prog[payload.period] = payload.value;
                })
              }
            });
          }

          state[payload.property].cancellation = false;
          state[payload.property].validation = true;

          state.hum.zones.forEach(zone => {
            if (
              zone[payload.property].morning.status === 'invalid' ||
              zone[payload.property].day.status === 'invalid' ||
              zone[payload.property].evening.status === 'invalid' ||
              zone[payload.property].night.status === 'invalid'
            ) {
              state[payload.property].validation = false;
            }
          });
        }
      }

      if (payload.event === 'focus') {
        if (payload.param === "zone") {
          state[payload.property].zones.forEach(element => {
            if (element.id == payload.id) {
              element[payload.property][payload.period].unit = '';
              element[payload.property][payload.period].control = true;
              state[payload.property].program.period.name = null;
            }
          });
        }
      }

      if (payload.event === 'blur') {
        if (payload.param === "zone") {
          state[payload.property].zones.forEach(element => {
            if (element.id == payload.id) {
              if (payload.property === 'hum' && element[payload.property][payload.period].status !== 'invalid') {
                element[payload.property][payload.period].unit = '%';
              }
              if (payload.property === 'temp' && element[payload.property][payload.period].status !== 'invalid') {
                element[payload.property][payload.period].unit = '°';
              }
            }
          });
        }
      }
    },
    handleSystemZone(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      state.zones.forEach((element, index) => {
        if (element.id === payload.id) {
          element.current = true;

          if (isEmpty(state.backup.zones)) {
            state.backup.zones = JSON.parse(JSON.stringify(state.zones));
          } else if (isEmpty(state.backup.zones[index])) {
            state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
          }
        }
      });

      if (payload.event === 'change' || payload.event === 'input') {
        state.zones.forEach((zone, index) => {
          if (zone.id === payload.id) {
            if (isEmpty(state.backup.zones[index])) {
              state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
            }

            if (payload.element === 'input') {
              if (payload.name === 'name') {
                zone[payload.name].value = payload.value;

                if (payload.value.match(/^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/)) zone[payload.name].status = 'valid';
                else zone[payload.name].status = 'invalid';
              }

              if (payload.name === 'address') {
                zone.device.input.value = payload.value;

                if (payload.value.match(/^[0-9]{1,5}$/)) zone.device.input.status = 'valid';
                else zone.device.input.status = 'invalid';
              }

              if (payload.name === 'quan') {
                zone.nozzle.input.value = payload.value;

                if (payload.value.match(/^[0-9]{1,5}$/)) zone.nozzle.input.status = 'valid';
                else zone.nozzle.input.status = 'invalid';
              }
            }

            if (payload.element === "switch") {
              if (payload.param !== null) {
                zone[payload.param].active_srv = payload.value;

                if (zone[payload.param].active_srv === false) {
                  zone[payload.param].setting = zone[payload.param].default;
                  zone[payload.param].status = 'default';
                }
              }
            }

            if (payload.element === 'slider') {
              zone[payload.param][payload.name] = payload.value;
            }

            if (payload.element === 'dropdown') {
              if (payload.name === 'num') {
                if (payload.open) {
                  let common = [...Array(31)].map((el, i) => {
                    return {
                      id: i + 1,
                      name: i + 1,
                      title: `Зона ${i + 1}`
                    }
                  });

                  let arr = this.state.system.zones;

                  for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < common.length; j++) {
                      if (common[j].id === arr[i].num.val) {
                        common.splice(common.indexOf(common[j]), 1)
                      }
                    }
                  }

                  zone.num.categories = common;
                } else {
                  zone.num.value = payload.value;
                  zone.num.val = +payload.alias;
                }

              }

              if (payload.name === 'device') {
                zone.device.dropdown.value = payload.value;
                zone.device.dropdown.alias = payload.alias;
                zone.device.dropdown.status = 'valid';
              }

              if (payload.name === 'nozzle') {
                zone.nozzle.dropdown.value = payload.value;
                zone.nozzle.dropdown.alias = payload.alias;
                zone.nozzle.dropdown.status = 'valid';
              }
            }

            if (payload.element === 'add') {
              if (payload.block === 'devs') {
                let obj2 = {
                  type: zone.device.dropdown.alias,
                  addr: +zone.device.input.value,
                  index: 0
                };

                zone[payload.block].push(obj2);

                let sIndex = 0;
                let gIndex = 0;

                zone[payload.block].forEach(dev => {
                  if (dev.type === 's') dev.index = ++sIndex;
                  if (dev.type === 'g') dev.index = ++gIndex;
                });

                zone.device.dropdown.value = null;
                zone.device.dropdown.alias = null;
                zone.device.dropdown.status = 'default';

                zone.device.input.value = null;
                zone.device.input.status = 'default';

                zone.device.button.disabled = true;
              }

              if (payload.block === 'nozzles') {
                let obj3 = {
                  type: zone.nozzle.dropdown.alias,
                  quan: +zone.nozzle.input.value,
                };

                zone[payload.block].push(obj3);

                zone.nozzle.dropdown.value = null;
                zone.nozzle.dropdown.alias = null;
                zone.nozzle.dropdown.status = 'default';

                zone.nozzle.input.value = null;
                zone.nozzle.input.status = 'default';

                zone.nozzle.button.disabled = true;
              }
            }

            if (payload.element === 'delete') {
              zone[payload.modules] = zone[payload.modules].filter((element, index) => index !== payload.index);
            }

            if (zone.device.dropdown.status === 'valid' && zone.device.input.status === 'valid') {
              zone.device.button.disabled = false;
            }

            if (zone.nozzle.dropdown.status === 'valid' && zone.nozzle.input.status === 'valid') {
              zone.nozzle.button.disabled = false;
            }

            zone.cancellation = false;
            zone.validation = true;

            if (zone.name.status === 'invalid' || zone.hum.status === 'invalid' || zone.temp.status === 'invalid') zone.validation = false;
          }
        });
      }

      if (payload.event === 'focus') {
        state.zones.forEach(element => {
          if (element.id === payload.id) {
            if (payload.param !== null) {
              if (isNaN(+payload.value.slice(0, -1))) element[payload.param][payload.name] = '';
              else element[payload.param][payload.name] = +payload.value.slice(0, -1);

              element[payload.param].unit = '';

            } else {
              element[payload.name] = payload.value;
            }
          }
        });
      }

      if (payload.event === 'blur') {
        state.zones.forEach(element => {
          if (element.id === payload.id) {
            if (payload.param !== null) {
              element[payload.param].unit = '';

              if (element[payload.param].set === '') {
                element[payload.param].set = 0;
                element[payload.param].status = 'valid';
              }

              if (payload.param === 'hum') element.hum.unit = '%';
              if (payload.param === 'temp') element.temp.unit = '°';

              element.cancellation = false;
              element.validation = true;

              if (element.name.status === 'invalid' || element.hum.status === 'invalid' || element.temp.status === 'invalid') element.validation = false;
            }
          }
        });
      }
    },

    resetSystemsState(state) {
      if (state.zones) {
        state.zones.forEach((zone, index) => {
          if (state.backup.zones && state.backup.zones.length > 0) {
            if (zone.id === state.backup.zones[index].id) {
              zone.hum = state.backup.zones[index].hum;
              zone.temp = state.backup.zones[index].temp;
              zone.name = state.backup.zones[index].name;
              zone.num = state.backup.zones[index].num;
              zone.device = state.backup.zones[index].device;
              zone.devs = state.backup.zones[index].devs;
              zone.nozzle = state.backup.zones[index].nozzle;
              zone.nozzles = state.backup.zones[index].nozzles;
              zone.status = 'default';
              state.backup.zones[index] = {};
            }
          }
          zone.current = false;
          zone.cancellation = true;
          zone.validation = false;
          zone.extended = false;
        });
      }
      state.deleting = true;
      state.hum.current = null;
    },
    cancelSystemChanges(state, payload) {
      if (payload.param === "about") {
        state.about.cancellation = true;
        state.about.validation = false;

        state.about.name.value = state.backup.about.name.value;
        state.about.name.status = 'default';
        state.about.name.error = false;

        state.about.address.value = state.backup.about.address.value;
        state.about.address.status = 'default';
        state.about.address.error = false;

        state.about.date_start.value = state.backup.about.date_start.value;
        state.about.date_start.status = 'default';
        state.about.date_start.error = false;

        state.about.date_install.value = state.backup.about.date_install.value;
        state.about.date_install.status = 'default';
        state.about.date_install.error = false;

        state.about.date_warranty.value = state.backup.about.date_warranty.value;
        state.about.date_warranty.status = 'default';
        state.about.date_warranty.error = false;

        state.about.date_maintenance_last.value = state.backup.about.date_maintenance_last.value;
        state.about.date_maintenance_last.status = 'default';
        state.about.date_maintenance_last.error = false;

        state.about.date_maintenance_plan.value = state.backup.about.date_maintenance_plan.value;
        state.about.date_maintenance_plan.status = 'default';
        state.about.date_maintenance_plan.error = false;

        state.about.owner.forEach((param, index) => {
          param.value = state.backup.about.owner[index].value;
          param.status = 'default';
          param.error = false;
        });

        state.backup.about = {};
      }

      if (payload.param === "hum") {
        state.hum.cancellation = true;
        state.hum.validation = false;
        state.hum.current = null;
        state.hum = state.backup.hum;
        state.backup.hum = {};
      }

      if (payload.param === 'zone') {
        state.zones.forEach((element, index) => {
          if (element.id == payload.id) {
            element.hum = state.backup.zones[index].hum;
            element.temp = state.backup.zones[index].temp;
            element.name = state.backup.zones[index].name;
            element.device = state.backup.zones[index].device;
            element.devs = state.backup.zones[index].devs;
            element.nozzle = state.backup.zones[index].nozzle;
            element.nozzles = state.backup.zones[index].nozzles;
            element.status = 'default';
            element.cancellation = true;
            element.validation = false;
            element.extended = false;
            state.backup.zones[index] = {};
          }
        });
      }

      if (payload.param === "config") {
        state.config.cancellation = true;
        state.config.validation = false;

        state.config.params.forEach((param, index) => {
          param.value = state.backup.config.params[index].value;
          param.status = 'default';
          param.error = false;
        });

        state.backup.config = {};
      }

      if (payload.param === "control") {
        state.control.manual_control = state.backup.control.manual_control;
        state.control.valve_drain = state.backup.control.valve_drain;
        state.control.valve_in = state.backup.control.valve_in;
        state.control.pump = state.backup.control.pump;
        state.control.zones = state.backup.control.zones;
        state.control.cancellation = true;
        state.control.validation = false;

        state.backup.control = {};
      }

      if (payload.param === "features") {
        state.features.cancellation = true;
        state.features.validation = false;

        state.features.params.forEach((param, index) => {
          param.value = state.backup.features.params[index].value;
          param.status = 'default';
          param.error = false;
        });

        state.backup.features = {};
      }
    },
    clearSystemSection(state, payload) {
      state[payload].cancellation = true;
      state[payload].validation = false;
      state.backup[payload] = {};
    },
    clearSystemState(state) {
      state.notification = {};
      state.navbar = {};
      state.about = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.config = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.state = {
        network: {
          type: null,
          status: null,
          indicator: null,
          timer: null,
          countdown: null,
        },
        extended: false,
      };
      state.control = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.features = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.network = {
        extended: false,
      };
      state.tasks = [];
      state.deleting = false;
      state.backup = {};
    },
    updateSystemAboutSocket(state, payload) {
      if (payload.mileage) state.about.mileage = +payload.mileage * 10000;
      if (payload.pump_hours) state.about.pump_hours = payload.pump_hours;
    },
    updateSystemZonesSocket(state, payload) {
      if (state.zones !== undefined && state.zones.length > 0) {
        state.zones.forEach((zone, index) => {
          if (zone.id === payload.zones[index].id && !zone.current) {
            zone.hum.cur = payload.zones[index].hum.cur;
            zone.hum.set = payload.zones[index].hum.set;
            zone.hum.activity = payload.zones[index].hum.activity;

            zone.temp.cur = payload.zones[index].temp.cur;
            zone.temp.set = payload.zones[index].temp.set;
            zone.temp.activity = payload.zones[index].temp.activity;

            state.hum.programs.forEach(program => {
              if (program.active_srv === true) {
                let day = new Date().getDay();
                let hour = new Date().getHours();

                if (
                  day >= program.start_day && day <= program.stop_day ||
                  day === program.start_day || day === program.stop_day
                ) {
                  program.periods.forEach(period => {
                    if (
                      (period.start.hour <= hour && hour <= period.stop.hour) ||
                      (period.start.hour >= hour && hour < period.stop.hour && period.name === 'night') ||
                      (period.start.hour <= hour && hour >= period.stop.hour && period.name === 'night')

                    ) {
                      let start = `${period.start.hour < 10 ? "0" + period.start.hour : period.start.hour}:${period.start.minute < 10 ? "0" + period.start.minute : period.start.minute}`;
                      let stop = `${period.stop.hour < 10 ? "0" + period.stop.hour : period.stop.hour}:${period.stop.minute < 10 ? "0" + period.stop.minute : period.stop.minute}`;

                      zone.program = {
                        id: program.id,
                        period: period.name,
                        title: period.title,
                        range: `${start} - ${stop}`,
                        name: program.name
                      }
                    }
                  });

                  state.hum.zones.forEach(elem => {
                    if (elem.id === zone.id) {

                      elem.hum.programs.forEach(prog => {
                        if (prog.id === zone.program.id) {
                          zone.hum.set = prog[zone.program.period]
                        }
                      });
                    }
                  });
                }
              }
            });
          }
        });
      }
    },
  },
  actions: {
    fetchSystem(context, payload) {
      if (payload === 'default') {
        let payload = {
          visible: true,
          title: 'Уведомление!',
          text: `
              В настоящий момент не выбрана ни одна система. Перейдите к списку
              систем и выберите необходимую.
              `,
          control: false,
        };
        context.commit('createSystemNotification', payload);
      } else {
        context.commit("showPreloader");
        context.commit("clearSystemState");

        axios
          .get(`https://show-api.airwet.app/api/getSystem/${payload}`, this.state.common.headers)
          .then((response) => {
            context.state.state.network.type = 'request';

            context.commit('updateSystemNavbar', response.data);
            context.commit('updateSystemAbout', response.data);
            context.commit('updateSystemZones', response.data);
            context.commit('updateSystemHum', response.data);
            context.commit('updateSystemZones', response.data);

            context.commit('updateSystemConfig', response.data);
            context.commit('updateSystemState', response.data);
            context.commit('updateSystemControl', response.data);
            context.commit('updateSystemFeatures', response.data);
            context.commit('updateSystemNetwork', response.data);

            context.commit("hidePreloader");
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    fetchSystemTasks(context, payload) {
      context.commit("showPreloader");

      if (payload) {
        if (payload.type === "prev") {
          context.state.pagination.start = context.state.pagination.start - context.state.pagination.limit;
          if (context.state.pagination.start <= 0) {
            context.state.pagination.start = 0;
          }

          if (context.state.pagination.start === 0) {
            context.state.pagination.prev = false;
          }

          context.state.pagination.next = true;
        }

        if (payload.type === "next") {
          context.state.pagination.start = context.state.pagination.start + context.state.pagination.limit;
          if (context.state.pagination.start >= context.state.pagination.total) {
            context.state.pagination.start = context.state.pagination.total - context.state.pagination.limit;
          }

          if (context.state.pagination.start === context.state.pagination.total - context.state.pagination.limit) {
            context.state.pagination.next = false;
          }

          context.state.pagination.prev = true;
        }

        if (payload.page) {
          context.state.pagination.start = payload.page * context.state.pagination.limit - context.state.pagination.limit;
          context.state.pagination.prev = true;
          context.state.pagination.next = true;

          if (context.state.pagination.start === 0) {
            context.state.pagination.prev = false;
          }

          if (context.state.pagination.start === context.state.pagination.total - context.state.pagination.limit) {
            context.state.pagination.next = false;
          }
        }
      }

      axios
        .get(`https://show-api.airwet.app/api/tasks?filters[executor][id][$eq]=2&pagination[start]=${context.state.pagination.start}&pagination[limit]=${context.state.pagination.limit}`, this.state.common.headers)
        .then((response) => {
          context.commit('updateSystemTasks', response.data);
          context.commit('updateSystemPagination', response.data.meta.pagination);
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    changeSystemActiveStatus(context, payload) {
      context.commit("showPreloader");

      let system = {
        data: {
          active_srv: payload.active,
          active_user: context.state.about.active_user,
          errors: context.state.about.errors,
          sender: 'engineer',
        },
      }

      axios
        .put(`https://show-api.airwet.app/api/updateSystem/${payload.system}`, system, this.state.common.headers)
        .then((response) => {
          context.commit('updateSystemAbout', response.data);
          context.commit('updateSystemZones', response.data);
          context.commit('updateSystemHum', response.data);

          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    changeSystemOwner(context, payload) {
      context.commit("showPreloader");

      if (payload.owner === 'new') {
        let acronym = '';

        if (payload.first_name !== null && payload.last_name !== null) {
          acronym = `${payload.first_name.substr(0, 1)}${payload.last_name.substr(0, 1)}`;
        }

        axios
          .post("https://show-api.airwet.app/api/auth/local/register", {
            username: payload.email.substring(0, payload.email.indexOf('@')),
            email: payload.email,
            password: payload.password,
            first_name: payload.first_name,
            last_name: payload.last_name,
            middle_name: payload.middle_name,
            phone: payload.phone,
            acronym: acronym,
            first_entry: true,
          }, this.state.common.headers)
          .then((user) => {
            axios
              .post("https://show-api.airwet.app/api/users/sendMailToNewUser", {
                user_id: user.data.user.id,
                password: payload.password,
              }, this.state.common.headers)
              .then(() => {
                let system = {
                  data: {
                    from_user: user.data.user.id
                  },
                }

                axios
                  .put(`https://show-api.airwet.app/api/updateSystem/${context.state.about.id}`, system, this.state.common.headers)
                  .then((response) => {
                    context.commit('updateSystemAbout', response.data);
                    context.commit("hidePreloader");
                  })
              })
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload.owner === 'exist') {
        axios
          .get(`https://show-api.airwet.app/api/users/GetUserIdFromEmail/${payload.email}`, this.state.common.headers)
          .then((user) => {
            let system = {
              data: {
                from_user: user.data.data.id
              },
            }

            axios
              .put(`https://show-api.airwet.app/api/updateSystem/${context.state.about.id}`, system, this.state.common.headers)
              .then((response) => {
                context.commit('updateSystemAbout', response.data);
                context.commit("hidePreloader");
              })
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    addSystemDocument(context, payload) {
      context.commit("showPreloader");

      let formData = new FormData();
      formData.append("files", payload.file);

      axios
        .post("https://show-api.airwet.app/api/upload", formData, this.state.common.headers)
        .then((file) => {
          axios
            .post("https://show-api.airwet.app/api/documents", {
              data: {
                name: payload.name,
                file: file.data[0].id,
                from_system: context.state.about.id,
              }
            }, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                .then((response) => {
                  context.commit('updateSystemAbout', response.data);
                  context.commit('updateSystemHum', response.data);
                  context.commit('updateSystemZones', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    deleteSystemDocument(context, payload) {
      context.commit("showPreloader");

      axios
        .delete(`https://show-api.airwet.app/api/upload/files/${payload.file}`, this.state.common.headers)
        .then(() => {
          axios
            .delete(`https://show-api.airwet.app/api/documents/${payload.id}`, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                .then((response) => {
                  context.commit('updateSystemAbout', response.data);
                  context.commit('updateSystemHum', response.data);
                  context.commit('updateSystemZones', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    resetSystemErrors(context, payload) {
      let system = {
        data: {
          errors: context.state.about.errors.filter(item => item.code === payload.error).map(item => item.code),
        },
      }

      axios
        .put(`https://show-api.airwet.app/api/resetErrors/${context.state.about.id}`, system, this.state.common.headers)
        .then(() => {
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    sendSystemChanges(context, payload) {
      context.commit("showPreloader");

      if (payload === 'about') {
        let system = {
          data: {
            name: context.state.about.name.value,
            address: context.state.about.address.value,
            date_start: context.state.about.date_start.value,
            date_install: context.state.about.date_install.value,
            date_warranty: context.state.about.date_warranty.value,
            date_maintenance_last: context.state.about.date_maintenance_last.value,
            date_maintenance_plan: context.state.about.date_maintenance_plan.value,
          },
        }

        axios
          .put(`https://show-api.airwet.app/api/updateSystem/${context.state.about.id}`, system, this.state.common.headers)
          .then((response) => {
            context.commit('updateSystemAbout', response.data);
            context.commit('updateSystemHum', response.data);
            context.commit('updateSystemZones', response.data);
            context.commit('clearSystemSection', payload);
            context.commit("hidePreloader");
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload === 'config') {
        let data = {};
        let prepare = {};

        context.state.config.params.forEach(param => {
          prepare[param.name] = param.value;
        });

        data = {
          data: prepare,
        }

        axios
          .put(`https://show-api.airwet.app/api/updateSystem/${context.state.about.id}`, data, this.state.common.headers)
          .then(() => {
            axios
              .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
              .then((response) => {
                context.commit('updateSystemAbout', response.data);
                context.commit('updateSystemConfig', response.data);
                context.commit("hidePreloader");

                context.state.config.cancellation = true;
                context.state.config.validation = false;
                context.state.backup.config = {};
              })
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload === 'control') {
        let zones = JSON.parse(JSON.stringify(context.state.control.zones));

        for (let i = 0; i < zones.length; i++) {
          for (let key in zones[i]) {
            if (key !== 'id' && key !== 'num' && key !== 'hum') delete zones[i][key];
          }
          zones[i].hum = {
            id: zones[i].hum.id,
            activity: zones[i].hum.activity
          }
        }

        let common = {
          data: {
            manual_control: context.state.control.manual_control,
            pump: context.state.control.pump,
            valve_drain: context.state.control.valve_drain,
            valve_in: context.state.control.valve_in,
            zones: zones,
          }
        }

        axios
          .put(`https://show-api.airwet.app/api/systems/${context.state.about.id}/manualControl`, common, this.state.common.headers)
          .then(() => {
            context.commit("hidePreloader");
            context.state.control.cancellation = true;
            context.state.control.validation = false;
            context.state.backup.control = {};
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload === 'features') {
        let data = {};
        let features = {};
        let network = {};
        let id = localStorage.getItem('system__board-id');

        context.state.features.params.forEach(param => features[param.name] = +param.value);
        context.state.network.params.forEach(param => network[param.name] = param.value);

        data = {
          data: {
            params: {
              features: features,
              network: network,
            },
          }
        }

        axios
          .put(`https://show-api.airwet.app/api/boards/${id}`, data, this.state.common.headers)
          .then(() => {
            axios
              .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
              .then((response) => {
                context.commit('updateSystemAbout', response.data);
                context.commit('updateSystemZones', response.data);
                context.commit('updateSystemHum', response.data);
                context.commit('updateSystemFeatures', response.data);
                context.commit('clearSystemSection', payload);
                context.commit("hidePreloader");
              })
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    changeSystemProgram(context, payload) {
      context.commit("showPreloader");

      let program = {
        data: context.state.hum.program,
      }
      let programZones = context.state.hum.zones;
      let backupZones = context.state.backup.hum.zones;

      context.state.hum.previous = context.state.hum.program;

      axios
        .put(`https://show-api.airwet.app/api/programs/${payload.id}`, program, this.state.common.headers)
        .then(() => {
          let endpoints = [];

          for (let i = 0; i < programZones.length; i++) {
            if (JSON.stringify(programZones[i]) !== JSON.stringify(backupZones[i])) {
              let obj = {
                url: `https://show-api.airwet.app/api/zones/${context.state.hum.zones[i].id}`,
                payload: {
                  data: {
                    hum: {
                      id: context.state.hum.zones[i].hum.id,
                      programs: context.state.hum.zones[i].hum.programs
                    }
                  },
                },
              };

              endpoints[i] = obj;
            }
          }

          if (endpoints.length > 0) {
            axios.all(endpoints.map((endpoint) => axios.put(endpoint.url, endpoint.payload, this.state.common.headers)))
              .then(() => {
                axios
                  .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                  .then((response) => {
                    context.commit('updateSystemAbout', response.data);
                    context.commit('updateSystemZones', response.data);
                    context.commit('updateSystemHum', response.data);
                    context.commit("hidePreloader");
                  })
              })
              .catch((error) => context.commit("handleError", error));
          } else {
            axios
              .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
              .then((response) => {
                context.commit('updateSystemAbout', response.data);
                context.commit('updateSystemZones', response.data);
                context.commit('updateSystemHum', response.data);
                context.commit("hidePreloader");
              })
              .catch((error) => context.commit("handleError", error));
          }

          context.state.backup.hum = {};
        })
        .catch((error) => context.commit("handleError", error));
    },
    addSystemZone(context, payload) {
      context.commit("showPreloader");

      axios
        .post('https://show-api.airwet.app/api/zones', {
          data: payload,
        }, this.state.common.headers)
        .then(() => {
          axios
            .get(`https://show-api.airwet.app/api/zones/?populate=devs,nozzles,hum,temp&filters[from_system][id][$eq]=${payload.from_system}`, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                .then((response) => {
                  context.commit('updateSystemHum', response.data);
                  context.commit('updateSystemZones', response.data);
                  context.commit('updateSystemState', response.data);
                  context.commit('updateSystemControl', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    changeSystemZone(context, payload) {
      context.commit("showPreloader");

      let zone = {
        name: null,
        num: null,
        event: null,
        devs: null,
        nozzles: null,
        hum: {
          id: null,
          activity: null,
          active_srv: null,
          set: null,
          cur: null,
        },
        temp: {
          id: null,
          activity: null,
          active_srv: null,
          set: null,
          cur: null,
        },
      }

      context.state.zones.forEach(elem => {
        if (elem.id === payload.zone) {
          zone.name = elem.name.value;
          zone.num = elem.num.val;
          zone.event = 'service_config';
          zone.devs = elem.devs;
          zone.nozzles = elem.nozzles;
          zone.hum.id = elem.hum.id;
          zone.hum.activity = elem.hum.activity;
          zone.hum.active_srv = elem.hum.active_srv;
          zone.hum.set = elem.hum.set;
          zone.hum.cur = elem.hum.cur;
          zone.temp.id = elem.temp.id;
          zone.temp.activity = elem.temp.activity;
          zone.temp.active_srv = elem.temp.active_srv;
          zone.temp.set = elem.temp.set;
          zone.temp.cur = elem.temp.cur;
        }
      });

      axios
        .put(`https://show-api.airwet.app/api/zones/${payload.zone}`, {
          data: zone,
        }, this.state.common.headers)
        .then(() => {
          axios
            .get(`https://show-api.airwet.app/api/zones/?populate=devs,nozzles,hum,temp&filters[from_system][id][$eq]=${payload.system}`, this.state.common.headers)
            .then(() => {
              if (context.state.hum.current && context.state.hum.current.changed) {
                let program = {
                  data: {
                    active_srv: false,
                  }
                }

                context.state.hum.previous = context.state.hum.program;

                axios
                  .put(`https://show-api.airwet.app/api/programs/${context.state.hum.current.id}`, program, this.state.common.headers)
                  .then(() => {
                    axios
                      .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                      .then((response) => {
                        context.commit('updateSystemHum', response.data);
                        context.commit('updateSystemZones', response.data);
                        context.commit('updateSystemState', response.data);
                        context.commit('updateSystemControl', response.data);
                        context.commit("hidePreloader");
                      })
                      .catch((error) => context.commit("handleError", error));

                    context.state.backup.hum = {};
                  })
                  .catch((error) => context.commit("handleError", error));
              } else {
                axios
                  .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                  .then((response) => {
                    context.commit('updateSystemHum', response.data);
                    context.commit('updateSystemZones', response.data);
                    context.commit('updateSystemState', response.data);
                    context.commit('updateSystemControl', response.data);
                    context.commit("hidePreloader");
                  })
              }

              context.state.zones.forEach((elem, index) => {
                if (elem.id === payload.zone) {
                  context.state.backup.zones[index] = {};
                }
              });
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    deleteSystemZone(context, payload) {
      context.commit("showPreloader");

      axios
        .delete(`https://show-api.airwet.app/api/zones/${payload.zone}`, this.state.common.headers)
        .then(() => {
          axios
            .get(`https://show-api.airwet.app/api/zones/?populate=devs,nozzles,hum,temp&filters[from_system][id][$eq]=${payload.system}`, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://show-api.airwet.app/api/getSystem/${context.state.about.id}`, this.state.common.headers)
                .then((response) => {
                  context.commit('updateSystemZones', response.data);
                  context.commit('updateSystemState', response.data);
                  context.commit('updateSystemControl', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
  }
}